



:root {
  --primary-color: white;
  --secondary-color: brown;
  --yellow: linear-gradient(180deg, #f8d49a -146.42%, #fad79d -46.42%);
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --purple: linear-gradient(180deg, #bb67ff 0%, #c484f3 100%);
  --pink: #ff919d;
  --glass: rgba(255, 255, 255, 0.54);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #f799a354;
}
/* .App {
  text-align: center;
} */


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a {
  text-decoration: none !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}




.location-search-input-box{
  width: 100% !important;
  border-radius: 8px !important;
  color: #6c757d !important;
  font-weight: 300 !important;
  margin: 0 auto !important;
  border-color: white !important;
}
.location-search-icon {
  width: 0px !important;
  margin-top: 10px !important;
  margin-left: 0px !important;
}
.location-enter-icon {
  width: 0px !important;
  margin-top: 8px !important;
  margin-left: 0px !important;
  cursor: pointer !important;
}

.pac-container {
  /* position: absolute !important;
  font-size: 30px;
  z-index: 4000 !important;
  border-radius: 5px;
  border-top: 0px solid #d9d9d9;
  font-family: Arial,sans-serif;
  box-shadow: 0 0px 0px rgb(0 0 0 / 0%);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden; */
  border-color: none !important;
  /* margin-top: 100px;
  background-color: transparent !important; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





/* .navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-color) !important;
  border-bottom: 0.1875rem solid var(--primary-color);
}

.navbar-nav > li > a.active,
.navbar-nav > li > a:focus {
  border-bottom: 0.1875rem solid var(--primary-color);
} */


