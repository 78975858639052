.demo-app {
  /* display: flex; */
  min-height: 100%;
  font-family: 'Work Sans', sans-serif !important;    
  font-size: 10px;
}
.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #fae9df;
  border-right: 1px solid #d3e2e8;
}
.demo-app-sidebar-section {
  padding: 2em;
}
.demo-app-main {
  flex-grow: 1;
  padding: 0;
  /* margin-left: 18%; */
  width: 100%;
  align-items: center;
}
.staff-calendar-main {
  flex-grow: 1;
  padding: 0;
  /* margin-left: 18%; */
  width: 100%;
  align-items: center;
}
.fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}
.staff-counts{
  font-size: 12px !important;
  /* background-color: #ECB390 !important; */
  font-family: 'Work Sans', sans-serif !important; 
  color: #602F10  !important;   
  margin-top: 10px;
  text-align: center !important;
}
.fc-event-main{
  background-color: #F3CFB8 !important;
}
.fc-daygrid-day-number{
  color: #602F10 !important;
}
.fc-col-header-cell-cushion {
  color: #602F10 !important;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end{
  background-color: #F3CFB8 !important;
  border-color: #ECB390 !important;
  color: black !important;

}
.fc-event-main-frame{
  color: #602F10 !important;
  border-color: #ECB390 !important;
  background-color: #F3CFB8 !important;
}
.fc-v-event .fc-event-main {
  color: #602F10 !important;
  border-color: #ECB390 !important;
  background-color: #F3CFB8 !important;

}
@media screen and (max-width: 767px) and (min-width: 320px) {
  .fc .fc-daygrid-more-link {
    position: relative;
    z-index: 4;
    cursor: pointer;
    bottom: 39px !important;
}
  .fc .fc-toolbar-title {
    font-size: 0.75em;
    margin: 0;
  }
  }