.policy-header {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  color: #000;
  margin-bottom: 40px;
}
h2 {
  font-weight: 500 !important;
}
.sub-header {
  font-size: 20px;
  font-weight: 400;
  color: #6c757d;
}
.terms-paragraph {
  font-size: 20px;
  color: #000;
  font-weight: 400;
  margin-bottom: 30px;
}
section {
  padding: 20px 0 100px 0;
}