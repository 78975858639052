.user-home-banner {
  width: 100%;
  background-image: url("../../assets/images/user-bannerimg.webp") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 700px !important;
  padding: 0;
}
.user-home-text {
  text-align: center;
  margin-top: 8%;
  width: 100%;
  left: 0;
}
.user-home-text p {
  font-weight: 500;
  font-size: 24px;
  color: #000;
  margin: 0;
}
.user-home-text p.banner-text {
  font-size: 70px;
  font-weight: 400;
  color: #000;
  margin: 0 auto;
  font-family: 'DM Serif Display' !important;
}
.user-home-text span.beauty-text {
  float: left;
  width: 100%;
  font-size: 70px;
  font-weight: 400;
  color: #c78963;
  margin: 0 auto;
  font-family: 'DM Serif Display' !important;
}
.city-zip-outer {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.zip-inner {
  background: #fff;
  height: 100px;
  width: 70%;
  border-radius: 32px;
  margin: 0 auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
}
.city-zip-outer .city-zipcode {
  display: inline-block;
  width: 43%;
  height: 100px;
  /* float: left; */
  border: none;
}
.city-zip-outer .service-stylist {
  display: inline-block;
  width: 36%;
  height: 100px;
  /* float: left; */
  border: none;
}
.zip-location {
  float: left;
  padding: 34px 0px 34px 30px;
}
.service-location {
  float: left;
  padding: 34px 0px 34px 15px;
  border-left: 1px solid #ecb390;
}
.button-location {
  float: right;
  margin: 7px 30px 0px 0px !important;
}
.form-control:focus {
  outline: none;
  box-shadow: none;
}
.service-gender-user {
  width: 60%;
  margin: 35px 0;
  display: inline-block;
}
.service-gender-user ul li {
  float: left;
  width: 33%;
  position: relative;
}
.service-gender-user ul li p {
  font-weight: 500;
  font-size: 40px;
  color: #c78963;
  margin: 0 !important;
  font-family: 'Space Grotesk', sans-serif !important;
}
.service-gender-user ul li span {
  font-weight: 500 !important;
  font-size: 20px;
  color: #7F7F7F;
  font-family: 'Work Sans', sans-serif !important;
  
}
.service-gender-user ul li:before {
  position: absolute;
  content: "";
  background: #c78963;
  width: 5px;
  height: 60px;
  top: 30px;
  right: 0;
  border: 1px solid #c78963;
}
.service-gender-user ul li:last-child:before {
  content: none;
}
.user-section {
  /* background: url(../images/explore-bg.svg) no-repeat; */
  margin-top: 20px;
  width: 100%;
  display: inline-block;
}

.user-common-title {
  float: left;
  width: auto;
  font-size: 48px;
  font-weight: 400;
  color: #000;
}
.user-common-title span {
  font-weight: 400;
  font-size: 48px;
  color: #ecb390;
}
.view-all {
  float: right;
  font-size: 20px;
  font-weight: 500;
  color: #0d6efd;
  margin-top: 15px !important;
}
.explore-service-list {
  float: left;
  width: 100%;
  margin: 50px 0;
}
.explore-service-list ul li {
  float: left;
  width: 15%;
  margin-right: 25px;
  cursor: pointer;
}
.explore-service-list ul li:last-child {
  margin-right: 0;
}
.explore-list {
  float: left;
  width: 100%;
  text-align: center;
}
.explore-list span {
  font-weight: 500;
  font-size: 32px;
  color: #000;
  float: left;
  width: 100%;
  margin-top: 20px;
}
.our-features-outer {
  float: left;
  width: 100%;
  background: #f8f9fa;
  padding-top: 30px;
  margin-top: 100px;
}
.client-main {
  float: right;
  margin-top: 15px;
}
.client-main ul li {
  float: left;
  width: auto;
  margin: 0 0 0 20px;
}
.client-main ul li a {
  font-weight: 500;
  font-size: 20px;
  color: #6c757d;
}
.client-main ul li a:hover {
  color: #0d6efd;
  border-bottom: 1px solid #0d6efd;
}
.our-features-list {
  float: left;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px !important;
}
.our-features-list ul li {
  float: left;
  width: 33%;
  margin-bottom: 60px;
}
.our-features-list ul li .explore-list span {
  font-size: 20px;
}
.how-works-outer {
  float: left;
  width: 100%;
  margin: 100px 0;
}
.how-works-list {
  float: left;
  width: 100%;
  margin: 40px 0 0px 0;
}
.how-works-list ul li {
  float: left;
  width: 23%;
  margin-right: 25px;
}
.how-works-list ul li:last-child {
  margin-right: 0;
}
.how-works-list ul li .card {
  border: none;
  background: transparent;
}
.how-works-list ul li .card-body {
  padding: 0;
}
.how-works-list ul li .card-title {
  font-size: 24px;
  font-weight: 500;
  color: #212529;
  margin: 20px 0 15px 0;
}
.how-works-list ul li .card-text {
  font-size: 20px;
  font-weight: 300;
  color: #6c757d;
}
.works-img {
  float: left;
  width: 40px;
}
.customer-review {
  float: left;
  width: 100%;
  margin-bottom: 50px;
}
.customer-review ul li {
  float: left;
  width: 48%;
  margin-right: 25px;
}
.customer-review ul li:last-child {
  margin-right: 0;
}
.customer-review ul li .card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  border-radius: 12px;
  border: none;
  padding: 30px;
}
.customer-review ul li .card-text {
  font-size: 20px;
  font-weight: 400;
  color: #6c757d;
}
.customer-review ul li .card-body {
  padding: 0;
}
.customer-review ul li span {
  float: left;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: #212529;
}
.customer-review ul li span.location-date {
  float: left;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #6c757d;
  margin-top: 5px;
}
.customer-review-img {
  float: left;
  width: 100%;
  text-align: center;
}
.customer-review .us-icon {
  width: 65px;
  margin-bottom: 10px;
}
.customer-review-slide {
  float: left;
  width: 100%;
  margin-top: 5px !important;
}
.customer-review-slide .carousel-indicators [data-bs-target] {
  width: 18px;
  height: auto;
  background: #ecb390;
  border-radius: 100%;
}
.customer-review-slide .carousel-indicators {
  bottom: -30px;
}
.customer-review-slide .carousel-control-next,
.customer-review-slide .carousel-control-prev {
  display: none !important;
}
.book-appointment-section {
  float: left;
  width: 100%;
  margin: 50px 0px 0px 0px;
  position: relative;
  padding: 0;
}
.book-appointment-outer {
  float: left;
  width: 100%;
  background: #000;
  border-radius: 24px;
  position: relative;
  margin-bottom: 100px;
  height: 400px !important;
}
.customer-review-outer {
  float: left;
  width: 100%;
  padding: 0;
  margin-top: 50px;
}
.book-appointment-outer img {
  float: right;
  border-radius: 24px;
  height: 400px;
  width: 650px!important;
}
.discount-booking-text {
  float: left;
  width: 50%;
  position: absolute;
  
  left: 50px;
}
.discount-booking-text p {
  margin-top: 20px !important;
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  font-family: 'Space Grotesk', sans-serif !important;
}
.discount-booking-text h2 {
  font-weight: 400;
  font-size: 54px;
  color: #ecb390;
  margin-bottom: 100px;
  Font-family:'DM Serif Display'!important;
  margin-top: 0 !important;
}
.discount-booking-text span {
  font-size: 16px;
  color: #f3cfb8;
 
  font-weight: 400!important;
  font-family: 'Work Sans', sans-serif;
}
.explore-btn {
  background: #ecb390;
  font-weight: 500;
  font-size: 20px;
  color: #602f10;
  text-align: center;
  border-radius: 12px;
  width: 220px;
  padding: 5px 10px 5px 10px;
  position: absolute;
  right: 80px;
  bottom: 38px;
  border: none;
}
.explore-cart-btn{
  background: #ecb390;
  font-weight: 500;
  font-size: 20px;
  color: #602f10;
  text-align: center;
  border-radius: 12px;
  width: 280px;
  padding: 10px 20px;
  right: 50px;
  bottom: 80px;
  border: none;
}
.explore-service-outer {
  padding: 0;
}
#carousel-mobile-view{
  display: none !important;
}

/*Mobile Responsive Start*/
@media screen and (max-width: 767px) and (min-width: 320px) {
  .view-all {
    float: right;
    font-size: 20px;
    font-weight: 500;
    color: #0d6efd;
    margin-top: 0px !important;
  }
  #carousel-system-view{
    display: none !important;
  }
  #carousel-mobile-view{
    display: contents !important;
  }

  .book-appointment-outer img {
    height: 220px !important;
  }
  .book-appointment-outer {
    float: left;
    width: 100%;
    background: #000;
    border-radius: 24px;
    position: relative;
    margin-bottom: 100px;
    height: 220px !important;
}
  .user-home-text {
    top: 5px;
    left: 0;
    width: 100%;
    margin: 0 auto;
  }
  .search-btn {
    float: right;
    width: auto;
    margin: 0 10px 0 0px;
  }
  .city-zip-outer {
    margin-top: 5px;
    padding: 0 10px;
  }
  .city-zip-outer,
  .zip-inner {
    width: 100%;
    margin-top: 5%;
  }
  .zip-inner,
  .city-zip-outer .city-zipcode,
  .city-zip-outer .service-stylist {
    height: 45px;
  }
  .zip-location,
  .service-location {
    padding: 0 0px 0 10px;
    width: 30%;
    height: 50px !important;
  }
  .button-location {
    width: 35px;
  }
  .city-zip-outer .city-zipcode,
  .city-zip-outer .service-stylist {
    font-size: 14px;
    width: 35%;
  }
  .user-home-text p {
    font-size: 18px;
  }
  .service-gender-user ul li p {
    font-size: 18px;
    margin-top: 10%;
  }
  .service-gender-user ul li span {
    font-size: 16px;
  }
  .service-gender-user {
    width: 100%;
    margin: 0;
  }
  .service-gender-user ul li:before {
    width: 5px;
    height: 30px;
    margin-top: 10%;
  }

  .user-common-title,
  .user-common-title span {
    font-size: 26px;
  }
  .explore-list span {
    font-size: 28px;
  }
  .client-main {
    margin-top: 0;
  }
  .discount-booking-text {
    top: 10px;
    left: 10px;
  }
  .explore-btn {
    top: inherit;
    bottom: 10px;
    right: 10px;
    font-size: 16px;
    width: auto;
  }
  .discount-booking-text h2 {
    font-size: 20px;
    margin: 5px 0;
  }
  .discount-booking-text p {
    margin-bottom: 5px;
    font-size: 16px;
  }
  .discount-booking-text span {
    font-size: 15px;
  }
  .book-appointment-section {
    margin-top: 100px;
    padding: 0;
  }
  .user-home-text p.banner-text,
  .user-home-text span.beauty-text {
    font-size: 20px;
  }
  .explore-service-list ul li {
    width: 100%;
    text-align: center;
    margin: 0 0 30px 0;
    cursor: pointer;
  }
  .our-features-list ul li {
    width: 100%;
  }
  .how-works-list ul li {
    width: 100%;
    margin: 0 0 30px 0;
  }
  .how-works-outer {
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 0;
  }
  .how-works-list {
    margin-bottom: 0;
  }
  .discount-booking-text {
    width: 100%;
  }
  .customer-review ul li {
    width: 100%;
    margin: 0;
  }
  .explore-service-list {
    margin: 20px 0;
  }
  .our-features-outer {
    margin-top: 20px;
    padding: 0 !important;
    margin-bottom: 0 !important;
  }
  .user-home-content {
    width: 100% !important;
  }
  .user-home-text {
    text-align: center;
    margin-top: 30%;
    width: 100%;
    left: 0;
}
 
  .user-home-text p.banner-text{
    font-size: 30px;
  }
}
/*Mobile Responsive End*/

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .discount-booking-text h2 {
    font-size: 40px;
}

  .explore-service-list ul li {
    float: left !important;
    width: 14% !important;
    margin-right: 13px !important;
  }
}