.ordersummary-inner {
  float: left;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  margin-bottom: 15px;
}
.ordersummary-inner h3 {
  font-weight: 500;
  font-size: 28px;
  color: #212529;
  display: inline-block;
  padding: 20px;
}
.ordersummary-inner span {
  font-weight: 500;
  font-size: 28px;
  color: #212529;
  margin: 0;
  padding: 20px;
}
.servicelist-inner {
  float: left;
  width: 100%;
  padding: 20px;
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
}
.discount-coupon {
  font-size: 16px;
  font-weight: 400;
  color: #6c757d;
  padding: 12px 60px 12px 12px;
  border-radius: 8px;
  position: relative;
}
.discount-coupon:focus {
  box-shadow: none;
}
.apply-btn {
  font-size: 16px;
  font-weight: 400;
  color: #602f10;
  position: absolute !important;
  right: 0;
  top: 0;
  padding: 12px;
  border: none;
  background: transparent;
}
.apply-btn:hover {
  background-color: transparent;
  color: #602f10;
}
.servicelist-inner ul li {
  float: left;
  width: 50%;
  margin-bottom: 10px;
}
.servicelist-inner ul li p {
  font-size: 20px;
  font-weight: 400;
  color: #6c757d;
  display: inline-block;
  margin: 0;
}
.servicelist-inner ul li span {
  font-size: 20px;
  font-weight: 400;
  color: #212529;
  padding: 0;
}
.servicelist-inner ul li span.green {
  color: #198754;
}
.info-icon {
  margin: 0 0 0 10px;
}
.total-amount-inner {
  float: left;
  width: 100%;
  padding: 20px;
}
.total-amount-inner p {
  font-weight: 500;
  font-size: 28px;
  color: #212529;
  margin: 0;
  display: inline-block;
}
.total-amount-inner span {
  font-weight: 500;
  font-size: 28px;
  color: #212529;
  margin: 0;
  padding: 0;
}
.total-amount-inner p.order-save {
  float: left;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #198754;
  margin-top: 10px;
}
.continue-btn {
  width: 100%;
  background: #ecb390;
  text-align: center;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  color: #602f10;
  margin-top: 30px;
}
p.safe-secure {
  font-size: 16px;
  font-weight: 400;
  color: #6c757d;
  margin-top: 20px;
}
.secure {
  margin: -4px 10px 0 0;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.login-box {
  float: left;
  width: 100%;
}
.login-box p {
  font-weight: 400;
  font-size: 16px;
  color: #212529;
}
.continue-btn-login {
  float: left;
  width: 100%;
  background: #ecb390;
  text-align: center;
  padding: 10px;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  color: #602f10;
  margin-top: 10px;
}
.Reschedule-process{
  float: left;
  width: 100%;
  background: #ecb390;
  text-align: center;
  padding: 10px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  color: #602f10;
  margin-top: 10px;
}
.Reschedule-cancel {
  float: left;
  width: 100%;
  background: white;
  text-align: center;
  padding: 10px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-size: 16px;
  color: #7F7F7F;
  margin-top: 10px;
}

.order-payment-view{
  padding: 0 !important;
}
.order-payment-view p{
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.order-payment-view h6{
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #333333 !important;
  line-height: 24px !important;
}
.order-id-info{
  margin-top: 50px !important;
}
.order-id-info h6{
  color: #333333;
  font-size: 18px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  margin-top: 10px !important;
}

.rate-invite{
  font-size: 14px !important;
  width: 100% !important;
  background-color: #FFF2EB !important;
  color: #602F10 !important;
  border-color: #F3CFB8 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.re-order-text{
  color: #1890FF !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  margin-top: 10px !important;
}
.re-order-icon{
  color: #1890FF !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  margin-top: 10px !important;
  margin-right: 5px !important;
}
  
.payment-status-card{
  display: flex !important;
}
.payment-status-card span{
  font-size: 12px !important;
  margin-top: 4px !important;
  margin-right: 5px !important;
  color: #52C41A!important;
}
.order-status-card{
  display: flex !important;
}
.myOrder-status-card span{
  font-size: 12px !important;
  margin-top: 2px !important;
  margin-right: 5px !important;
}
.myOrder-status-card{
  display: flex !important;
}
.secondary-card-info{
  font-size: 16px;
  font-weight: 500 !important;
  color: #333333;
  font-family: 'Work Sans', sans-serif !important;
}
.payment-badge-info{
  background-color: #52C41A !important;
  color: white !important;
}
.order-status-card-accept span{
  font-size: 12px !important;
  margin-top: 4px !important;
  margin-right: 5px !important;
  color: #52C41A !important;
}
.order-status-card span{
  font-size: 12px !important;
  margin-top: 4px !important;
  margin-right: 5px !important;
  color: #FADB14 !important;
}
.order-status-card-pending span{
  font-size: 12px !important;
  margin-top: 4px !important;
  margin-right: 5px !important;
  color: #FADB14 !important;
}
.order-status-card-rejected span{
  font-size: 12px !important;
  margin-top: 4px !important;
  margin-right: 5px !important;
  color: #F5222D !important;
}

.order-view-info{
  width: 95% !important;
}
.cancel-card-inner label{
 font-size: 14px !important;
 color: #333333 !important;
 font-weight: 400 !important;
 font-family: 'Work Sans', sans-serif !important;
}
.cancel-card-inner option{
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.refund-note h6{
  font-size: 12px;
  color: #333333;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.cancel-request-card{
  background-color: #FAFAFA !important;
}
.refund-note p{
  font-size: 12px !important;
  color: #7F7F7F;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  margin-top: 10px
}
.refund-note p span{
  color: #87CEEB !important;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.refund-policy{
  padding: 10px !important;
  background-color: #FAFAFA !important;
}
.refund-policy h6{
  font-size: 12px;
  color: #333333;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.refund-policy p{
  font-size: 12px;
  color: #7F7F7F;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.order-details-cancel{
  background-color: #EBEBEB !important;
}
.order-details-cancel p{
  font-size: 12px !important;
  color: #333333 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}

.select-mode-cancel{
  color: #7F7F7F !important;
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important; 
  font-weight: 400 !important;
}
.cancel-body-inner{
  height: 300px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.eligible-text p{
  color: #333333;
  font-size: 16px;
  font-family: 'Work Sans', sans-serif !important; 
  font-weight: 500 !important;
}
.eligible-text {
  border-bottom: 2px dashed #EBEBEB;
  margin-top: 0 !important;
  margin-bottom: 20px !important;
}
.cancel-card-header{
  background-color: white !important;
  font-size: 20px !important;
  font-family: 'Work Sans', sans-serif !important; 
  font-weight: 500 !important;
}
.cancel-card-Subheader{
  background-color: white !important;
  font-size: 20px !important;
  font-family: 'Work Sans', sans-serif !important; 
  font-weight: 500 !important;
  margin-top: 20px !important;
  border-top: 1px solid #EBEBEB !important;
}

.orginal-payment{
  color: #000000 !important;
  font-size: 14px !important;
 font-family: 'Work Sans', sans-serif !important; 
 font-weight: 500 !important;
}
.failure-inner{
  height: 100vh !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
}


.review-order {
  float: left;
  width: 100%;
  font-size: 12px;
  color: #7F7F7F;
  font-weight: 400;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}
.service-type {
  float: left;
  width: 100%;
  border-bottom: 1px solid #ced4da;
  padding-bottom: 10px;
}
.service-type .form-check {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  margin-bottom: 10px;
}
.contact-details {
  float: left;
  width: 100%;
  margin-top: 25px;
}
.contact-details .form-control,
.contact-details .form-select {
  border: 1px solid #ced4da;
  padding: 12px;
  font-size: 16px;
  font-weight: 300;
  color: #BFBFBF !important;
  border-radius: 8px;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.form-control:focus,
.form-select:focus {
  box-shadow: none;
}
.edit-icon {
  font-size: 16px;
  font-weight: 500;
  color: #0d6efd;
  position: absolute;
  right: 10px;
  margin: 0;
}
.edit {
  margin: -3px 8px 0 0;
}
.shop-service {
  float: left;
  width: 100%;
}
.shop-service span {
  float: left;
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  color: #0d6efd;
  margin: 0 0 20px 0;
}
.shop-service p {
  font-weight: 400;
  font-size: 16px;
  color: #212529;
}
.change-btn {
  float: right;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #0d6efd;
  color: #0d6efd;
  border-radius: 8px;
  position: absolute;
  right: 20px;
  top: 10px;
  padding: 10px 30px;
}
.service-date-list {
  float: left;
  width: 100%;
}
.service-date-list p {
  font-size: 18px;
  color: #0d6efd;
  font-weight: 400;
  margin-bottom: 5px;
}
.service-date-list span {
  float: left;
  font-size: 14px;
  font-weight: 400;
  color: #6c757d;
  margin: 0;
}
p.date-day {
  float: left;
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  color: #212529;
  margin: 10px 0 20px 0;
}
.service-date-list ul li {
  float: left;
  width: 25%;
  margin-bottom: 5px;
}
.service-date-list ul li span {
  font-size: 20px;
  font-weight: 400;
  color: #212529;
}
.service-date-day {
  float: left;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 15px;
}
.service-date-day p {
  font-weight: 500;
  font-size: 24px;
  color: #212529;
}
.info {
  margin: -2px 0 0 10px;
}
.service-date-day a {
  font-weight: 500;
  font-size: 16px;
  color: #0d6efd;
  position: absolute;
  right: 0;
  top: 8px;
}
.service-date-day a img {
  margin: -2px 6px 0 0;
}
.service-date-day p.common-date {
  float: left;
  font-size: 16px;
  font-weight: 400;
  color: #6c757d;
  margin: 0;
}
.service-date-day span {
  float: left;
  font-size: 16px;
  font-weight: 400;
  color: #212529;
  margin: 0 0 0 8px;
}
.service-date-day p.date-time {
  float: left;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #dc3545;
  margin: 15px 0 0 0;
}
.checkout-list {
  width: 100%;
  padding-top: 20px;
}
.checkout-list ul li {
  float: left;
  width: 100%;
  border-bottom: 1px solid #e9ecef;
  padding: 15px 0;
  position: relative;
}
.checkout-list ul li:last-child {
  border-bottom: none;
}
.checkout-list ul li p {
  float: left;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #0d6efd;
  margin: 0 0 5px 0;
}
.checkout-list ul li span {
  float: left;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #6c757d;
  margin: 0;
}
.checkout-list ul li p.service-heading {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #212529 !important;
}
.service-item p {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #6c757d !important;
}
.common-img {
  margin: 0 8px 0 0;
}
.checkout-list p {
  float: left;
  width: auto;
  font-weight: 500;
  font-size: 32px;
  color: #212529;
}
.service-item {
  float: left;
  width: 30%;
}
.stylish-time {
  float: left;
  width: 70%;
  position: absolute;
  right: 0;
  bottom: 20px;
}
.common-stylish-time {
  border: 1px solid #ced4da;
  font-size: 16px;
  font-weight: 400;
  color: #6c757d;
  border-radius: 8px;
  width: auto;
  float: left;
  margin: 0 20px 0 0;
}
span.remove-text {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #dc3545 !important;
  float: right !important;
  position: absolute;
  top: 8px;
  width: auto !important;
}
.checkout-card-header .btn {
  color: #C78963 !important;
  width: 100%;
  text-align: left;
  border-color: #FFF2EB !important;
  height: 40px !important;
}
.checkout-card-header span{
  font-size: 18px !important;
  font-family: 'Space Grotesk', sans-serif !important; 
  font-weight: 600 !important;
}
.checkout-card-header {
  background-color: white !important;
}
.checkout-card-header-active {
  background-color: #FFF2EB !important;
}
.checkout-card-header-active span{
  font-size: 18px !important;
  font-family: 'Space Grotesk', sans-serif !important; 
  font-weight: 600 !important;
}
.error-message{
  font-size: 14px;
  color: #dc3545;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.checkout-card-header-active .btn {
  color: #C78963 !important;
  width: 100%;
  text-align: left;
  border-color: #FFF2EB !important;
  height: 40px !important;
}
.selected-date-display{
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

