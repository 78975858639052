/*Filter Section Start*/
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Space+Grotesk:wght@300;400;500;600;700&family=Work+Sans:wght@300;400;500;600;700;800&display=swap');
body p{
  font-family: 'Work Sans', sans-serif;
}
 body h5 h6 h5 h4 span{
  font-family: 'Space Grotesk', sans-serif !important;
  font-weight: 900;
 }
body span {
  font-family: 'Space Grotesk', sans-serif !important;
  font-weight: 900 !important;
}
.refer-code{
  border: 2px solid #E0E0E0 !important;
  width: 300px !important;
  height: 56px !important;
  display: flex !important;
  margin-top: 15px !important;
  background-color: #FAFAFA;
  border-radius: 4px !important;
}

.refer-code p{
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  color: #333333;
  margin: 15px 15px;
  width: 200px !important;
}
.gst-enable-radio{
    width: 40% !important;
}
.refer-code h6{
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  color: #ECB390  ;
  margin: 17px 10px 16px 100px;
  cursor: pointer;
}
.Refer-section h1{
  font-size: 24px;
  font-weight: 600 !important;
  font-family: 'Space Grotesk', sans-serif !important;
  color: #333333 !important ;
  margin-bottom: 30px !important;
}
.Refer-section p {
  font-size: 14px;
  color: #808080;
  font-family: 'Work Sans', sans-serif !important;
}
.Refer-section h6 {
  font-size: 14px;
  color: #333333;
  font-family: 'Work Sans', sans-serif !important;
}
.no-image-found{
  width: 100%;
  height: 400px !important;
  margin: 0 !important;
  padding: 0 !important;
}
.service-details-singlePage{
  font-size: 14px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  color: #7F7F7F !important;
  margin-top: 2px !important;
}
.service-details-singlePage a{
  font-size: 14px !important;
  font-family: 'Work Sans', sans-serif !important;
}
.service-address-details{
  padding: 0 !important;
}
.time-modal{
  width: 100% !important;
  margin-left: 12% !important;
}
.filter-sideBar{
  margin-top: 0 !important;
}
.reset-fliter-set{
  font-size: 16px !important;
  margin-top: 5px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  text-align: end !important;
}
.filter-sideBar h6{
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500px !important;
  margin: 0px;
  color: #333333 !important;
}
.filter-sideBar label{
 color: #7F7F7F !important;
 font-size: 14px !important;
 font-family: 'Work Sans', sans-serif !important;
 font-weight: 400 !important;
}
.filter-sideBar .form-check-input[type=checkbox]{
  border: 2px solid #C78963 !important;
  border-radius: 0px !important;
}
.explore-page-section{
  background-color: #F5F5F5 !important;
}
.explorePage{
  background-color: #f3f0f0 !important;
}
.breadCum-list{
  background-color: #F5F5F5 !important;
}
.card-list {
  width: 100%;
  margin-left: 0px;
  border-radius: 8px !important;
}
.explore-card-style h4{
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  margin: 0 !important;
  padding: 0 !important;
  word-break: break-all !important;
}
.explore-card-style{
  height: 100px;
}

.card-img-top {
  position: relative;
  padding: 5px;
  height: 240px !important;
  width: 100% !important;
  border-radius: 8px !important;
  margin: 0 auto !important;
}
.view-details-btn{
  width: 100% !important;
  background-color: #FAE9DF !important;
  border-color: #F3CFB8;
  margin: 0 !important;
  color: #602F10 !important;
  border-radius: 0px 0px 5px 5px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  height: 100% !important;
}
.view-details-btn:hover{
  background-color: #404041 !important;
  color: #ECB390 !important;
  border-color: #404041 !important;
}
.footer-view-details{
  border-color: #F3CFB8;
  border-top: 0px !important;
  padding: 0 !important;
  margin: 0 !important;
}
.service-gender-name{
  font-size: 14px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  color: #7F7F7F !important;
}
.fiter-header{
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.item-service-name{
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #333333 !important;
}
.item-service-description{
  font-size: 14px !important;
  font-weight: 300 !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #333333 !important;

}
.view-cart-btn p span{
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.viewcard-tab{
  background-color: #D9F7BE !important;
  width: 100% !important;
  position: fixed !important;
  bottom: 10% !important;
}
.view-cart-tab-btn{
  background-color: #52C41A !important;
  border-color: #52C41A !important;
  width: 60% !important;
  height: 40px !important;
  border-radius: 8px !important;
}
.viewcart-service{
  color: #333333 !important;
  Font-size: 16px !important;
  float: right;
  margin-right: 50px !important;
}
.view-save{
  color: #52C41A !important;
  font-weight: 800 !important;
}

.ion-icon {
  color: black !important;
}
.explore-search-icon{
  position: relative;
  left: 4% !important;
}
.explore-nav-search{
  position: relative;
  left: 8% !important;
}

.filter-card{
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  margin-bottom: 0 !important;
}
.filter-card-mobile{
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  margin-bottom: 0 !important;
  width: 90% !important;
  margin: 15px;
}
.filter-card-mobile span{
  font-size: 14px !important;
}
.filter-card-mobile h5{
  font-size: 14px !important;
}
.accordion-header{
  padding:0!important;
}
.accordion-header h5{
  margin:0;
}
.accordion-button:not(.collapsed) {
  color: #000 !important;
  background-color: #fff !important;
}

.accordion-button:focus {
  border-color: #fff !important;
}
.reset{
  font-weight: 500 !important;
  font-size: 20px !important;
}

.filter-reset {
  float: left;
  width: 100%;
  padding: 15px;
  border: 1px solid #e9ecef;
}
.filter-reset .filter-icon {
  font-size: 24px;
  color: #602f10;
  background: url(../images/filter.png) 0px 7px no-repeat;
  padding: 0 0 0 40px;
  font-weight: 500;
}
.filter-reset .reset {
  float: right;
  font-size: 20px;
  color: #0d6efd;
}
.filter-section .card-header .card {
  border: 1px solid #e9ecef;
 
}
.filter-section .card-header {
  background-color: #fff;
  position: relative;
}
.filter-section .card-header .btn {
  float: left;
  width: 100%;
  font-size: 24px;
  color: #000;
  font-weight: 500;
  text-align: left;
  padding: 5px 0;
}
.filter-section .card-header .btn:before {
  content: "";
  position: absolute;
  top: 25px;
  right: 30px;
  background: url(../images/arrow.png) no-repeat;
  width: 20px;
  height: 12px;
}
.filter-section .card-header .btn.collapsed:before {
  transform: rotate(180deg);
}
.filter-section .form-check-label {
  font-size: 16px;
  color: #000;
  line-height: 24px;
  font-weight: 400;
}
.filter-section .common-tag {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  font-family: 'Work Sans', sans-serif !important;
  color: #7F7F7F;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 10px;
}
.btn-check:focus + .btn,
.btn:focus,
.form-check-input:focus,
.form-control:focus {
  box-shadow: none !important;
}
.form-check-input[type=checkbox] {
  border: 2px solid #C78963 !important;
  border-radius: 0 !important;
}
.filter-section .filter-section .common-tag:hover {
  color: #d46724;
}
.search-box {
  text-indent:1.6em;
  margin-bottom: 15px;
  border-radius: 8px !important;
  color: #6c757d !important;
  font-weight: 300 !important;
  font-size: 16px !important; 
}
.explore-item-badges{
  display: inline-block !important;
  width: 250px !important;
  word-break: break-word !important;
  margin: 0 auto !important;
  height: 35px !important;
}
.explore-item-badges span {
  float: left;
  margin: 1px 0px !important;
  font-size: 8px;
}
.line-search{
  margin-top: 0 !important;
  padding-top: 0 !important;
  margin-left: 20px !important;
}
.exploremain-search-box{
  text-indent:2em;
  margin-top: 5px !important;
  margin-bottom: 0px;
  color: #6c757d !important;
  font-weight: 300 !important;
  font-size: 16px !important; 
  border-top: none !important;
  border-left:none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-color: #6c757d !important;
  background-color: transparent!important;
}
.service-search-explore{
  margin-top: 0px !important;
  margin-bottom: 10px;
  color: #6c757d !important;
  font-weight: 300 !important;
  font-size: 16px !important; 
  border-top: none !important;
  border-left:none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-color: #6c757d !important;
  background-color: white !important;
}
.service-search-explore::placeholder{
  font-size: 12px !important;
}
.loacation-count{
  position: relative;
  text-align: end;
  left: 90%;
  bottom: 10px;
}
.location-names span{
  color: #7F7F7F !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: 'Work Sans', sans-serif !important;
  line-height: 10px !important;
}
.search-box-nav {
  text-indent:1.5em;
  border-radius: 8px !important;
  color: #6c757d !important;
  font-weight: 300 !important;
  font-size: 16px !important; 
  width: 332px !important;
  height: 40px !important;
  background-color: #EBEBEB !important;
  border-color: #EBEBEB !important;
}

/*Filter Section End*/
/*Explore Section Start*/
.breadcrumb_outer {
  background: #f8f9fa;
  background: rgb(248, 249, 250);
  background: linear-gradient(
    90deg,
    rgba(248, 249, 250, 1) 0%,
    rgba(248, 249, 250, 1) 30%,
    rgba(248, 249, 250, 1) 100%
  );
}
.breadcrumb_outer li a {
  color: #7F7F7F !important;
  font-size: 12px;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
  margin: 0 !important;

}
.breadcrumb_outer li span {
  font-size: 12px;
  color: #7F7F7F !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  margin: 0 !important;

}
.breadcrumb_outer .breadcrumb {
  margin: 0;
  padding: 5px;
}
.search-location {
  border-bottom: 1px solid #e9ecef;
  padding: 20px;
  position: relative;
}
.search-location img {
  position: absolute;
  top: 32px;
  left: 32px;
}
.search-location .form-control {
  width: 375px;
  height: 42px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding-left: 35px;
}
.explore-card-images {
  width: 90%;
  margin: 0 auto ;
 
}
.explore-card-images .card-title {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  font-family: Space Grotesk;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height:px;
}
.explore-card-images .card-text {
  font-size: 12px;
  font-weight: 400;
  color: #6c757d;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 21px;
}
.explore-card-images ul {
  float: left;
  padding: 0;
  margin: 0;
}
.explore-card-images ul li {
  float: left;
  width: 32%;
  border: 1px solid #e9ecef !important;
  border-radius: 8px !important;
  background: #e9ecef;
  margin-right: 20px;
  margin-bottom: 20px;
}
.explore-card-images ul li:nth-child(3n + 0) {
  margin-right: 0;
}
.explore-card-images ul li:hover {
  box-shadow: 0px 5px 10px #e5e5e5;
  border-radius: 8px !important;
  border: none !important;
}
.explore-card-images ul li:hover .btn-primary {
  background: #404041;
  color: #ecb390;
  border: 1px solid #404041;
}

.explore-card:hover {
  box-shadow: 0px 5px 10px #e5e5e5;
  border-radius: 8px !important;
}

.explore-card:hover .btn-primary {
  background: #404041;
  color: #ecb390;
  border: 1px solid #404041;
}
.card-list ul {
  float: left;
  padding: 0;
  margin-bottom: 20px;
  height: 70px;
  overflow: auto;
}

.card-list ul li {
  float: left;
  width: auto;
  margin-right: 8px;
  text-align: center;
  margin-bottom: 0;
}
.card-list ul li:hover {
  box-shadow: none !important;
  border: 1px solid #e9ecef !important;
}
.card-list ul li:nth-child(3n + 0) {
  margin-right: 8px !important;
}
/* .card-list ul li span {
  font-size: 10px;
  color: #343a40;
  font-weight: 400;
  padding: 8px 0px;
  border-radius: 5px !important;
  line-height: 24px;
} */
.card-item-review-count{
  font-size: 12px !important;
  margin-left: 10px !important;
  color: #7F7F7F !important;
  font-weight: 300 !important;
  margin-top: 2px !important;
  font-family: 'Work Sans', sans-serif !important;
}
.card-item-review-profile{
  font-size: 12px !important;
  margin-left: 10px !important;
  color: #7F7F7F !important;
  font-weight: 300 !important;
  font-family: 'Work Sans', sans-serif !important;
  position: relative;
  top: -5px !important;
}
.card-item-review-count-none{
  font-size: 12px !important;
  margin-left: 10px !important;
  color: #7F7F7F !important;
  font-weight: 300 !important;
  margin-top: 2px !important;
  font-family: 'Work Sans', sans-serif !important;
  visibility: hidden;
}
.item-badge{
  color: #343a40 !important;
  background-color: #E9ECEF !important;
  font-size: 8px !important;
 
  width: 100% !important;
  padding: 0 !important;
}

.explore-card-images ul li a {
  float: left;
  width: 100%;
}
.explore-card-images .btn-primary {
  background: #FAE9DF;
  border-color: #FAE9DF;
  width: 100%;
  border-radius: 8px;
  font-weight: 500;
  font-size: 18px;
  color: #602F10;
}
.explore-card-images ul li .like-bg {
  border: 1px solid #ced4da;
  background: #f8f9fa;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  right: 10px;
  position: absolute;
  top: 9px;
}
.explore-card-images ul li .like-icon {
  position: absolute;
  top: 7px;
  right: 6px;
}
.explore-card-images ul li .rating-bg {
  background: #198754;
  border-radius: 8px;
  position: absolute;
  width: 64px;
  height: 26px;
  bottom: 235px;
  left: 20px;
}
.explore-card-images ul li .rating-icon {
  position: absolute;
  top: 3px;
  left: 6px;
}
.explore-card-images ul li .rating-bg span {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  float: right;
  margin-right: 7px;
}
.loadmore-outer {
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
.loadmore-outer a {
  border: 1px solid #ced4da;
  padding: 9px 17px;
  border-radius: 8px;
}
/*Explore Section End*/
/*Mobile Responsive Start*/
@media screen and (max-width: 767px) and (min-width: 320px) {
  .item-service-name {
    font-size: 12px !important;
}
.gst-enable-radio{
  width: 60% !important;
}
.service-details-singlePage {
  font-size: 12px !important;
}
  .card-list img{
    width: 100% !important;
   
    margin: 0 auto !important;
  }
  .card-list{
    width: 100% !important;
    margin: 0 auto !important;
  }

  .explore-search-icon {
    position: relative;
    left: 8% !important;
}
  .view-cart-cl{
display: none !important;
  }
  .view-cart-tab-btn{
    background-color: #52C41A !important;
    border-color: #52C41A !important;
    width: 100% !important;
  }
  .card-img-top {
    position: relative;
    padding: 10px;
    height: 280px !important;
    width: 100% !important;
    
  }
  .explore-card-images{
    width: 100%;
    padding: 20px;
  }
  .explore-card-images ul li {
    width: 100%;
  }
  .card-list ul li {
    width: auto !important;
  }
  .search-location .form-control {
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 600px) {
  .fav-icon-fill {
     position: absolute;
     left: 92.7%;
     top: 2%;
     color: red !important;
     background-color: white !important;
     border-radius: 8px !important;
     padding: 5px !important;
     cursor: pointer !important;
 }
 .fav-icon-unFill {
  position: absolute;
  left: 92.7%;
  top: 2%;
  background-color: white !important;
  border-radius: 8px !important;
  padding: 5px !important;
}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  
  .card-list {
    width: 100% !important;
    margin-left: 0!important;
    height: 300px;
}
}
 /*Mobile Responsive End*/

 /* IpadMini Responsive */

@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1) {

  .how-works-outer{
    height: auto !important;
  }
 .card-img-top {
   position: relative;
   padding: 3px;
   height: 240px !important;
   width: 100% !important;
   border-radius: 10px !important;
   margin: 0 auto !important;
}
.fav-icon-fill {
  position: relative;
  left: 83.7%;
  bottom: 380px !important;
  color: red !important;
  background-color: white !important;
  border-radius: 8px !important;
  padding: 5px !important;
}
.fav-icon-unFill {
  position: relative;
  left: 83.7%;
  bottom: 380px !important;
  background-color: white !important;
  border-radius: 8px !important;
  padding: 5px !important;
  cursor: pointer !important;
}
.explore-card-style h4 {
  font-size: 14px !important;
}
.explore-card-images .card-text {
  font-size: 10px;
}
}


