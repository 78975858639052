
/* Payment Failed CSS*/
.customer-review-list{
  float:left;
  width:100%;
  max-height: 405px;
  overflow: auto;
  overflow-x:hidden;
}
.business-gallery-page{
  float:left;
  width:100%;
  /* max-height: 500px; */
  /* overflow: auto;
  overflow-x: hidden; */
}
.payment-failed {
  float: left;
  width: 100%;
  text-align: center;
}
.sorry-img {
  float: left;
  width: 100%;
  margin-bottom: 40px;
}
.payment-failed p {
  font-weight: 500;
  font-size: 40px;
  color: #000;
  margin: 40px 0 10px 0;
}
.payment-failed p span {
  font-size: 40px;
  color: #f00;
}
.payment-failed span.pay-again {
  font-size: 24px;
  font-weight: 500;
  color: #6c757d;
}
.back-tryagain {
  float: left;
  width: 100%;
  margin: 40px 0;
}
.back-btn {
  width: 195px;
  font-size: 20px;
  font-weight: 500;
  color: #602f10;
  padding: 10px 20px;
  margin: 0 20px 0 0;
  border-radius: 8px;
  border: 1px solid #602f10;
  background: transparent;
}
.try-agian-btn {
  width: 195px;
  color: #602f10;
  font-weight: 500;
  font-size: 20px;
  background: #ecb390;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
}
/*Mobile Responsive Start*/
@media screen and (max-width: 767px) and (min-width: 320px) {
  .amount-text {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #343a40 !important;
    position: absolute;
    top: 70px;
    right: 24px !important;
    width: auto !important;
}
  .try-agian-btn,
  .back-btn {
    width: 130px;
  }
}
/* Payment failed css end  */

/* Payment success css start */
.myorder {
  position: relative;
}
.myorder-btn {
  background: #0d6efd;
  border-radius: 8px;
  color: #fff;
  font-size: 20px;
  padding: 10px 20px;
  font-weight: 500;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
}

.payment-sucess-order-btn{
  background: #0d6efd;
  border-radius: 8px;
  float:right !important;
  color: #fff;
  font-size: 20px;
  border: none;
  padding: 10px 20px;
}
.service-details {
  float: left;
  width: 100%;
  margin-top: 30px;
}
.service-details span {
  float: none;
  font-weight: 400;
  font-size: 16px;
  color: #0d6efd;
}
.service-details p {
  font-weight: 400;
  font-size: 14px;
  color: #6c757d;
  margin: 5px 0;
}
.service-list-payment {
  width: 100%;
  max-height: 550px;
  margin-top: 20px;
}
.service-list-payment ul li {
  float: left;
  width: 100%;
  border-bottom: 1px solid #e9ecef;
  padding: 15px 0;
  position: relative;
}
.service-list-payment ul li:last-child {
  border-bottom: none;
}
.service-list-payment ul li p {
  float: left;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #0d6efd;
  margin: 0 0 5px 0;
}
.service-list-payment ul li span {
  float: left;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #6c757d;
  margin: 0;
}
.service-list-payment ul li p.service-heading {
  font-size: 20px;
  font-weight: 500;
  color: #212529;
}
.service-item p {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #6c757d !important;
}
.service-list-payment ul li span.total-amt {
  float: right;
  font-size: 24px;
  font-weight: 500;
  color: #212529;
  margin-top: 15px;
  width: auto;
  margin: 0;
}
.service-list-payment ul li p.date-month {
  float: left;
  width: auto;
  font-size: 24px;
  color: #000;
  font-weight: 500;
  margin: 0;
}
.amount-text {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #343a40 !important;
  position: absolute;
  top: 70px;
  right: 130px;
  width: auto !important;
}
.time-order {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #343a40 !important;
  position: absolute;
  top: 70px;
  right: 10px;
  width: auto !important;
}
.common-busniess-box {
  float: left;
  width: 100%;
  margin-top: 30px;
  overflow: auto;
}
.common-busniess-box-proposal{
  float: left;
  width: 100%;
  margin-top: 20px;
  overflow: auto;
}
.common-busniess-box ul li {
  float: left;
  width: 50%;
  margin-bottom: 15px;
}
.common-busniess-box ul li span.details-content {
  font-size: 18px;
  font-weight: 400;
  color: #343a40;
  margin-bottom: 5px;
}
.common-busniess-box ul li span {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}
.common-busniess-box a {
  float: left;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  color: #0d6efd;
  margin-bottom: 5px;
}
.common-busniess-box a img {
  margin: 0 10px 0 0;
}
.payment-sucessfull {
  float: left;
  width: 100%;
  text-align: center;
}
.payment-sucessfull p {
  font-weight: 500 !important;
  font-size: 28px !important;
  color: #000 !important;
  margin-top: 15px;
}
.payment-sucessfull span {
  float: none;
  font-weight: 500 !important;
  font-size: 28px !important;
  color: green !important;
}
.payment-sucessfull span.thanku-text {
  float: left;
  font-size: 18px !important;
  font-weight: 400 !important;
  color: #6c757d !important;
}
.back-home {
  font-size: 20px;
  font-weight: 500;
  color: #602f10;
  border-radius: 8px;
  padding: 10px 20px;
  background: transparent;
  border: 1px solid #602f10;
  width: 200px;
  margin-top: 20px;
}
/*Mobile Responsive Start*/
@media screen and (max-width: 767px) and (min-width: 320px) {
  .payment-sucessfull {
    margin-top: 25px;
  }
  .myorder-btn {
    font-size: 14px;
    right: 10px;
    top: -10px;
    padding: 5px 8px;
  }
  .myorder h6{
    font-size: 14px;
  }
  .service-list-payment ul li p.date-month{
    font-size: 18px;
  }
  .service-list-payment ul li span.total-amt{
    font-size: 18px;
    float:left;
  }
  .myorder h4{
    margin-bottom: 20px;
  }
  .service-details{
    margin-top: 20px;
  }
}
/* Payment success css end */

/* Payment cancelled css start */
.continue-btn {
  width: 350px;
  padding: 8px;
  background: #ecb390;
  font-weight: 500;
  border-radius: 8px;
  font-size: 20px;
  color: #602f10;
  border: none;
  margin-top: 20px;
}
.form-label {
  color: #212529;
  font-weight: 500;
  font-size: 20px;
}
.common-text {
  font-size: 16px;
  font-weight: 400;
  color: #7F7F7F;
  line-height: 24px;
  word-break: break-all;
}
.accordion-body {
  padding: 20px;
}
.accordion-button {
  font-size: 24px;
  font-weight: 500;
  color: #212529;
}
.accordion-button:not(.collapsed) {
  color: #ecb390;
  background-color: #404041;
}
.accordion-button:focus {
  border-color: none;
  box-shadow: none;
}
.accordion-button::after {
  display: none;
}
p.select-mode {
  font-size: 20px;
  font-weight: 500;
  color: #212529;
}
.form-check-label {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
p.refund-process {
  font-size: 16px;
  color: #212529;
  font-weight: 400;
  padding-left: 25px;
}
p.terms-condtion {
  font-size: 12px;
  font-weight: 400;
  color: #6c757d;
  padding-left: 25px;
}
p.terms-condtion span {
  float: none;
  font-size: 12px;
  color: #0d6efd;
}
.change-btn {
  float: right;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #0d6efd;
  color: #0d6efd;
  border-radius: 8px;
  position: absolute;
  right: 20px;
  top: 10px;
  padding: 10px 30px;
  background: transparent;
}
.accordion-button:not(.collapsed) .change-btn {
  display: none;
}
p.reason-cancel {
  font-size: 16px;
  font-weight: 400;
  color: #212529;
}

/*Mobile Responsive Start*/
@media screen and (max-width: 767px) and (min-width: 320px) {
  .accordion {
    margin-top: 25px;
  }
  .continue-btn {
    width: 100%;
  }
  .common-text {
    font-size: 12px !important;
    font-weight: 400;
    color: #6c757d;
    line-height: 24px;
    word-break: normal;
  }
  
}
/* Payment cancelled css end*/

/* Payment Cancellation status css start */
.sucessfully-cancel {
  float: left;
  width: 100%;
  text-align: center;
}
.sucessfully-cancel p {
  font-weight: 500;
  font-size: 24px;
  color: #000;
  margin: 30px 0 0 0;
}
.sucessfully-cancel p span {
  float: none;
  font-size: 24px;
  font-weight: 500;
  color: green;
}
.sucessfully-cancel span.request-process {
  font-size: 18px;
  font-weight: 400;
  color: #6c757d;
  margin: 20px 0 25px 0;
}
.back-home-btn {
  color: #602f10;
  font-weight: 500;
  font-size: 20px;
  border: 1px solid #602f10;
  border-radius: 8px;
  width: 220px;
  background: transparent;
  padding: 10px;
}
/*Mobile Responsive Start*/
@media screen and (max-width: 767px) and (min-width: 320px) {
  .sucessfully-cancel {
    margin-top: 20px;
  }
}
/* Payment Cancellation status css end */