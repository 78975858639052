@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Space+Grotesk:wght@500;600&family=Work+Sans:wght@300;400;500&display=swap');
body {
  overflow-x: hidden;
}
body p{
  font-family: 'Work Sans', sans-serif;
}
body .navbar{
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 900 !important;
}
body span {
  font-family: 'Space Grotesk', sans-serif !important;
  font-weight: 900 !important;
}
body .form-check-label{
  font-family: 'Work Sans', sans-serif;
}
body h1, h2, h3, h4, h5, h6{
  font-family: 'Space Grotesk', sans-serif; 
}
body ::placeholder{
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
}
body Button{
  font-family: 'Work Sans', sans-serif;
  font-weight: 500;
}
#normal-nav{
  display: block !important;
}
#advance-nav{
  display: none !important;
}
.request-badge-accepted{
  background-color: #52C41A !important;
  font-size: 10px !important;
}
.request-badge-cancelled{
  background-color: #F5222D !important;
  font-size: 10px !important;
}

.request-badge-pending{
  background-color: #FADB14 !important;
  font-size: 10px !important;
  color: black !important;
}
.request-container{
  width: 80%;
  margin: 0 auto !important;
}
.single-view-request{
  margin: 10px 0px 50px 0px;
}
.single-view-request h1{
  font-size: 20px;
  color: #333333;
  font-family: 'Work Sans', sans-serif;
}
.single-view-request p{
  color: #7F7F7F;
  font-size: 12px;
  font-family: 'Work Sans', sans-serif;
}
.single-view-request td{
  font-size: 14px;
  color: #333333;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400 !important;
  text-align: center !important;
}
.single-view-request thead{
  background-color: #F5F5F5;
  font-size: 16px;
  color: #7F7F7F;
}
.close-text{
  font-size: 16px !important;
  cursor:pointer;
  color: #602F10;
  font-family: 'Work Sans', sans-serif;
}
.request-view-email{
  font-size: 14px;
  color:#333333;
  font-weight: 400 !important;
}
.pending-badge{
  color: #FAC414 !important;
  background-color: #FEFFE6 !important;
  width: 100%;
}
.accepted-badge{
  color: #52C41A !important;
  background-color: #F6FFED !important;
  width: 100%;
}
.deny-badge{
  color: #F5222D !important;
  background-color: #FFF1F0 !important;
  width: 100%;
}
.goback-to-request{
  color: #C78963 !important;
  display: flex;
}
.goback-to-request span{
  position: relative;
  margin-top: 2px;
  cursor: pointer;
}
.goback-to-request h6{
  cursor: pointer;
}
.single-view-request h2{
 font-size: 16px;
 color: #C78963;
 font-family: 'Work Sans', sans-serif;
 font-weight: 600 !important;
}
.single-view-request hr{
  margin: 0;
  padding: 0;
}
.single-view-request h3{
 font-size: 16px;
 color: #000000;
 font-family: 'Work Sans', sans-serif;
}
.request-accept-btn{
  background-color:  #ECB390 !important;
  width: 100%;
  color: #602F10 !important;
  font-family: 'Work Sans', sans-serif;
  height: 48px !important ;
  border-radius: 8px !important;

}
.request-reject-btn{
  border-color:  #ECB390 !important;
  width: 100%;
  color: #C78963 !important;
  font-family: 'Work Sans', sans-serif;
  height: 48px !important;
  border-radius: 8px !important;

}
.request-decision-btn{
  width: 50%;
  margin:  20px auto !important;
}


.success-note p{
   font-size: 14px;
   color: #7F7F7F;
   font-weight: 400;
   font-family: 'Work Sans', sans-serif;
}
.success-note h6{
  font-size: 14px;
  margin-top: 15px;
  color: #000000;
  font-weight: 500;
  font-family: 'Work Sans', sans-serif;
}
.suggestion-places:hover{
  background-color:#fae9df;
  color: #333333;
  font-family: 'Work Sans', sans-serif;
 font-size: 14px;
}
.suggestion-places{
  color: #333333;
  font-family: 'Work Sans', sans-serif;
 font-size: 14px;
 line-height: 24px;
}
 .loading-location{
    text-align: center;
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
.locationAuto{
  width: 100% !important;
  border: none !important;
  height: 38px !important;
  font-size: 14px;
  font-family: 'Work Sans', sans-serif !important;
}
.location-searchBar-outer{
  background-color: white !important;
  height: 40px !important;
  width: 100% !important;
  border-radius: 10px !important;
  border: 1px solid #EBEBEB !important;
}
.location-searchbar-inner{
 width: 98%;
}
.find-my-location{
  color: #C78963 !important;
  font-size: 12px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}
.searchIcon-location{
  position: relative;
  top: 8px;
  left: 10px;
}
.sugeestion-container{
  height: 250px;
}
.locationAuto:focus{
  border: none !important;
  outline: none !important;
}
.location-modal-style{
  /* height: 450px !important; */
  padding: 10px !important;
  width: 100% !important;
}

.location-enter-btn{
  background: transparent !important;
  border: none !important;
  position: relative;
  right: 25px;
  top: 5px;
  font-weight: 400 !important;
  color:#6c757d !important;
}

.view-all-font{
  font-size: 16px !important;
  cursor: pointer;
  color: #C78963;
  font-family: 'Work Sans', sans-serif !important;
  margin-top: 20px !important;
  font-weight: 500 !important;
}
.service-cat-badge{
  color: white !important;
  background-color: #d49168 !important;
}
.business-update-btn{
  color: black;
  background-color: #d49168 !important;
}
.business-back-btn{
  border-color: #d49168 !important;
  color: #eaa377 !important;
}
.location-modal-body{
  margin-bottom: 50px !important;
}
.admin-tabel-data td{
  font-size: 16px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important; 
}
.step-one-title h4{
  font-size: 18px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  color: #333333 !important;
  line-height: 18px !important;
}
.step-one-title p{
  font-size: 12px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  color: #7F7F7F !important;
  line-height: 18px !important;
}
.business-register-form .form-label {
  font-size: 14px !important;
  line-height: 21px !important;
  color: #333333 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
}
.business-edit-form .form-label {
  font-size: 14px !important;
  line-height: 21px !important;
  color: #333333 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
}

.business-register-form{
  width: 80% !important;
  margin: 0 auto !important;
}
.business-edit-form{
  width: 100% !important;
  margin: 0 auto !important;
}
.next-btn-businessSteps{
  background-color: #ECB390 !important;
  border-color: #C78963 !important;
  color: #602F10 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.back-btn-businessSteps{
  background-color: white !important;
  border-color: #ECB390 !important;
  color: #C78963  !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  border-radius: 8px !important;
}
.secondary-mobile-outer{
  background-color: white !important;
  border-radius: 5px;
  border: 1px solid #BFBFBF !important;
  margin: 0px 10px !important;
  height: 38px !important;
  padding: 0 !important;
}
.selected-text-category{
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.badge-bg{
  color: #7F7F7F !important;
  background-color: #F5F5F5 !important;
  border-color: #F5F5F5 !important;
  font-size: 12px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
}
.no-border-style{
  border: none !important;
  height: 18px !important;
  padding: 0px !important;
  margin: auto 0 !important;
  width: 100% !important;
}
.std-divider{
  margin: auto 0 !important;
  padding: auto 0 !important;
  color: black;
}

.admin-tabel-data .form-check-input {
  border-radius: -0.75em !important;
  border: 2px solid #BFBFBF  !important;
}
.admin-coupon-list-head .form-check-input {
  border-radius: -0.75em !important;
  border: 2px solid #BFBFBF  !important;
}
.status-active{
  color: #C78963 !important;
}
.location-modal-body p{
  font-size: 20px !important;
  color: #333333 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  text-align: center;
  margin-top: 10px;
}
.service-list-header-option h3{
  font-size: 20px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.deleteTrash{
  color: #F5222D  !important;
  font-size: 28px !important;
  margin-top: 20px !important;
}
.inventory-selection-list{
  width: 150px !important;
  font-size: 16px !important;
  color: #7F7F7F !important;
  font-family: 'Work Sans', sans-serif !important;
}
.location-in-navbar span h6{
  font-size: 16px !important;
  color: #602F10 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.location-in-navbar span{
  font-size: 18px !important;
  color: #602F10 !important;
}
.inventory-btn-list{
  display: flex;
  float: right;
  margin-bottom: 10px;
  margin-right: 18px;
}

.add-service-btn{
  background-color: #ECB390 !important;
  border-color: #C78963 !important;
  color: #602F10 !important;
  width: 132px;
  
}
.staff-update-service-btn{
  background-color: #ECB390 !important;
  border-color: #C78963 !important;
  color: #602F10 !important;
  width: 132px;
  font-size: 20px !important;
}
.staff-delete-service-btn{
  background-color: white !important;
  border-color: red !important;
  color: red !important;
  width: 132px;
  font-size: 20px !important;

}
.staff-cancel-service-btn{
  background-color: white !important;
  border-color: #C78963 !important;
  color: #602F10 !important;
  width: 132px;
  font-size: 20px !important;

}
.myOrder-id-info{
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #333333 !important;
}
.myOrder-outer-view {
  background-color: #FCFCFC;
}
.coupon-headers p{
  margin-top: 10px;
  float: right;
  color: #1890FF;
  font-size: 12px;
  font-family: 'Work Sans', sans-serif !important;
  cursor: pointer;
  font-weight: 500!important;
}
.fiter-btn-coupon{
  background-color: white !important;
  color: #7F7F7F !important;
  border-color: #EBEBEB !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  text-align: center !important;
  border-radius: 8px !important;
  vertical-align: middle !important;
}
.searchbar-copon{
  height: 40px !important;
  width: 280px !important;
}
.searchbar-copon::placeholder{
  font-size: 16px !important;
  color: #7F7F7F !important;
  font-weight: 400!important;
  font-family: 'Work Sans', sans-serif !important;

}
/* .coupon-headers-inner {
  width: 78% !important;
  position: relative !important;
  left: 20%;
  top: 35px!important;
} */
.coupon-headers-inner {
  width: 63% !important;
  position: relative !important;
  left: 35%;
  top: 35px!important;
}
.coupon-headers-reset{
  margin-top: 10px;
    float: right;
    color: #1890FF;
    font-size: 12px;
    font-family: 'Work Sans', sans-serif !important;
    cursor: pointer;
    font-weight: 500!important;
}
.request-headers-reset{
  margin-top: 10px;
  float: right;
  color: #1890FF;
  font-size: 12px;
  font-family: 'Work Sans', sans-serif !important;
  cursor: pointer;
  font-weight: 500!important;
}
.myOrder-filter-inner p{
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.myOrder-filter-inner label{
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.myOrder-filter-inner .form-check-input{
  border-radius: 0 !important;
  border: 2px solid #C78963 !important;
  height: 12px !important;
  width: 12px !important;
  margin-top: 7px !important;
}
.myOrder-filter-card{
  border-radius: 0 !important;
}
.no-result-found{
  font-size: 20px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  margin-top: 30px !important;
}
.myOrder-verification-otp{
  color: #333333;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.myOrder-more-info{
  color: #C78963 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
  cursor: pointer;
}
.myOrder-payment-info h6{
  font-size: 14px !important;
  margin-bottom: 15px !important;
}
.myOrder-payment-info p{
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #333333 !important;
  line-height: 10px !important;
}
.payment-user-contact-info p{
  font-size: 14px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  color: #333333 !important;
}
.payment-user-contact-info h6{
  font-size: 14px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  color: #333333 !important;
}

.payment-user-contact-info h5{
  font-size: 14px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  color: #333333 !important;
}
.payment-sucess-need-help h1{
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  color: #333333 !important;
  margin-bottom: 15px !important;
}
.payment-sucess-need-help h2{
  font-size: 12px !important;
  color: #C78963 !important;
  cursor: pointer;
  margin: 0;
  padding: 4px 0px;
}
.payment-sucess-need-help p {
  font-size: 12px !important;
  color: #C78963 !important;
  cursor: pointer;
  margin: 0;
  padding: 4px 0px;
}
.help-icons{
  position:relative;
  margin-right: 5px !important;
  margin-top: 2px !important;
}
.payment-info-image{
  width: 64px !important;
  border-radius: 4px !important ;
  height: 64px !important;
}
.payment-sub-cards p{
  font-size: 14px !important;
  color: #7F7F7F !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.payment-sub-cards h6{
  font-size: 20px !important;
  color: #C78963 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600 !important;
}
.payment-sub-cards h1{
  font-size: 16px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.payment-suceess-service-info{
  display: flex;
  margin-top: 20px !important;
}
.payment-suceess-service-info p{
  position: relative;
  margin-right: 5px !important;
  font-size: 14px !important;
  color: #808080 !important;
  margin-top: 2px !important;
}
.payment-suceess-service-info h6{
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #333333 !important;
}
.payment-info-card{
  background-color: #FAFAFA !important;
}
.payment-info-inner{
  border-right: 1px solid #BFBFBF;
}
.payment-info-text{
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #333333 !important;
}
#thanks-note-outer{
  background-image: linear-gradient(#F6FFED, rgba(246, 255, 237, 0)100%);
}
#transcation-failed{
  background-image: linear-gradient(#FFF1F0, rgba(255, 241, 240, 0)100%);
}
.thanks-note h1{
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: 'Space Grotesk', sans-serif !important; 
}
.reschedule-outer{
  width: 80% !important;
  margin: 0 auto !important;
}
.thanks-note p{
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.thanks-note h6{
  font-size: 12px !important;
  font-weight: 500 !important;
  margin-top: 10px !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #333333 ;
}
.thanks-note h5{
  font-size: 12px !important;
  color: #C78963 !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  cursor: pointer;
}
.payment-order-info h6{
  font-size: 12px !important;
  color: #C78963 !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  cursor: pointer;
}
.payment-orderInfo-details h6{
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #333333 !important;
  margin-left: 0px;
}
.payment-orderInfo-details p{
  color: #7F7F7F !important;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 12px !important;
  color: #7F7F7F;
  margin-left: 0px;
}
.cancel-form-outer{
  width: 85% !important;
}

.payment-cancel-details h6{
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #333333 !important;
  margin-left: 50px;
}
.payment-cancel-details p{
  color: #7F7F7F !important;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 12px !important;
  color: #7F7F7F;
  margin-left: 50px;
}
.payment-info-totalCard h1{
  font-size: 14px !important;
  color: #7F7F7F;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
  margin-top: 8px !important;
}
.payment-info-totalCard h6{
  font-size: 14px !important;
  color: #333333;
  font-weight: 500 !important;
}
.payment-total-card-inner p{
  font-size: 14px !important;
  color: #333333 !important;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
  line-height: 10px !important;
}
.payment-total-card-inner .green_text{
  color: #52C41A !important;
}
.orderid-card{
  width: 300px;
  margin: 0 auto;
  background-color: #F5F5F5 !important;
}
.payment-success-icon{
  width: 97px !important;
  margin: 0 auto !important;
}
.manual-error-style{
  margin-top: 0.25rem !important;
  font-size: .875em !important;
  color: #dc3545 !important;
  font-weight: 400 !important;
  margin-bottom: 0 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.favorite-icon-favscreen{
  color: #F5222D !important;
  cursor: pointer !important;
  margin: 2px 2px 5px 2px !important;    
}
.fav-business-images{
  width: 80px !important;
  border-radius: 4px !important ;
  height: 80px !important;
}
.generate-coupon-code{
  width: 50% !important;
  margin: 0 auto !important;
  color: #602F10 !important;
  background-color: #ECB390 !important;
  border-color: #C78963 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 16px !important;
}
.save-coupon-code{
  width: 100% !important;
  margin: 0 auto !important;
  color: #602F10 !important;
  background-color: #ECB390 !important;
  border-color: #C78963 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 16px !important;
}
.cancel-coupon-code-cancel{
   margin: 0 auto !important;
  width: 100%;
  color: #7F7F7F  !important;
  background-color: white !important;
  border-color: #E0E0E0 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 16px !important;
}
.generate-coupon-code-cancel{
  margin: 0 auto !important;
  width: 50%;
  color: #7F7F7F  !important;
  background-color: white !important;
  border-color: #E0E0E0 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 16px !important;
}
.marketing-body-content{
  width: 80% !important;
  margin: 0 auto !important;
  padding: 0 !important;
}
.code-modal-body{
  width: 80% !important;
  margin: 0 auto !important;
}
.custom-code-container{
  width: 80% !important;
  margin: 0 auto !important;
} 
.code-generate-sec {
  padding: 20px;
  width: 80% !important;
  margin: 0 auto !important;
}
.marketing-code-share span{
  color: #C78963 !important;
  cursor: pointer !important;
  font-size: 20px !important;
}
.code-action-btn {
  width: 60% !important;
  margin: 30px auto !important;
 }
.custom-code-container ::placeholder{
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  color: #7F7F7F !important;
}
.code-modal-body .form-select{
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  color: #7F7F7F !important;
}
.custom-code-container .form-select{
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  color: #7F7F7F !important;
}
.form-label-coupon-code{
  font-size: 14px !important;
  color: #333333 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
}
.display-slot-time{
  width: 100% !important;
  display: flex !important;
  margin: 0 auto !important;
}
.display-slot-time p {
  color: #333333  !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.verify-email{
  background-color: #ECB390 !important;
  color: #602F10 !important;
  border-color:#ECB390 !important ;
}
.verify-email-component h6 span{
  color: #C78963 !important;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  font-weight: 500 !important;
  margin-top: 0px !important;
  padding-top: 0px !important;
  cursor: pointer !important;
}
.filter-service-dashboard {
  float: left;
  width: 25% !important;
  height: 40px !important;
  margin-left: 54px !important;
  margin-right: 10px;
  font-size: 16px !important;
  color: #7F7F7F !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  font-family: 'Work Sans', sans-serif !important;
}
.filter-items-view{
  background-color: white !important;
  width: 100% !important;
  margin: 0 auto !important;
  border-radius: 8px 8px 0px 0px !important;
}

.filter-badges {
  width: auto !important;
  margin: 8px 0px !important;
  padding: 0px 4px !important;
}
.badges-filter{
  background-color: #F5F5F5 !important;
  color: #7F7F7F !important;
  font-size: 12px !important;
}
.item-names{
  font-size: 16px !important;

}
.logo-header{
  width: 176px !important;
  height: 40px !important;
}
.how-it-works-card{
  border: none!important;
  margin: 0!important;
}
.how-it-works-card .card-body{
  margin-left: 0!important;
  padding-left: 0!important;
}
.how-it-works-card p{
  font-size: 16px!important;
  color: #7F7F7F !important;
  font-weight: 400!important;
  font-family: 'Work Sans', sans-serif;
  
}
.how-it-works-card h5{
  font-size: 20px!important;
  font-family: 'Work Sans', sans-serif;
}
.our-features-images img{
  width: 200px!important;
  height: 200px!important;
 
}
.our-features-images p{
  font-size: 20px !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #7f7f7f !important;
  font-weight: 500 !important;
}
.logo {
  width: 141.47px !important;
  height: 32px !important;
  margin-top: 0 !important;
}
.modal-body {
  padding: 0px 10px !important;
}
/* .navbar-view{
  margin: 0 auto !important;
  padding: 0 90px !important;
} */
.profile-dropdown-mobile.dropdown-menu[data-bs-popper] {
  left: 246px !important;
  margin-top: 0 !important;
  padding: 0 !important;
  margin-bottom: 20px !important;
}
.profile-dropdown.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -60px !important;
  margin-top: 0 !important;
}

.nav-search-hide{
  display: flex !important;
  margin: 10px !important;
}
.sign-in{
  background-color: #ECB390 !important;
  color: #602F10 !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  border-color: #ECB390 !important;
}
.sign-in-dropdowns{
  font-family: 'Work Sans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  border-bottom: 1px solid #EBEBEB !important;
  text-align: start !important;
}
.sign-in-dropdowns:hover {
  color: #C78963!important;
  background-color: white !important;
}
.drop-down-items{
  width: 220px !important;
  margin: 0 auto !important;
  position: relative;
  right: 100px;
}
.drop-down-items.dropdown-menu[data-bs-popper] {
  margin-top: 2px !important;
  padding: 0 !important;
  margin-bottom: 20px !important;
  position: absolute !important;
  left: -43px;

}
.register-header{
  font-size: 20px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  margin-top: 20px !important;
}
.register-form ::placeholder {
  font-family: 'Work Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.register-form{
  font-family: 'Work Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.register-button{
  background-color: #ECB390 !important;
  border-color: #ECB390 !important;
  color:#602F10 !important;
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  margin-bottom: 20px !important;
}
.agree-terms{
  font-size: 12px !important;
  margin: 2px 5px !important;
  font-family: 'Work Sans', sans-serif !important;
}
.agree-terms-error{
  font-size: 12px !important;
  margin: 2px 5px !important;
  font-family: 'Work Sans', sans-serif !important;
  color: rgb(225, 7, 7) !important;
  font-weight: 400 !important;
}
.resend-otp{
  color: #C78963 !important;
  cursor: pointer;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}
.sign-up-referral{
  font-size: 12px !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
  color: #BFBFBF !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;

}
/* .optional{
  color: #C78963 !important; 
  cursor: pointer;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
} */
.reset-eye-icon{
  position: relative;
  left: 85% !important;
  bottom: 30px !important;
  cursor: pointer;
}
.reset-eye-icon-conform{
  position: relative;
  left: 85% !important;
  bottom: 30px !important;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #C78963 !important;
  border-color: #C78963 !important;
}
.login-register{
  font-size: 14px !important; 
  color: #C78963;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  cursor: pointer;
}
.login-register:hover{
 color: #C78963 !important;
}
.link-text-register{
  color: rgb(52, 193, 240) !important;
  font-weight: 400 !important;
  cursor: pointer;
}

#mobile-view{
  display: none !important;
  width: 100% !important;
}
.review-headers{
  font-family: 'Work Sans', sans-serif !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  
}
.business-backdrop{
  background-color: black !important;
  opacity: 12% !important;

}
.container-xxl {
  zoom: 80% !important; 
  /* max-width: 2560px !important; */
}
.container{
  zoom: 90% !important;
}


.review-comment{
  font-family: 'Work Sans', sans-serif;
  font-weight: 400 !important;
  color:#6C757D !important
}
.carosel-review-card{
  box-shadow: 0px 4px 4px rgb(0 0 0 / 8%);
  border-radius: 15px !important;
  border: none !important;
  padding: 10px 30px;
  margin: 36px 5px 0px 20px;
}
.carousel .carousel-status {
display: none !important;
}
.carousel-icon{
  width: 64px;
  height: 64px;
}
.carousel .control-dots .dot {
  transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2pxrgba(0,0,0,0.9);
  background: #ECB390 !important;
  border-radius: 50%;
  width: 15px !important;
  height: 15px !important;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
.note-text{
  color:#F5222D !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px !important;
}
.important-note-modal{
  padding: 20px 100px !important;
}
.info-note{
  font-size: 12px !important;
  color: #808080 !important;
  margin-top: 18px !important;
}
.continue-btn-outer{
  width: 80% !important;
 
}
.info-continue-btn{
  background-color: #ECB390 !important;
  color:#602F10 !important;
  font-family: 'Space Grotesk', sans-serif !important;
  width: 70% !important;
  margin-left: 15% !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.info-continue-btn:hover{
  background-color: #602F10 !important;
  color: #ECB390 !important;
  border-color:#ECB390 !important ;
}
.gallery-add-btn{
  float: right !important;
}
.image-container {
  position: relative;
  width: 50%;
}

.gallery-image-hover {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  bottom: 30%;
  left: 23%;
  right: 0;
  background-color: #ECB390;
  overflow: hidden;
  width: 50%;
  height: 50%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: .3s ease;
  transition: .3s ease;
  cursor: pointer;
}

.image-container:hover .overlay {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.hover-text {
  color: #602F10;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: 'Space Grotesk', sans-serif !important;
}
.image-delete-cancel{
  color: #602F10 !important;
  background-color: white !important;
  border-color: #602F10 !important;
  width: 70% !important;
  padding: 10px !important;
  margin-left: 50px;
  font-weight: 600 !important;
}
.update-commission-cancel{
  color: #602F10 !important;
  background-color: white !important;
  border-color: #602F10 !important;
  width: 90% !important;
  padding: 10px !important;
  margin:0 auto;
  font-weight: 600 !important;
}
.update-commission-update{
  color: #602F10 !important;
  background-color: #ECB390 !important;
  border-color: #ECB390 !important;
  width: 90% !important;
  padding: 10px !important;
  margin: 0 auto;
  font-weight: 600 !important;
}
.commission-btn-group{
  padding: 20px 50px;
}
.image-delete-cancel:hover{
  background-color: #602F10 !important ;
  color: white !important;
}

.custom-modal .modal-content{
  width: 800px ;
  height: 65% ;
  position: absolute ;
  top: 50% ;
  left: 50% ;
  transform: translate(-50%, -50%) ;
}


@media only screen and (max-width: 768px) {
  .custom-modal .modal-content {
    height: auto;
    max-width: 90%;
  }
}

#add-group{
  margin-left: 40px;
}
@media only screen and (max-width: 768px) {
  #add-group{
    margin-left: 0;
  }
}

.email-error{
  color: red !important;
    font-family: 'Work Sans', sans-serif !important;
    font-size: 17px !important;
    font-weight: 400 !important;
    margin-top: 13px !important;
    margin-bottom: 0px !important;
   
}

.email-errorArea{
  color: red !important;
  height: 100px;

}

.image-delete-delete{
  color: #602F10 !important;
  background-color: #ECB390 !important;
  border-color: #ECB390 !important;
  width: 70% !important;
  padding: 10px !important;
  margin-right: 50px;
  font-weight: 600 !important;
}
.image-delete-delete:hover{
  background-color:#602F10 !important ;
  color:#ECB390 !important;
}
.delete-image-title{
  margin-left: 35px !important;
  font-size: 20px!important;
  color: black !important;
  font-weight: 600 !important;
}
.previewImage{
  width: 400px !important;
  height: 400px !important;
}

#fav-icon-hover{
  position: relative !important;
  bottom: 400px !important;
  left: 25% !important;
  z-index: 1 !important;
  background-color: white !important;
  width: 190px !important;
  border-radius: 20px !important;
  display: none;
  color: red !important;
  margin: 0px 0px 15px 10px !important;

}
#fav-icon-fill-outer {
  position: relative !important;
}
#fav-icon-hover p{
  padding: 0px 0px 0px 35px  !important;
  font-family: 'Space Grotesk', sans-serif !important;
  font-size: 14px !important;
  margin: 0 auto !important;
}

#fav-icon-fill-outer:hover #fav-icon-hover {
  display:block;
  position: absolute !important;
}


.fav-icon-fill {
  position: relative;
  left: 86%;
  bottom: 385px;
  color: red !important;
  background-color: white !important;
  border-radius: 5px !important;
  padding: 5px !important;
  cursor: pointer !important;
}

.fav-icon-unFill{
  position: relative;
  left: 86%;
  bottom: 385px;
  background-color: white !important;
   border-radius: 5px !important;
   padding: 5px !important;
  cursor: pointer !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  color: #C78963!important;
  border-bottom: 4px solid #C78963 !important;
}
.explore-single-tab{
  color: black!important;
  border: none !important;
  font-family: 'Space Grotesk', sans-serif !important;
  font-size: 20px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important
}
/* .service-main-outer .active{
  color: #602F10!important;
  border-bottom: 2px solid #602F10 !important;
} */
/* .service-main-outer .nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #ECB390 !important;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
} */
/* .service-main-outer .nav-tabs .nav-link {
  width: 400px !important;
  text-align: center !important;
} */


.home-style{
  width: 300px !important;
}
.single-page-review{
  color: white !important;
  font-size: 10px !important;
  margin: 3px 5px !important
}
.single-page-review-count{
  font-size: 12px !important;
  position: relative;
  top: 2px;
}
.single-page-review-profile{
  color: white !important;
  font-size: 10px !important;
  margin: 0px 5px 10px 5px  !important
}
.single-page-review-count-profile{
  font-size: 12px !important;
  position: relative;
  top: -4px;
}
.review-content{
  width: 100% !important;
  margin: 5px 0px 0px 0px !important;
  background-color: #52C41A !important;
  border-radius: 6px !important;
  Padding: 4px, 6px, 4px, 6px !important;
}

.review-content-explore{
  width: 60px !important;
  background-color: #52C41A !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  color: white !important;
  display: flex !important;
  height: 20px !important;
  padding: 0 !important;
  cursor:auto !important;
  border-color: #52C41A !important;
}
.review-content-explore:hover{
  border-color: #52C41A !important;
}
.review-content-explore p{
  margin: 0 auto !important;
}
.icon-explore-page{
  position: relative;
  margin: 0px 5px 0px 0px !important;
  top: 1px !important;
  color: white !important;
}
.review-content-explore span{
   color: white !important;
   font-size: 10px !important;
}
.review-count-explore{
  font-size: 12px;
  margin: 2px auto !important;
  color: #7F7F7F  ;
  font-family: 'Work Sans', sans-serif !important;

}
.review-content-profile{
  background-color: #52C41A !important;
  border-radius: 4px !important;
  width: 50px !important;
  height: 20px !important;
}

.date-apply{
  background-color: #ECB390 !important;
  color:#602F10 !important;
  font-family: 'Work Sans', sans-serif;
}
.calendar-view{
  width: 80% !important;
  margin: 0 auto !important;
  padding: 20px !important
}
/* .form-group{
  position:relative;
}
.form-group-referral{
  position:relative;
}
.form-group input{
  position:relative;
  z-index:9;
  background:transparent;
  border:1px solid rgb(180, 176, 176);
  padding: 5px;
  font-family: 'Work Sans', sans-serif !important;
}
.form-group-referral input{
  position:relative;
  z-index:9;
  background:transparent;
  border:1px solid rgb(180, 176, 176);
  padding: 5px;
  font-family: 'Work Sans', sans-serif !important;
}
.form-group label{
  position:absolute;
  left:12px;
  top:7px;
  z-index:1;
  color:rgb(180, 176, 176);
  font-family: 'Work Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 100 !important;
}
.form-group-referral label{
  position:absolute;
  left:12px;
  top:7px;
  z-index:1;
  color:rgb(180, 176, 176);
  font-family: 'Work Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 100 !important;
}

.form-group label::after{
  content:"*";
  color:red;
  font-size: 14px !important;
}
.form-group-referral label::after{
  content:"";
 
  font-size: 14px !important;
}
input[required]:valid + label{
  display: none;
} */

.download-btn{
  background-color: #ECB390 !important;
  color:#602F10 !important;
  font-family: 'Space Grotesk', sans-serif !important;
}
.download-btn-close{
  color: #602F10 !important;
  border-color:#ECB390 !important;
  font-family: 'Space Grotesk', sans-serif !important;
}
.download-btn:hover{
  background-color: #602F10 !important;
  color: #ECB390 !important;
  border-color:#ECB390 !important ;
}
.download-btn-close:hover{
  background-color: #602F10 !important;
  color: #ECB390 !important;
  border-color:#ECB390 !important ;
}
.modal{
  background-color: transparent !important;
  
}
.pagination-box{
  margin-top: 20px !important;
}
.page-item{
  border-radius: 8px !important;
}
.page-link{
  color: #602F10 !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}
.page-item.active .page-link{
  background-color: white !important;
  border: none !important;
  color: black !important;
  font-weight: 900 !important;
}
.gallery-image-user{
  width: 250px !important;
  height: 200px !important;
  border-radius: 8px !important;
}
.order-id-view{
  display: flex;
}
.order-otp-orders{
  letter-spacing: 8px !important;
  margin: 30px 0px 0px 0px !important;
}
.line-hr{
  margin: 0;
  border: 1px solid #E9ECEF;
  background-color: #E9ECEF;
}
.order-status-orderView{
  margin-left: 50px !important;
  margin-top: 10px !important;
}
.badge-font{
  color: white !important;
}
.badge-font-pending{
  color: #333333 !important;
  background-color: #FADB14!important;
  font-size: 12px !important;
  font-weight: 400 !important;
}
.badge-font-all{
  color: white !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.refund-order-view{
  width: 100% !important;
}
.card-order-view{
  width: 100% !important;
}
.customer-details-order{
  color: gray !important;
}
.order-total-service-no h6{
  margin-left: 200px !important;
}
.order-status-right h6{
  margin-left:100px!important;
}
.order-otp-verfication h6{
  margin-left: 90px !important;
}
.order-view-two-btn{
  text-align: end !important;
}
.order-btn-Reschedule{
  background-color: white !important;
  border-color: #602F10 !important;
  color: #602F10 !important;
  border-radius: 8px !important;
 padding: 5px 10px 5px 10px !important;
 margin-right:10px !important ;
}
.order-btn-Reschedule:hover{
  background-color: #ECB390 !important;
}
.order-btn-Cancel:hover{
  background-color: #602F10 !important;
  color: wheat!important;
}
.order-btn-Cancel{
  background-color:  #ECB390 !important;
  border-color: #ECB390 !important;
  color: #602F10 !important;
  border-radius: 8px !important;
  padding: 5px 10px 5px 10px !important;
}
.total-service-tabel{
  text-align:right !important;
  padding-right: 30px !important;
}
.total-amount-inorder h6{
  margin-left :230px !important; 
}
.para-bold-text{
  font-weight: 900 !important;
}
.about-one-outer{
  width: 90% !important;
  margin: 0 auto !important;
}
.about-one-img{
  width: 380px;
  float: right !important;
}
.about-two-img{
  width: 350px;
  float: left !important;
}
.about-two-left li{
  list-style-type: circle;
  font-weight: 400 !important;
  margin-left: 20px !important;
}
.about-two-left{
  margin-top: 80px !important;
}
.about-three-left{
  margin-top: 80px !important;
}
.about-three-left ul li {
  list-style-type: circle;
  font-weight: 400 !important;
  margin-left: 20px !important;
  margin-top: 20px !important;
  line-height: normal !important;
}
.about-three-outer{
  width: 80% !important;
  margin: 0 auto !important;
}
.about-two{
  width: 80% !important;
  margin: 0 auto !important;
}
.about-three-img{
  width: 350px;
  float: right !important;
}
.about-one-inner{
  margin-top: 60px !important;
}
.about-one-inner p{
  font-family: 'Work Sans', sans-serif !important;
  font-size: 16px !important;
}
.about-two-left p{
  font-size: 16px !important;
}
.about-three-left p{
  font-size: 16px !important;
}
.need-help-section li{
  list-style-type: none;
}
.business-rupee-icon{
  margin-bottom: 100px !important;
}
.business-left-menu ul li a {
  font-size: 18px !important;
  font-family: 'Space Grotesk', sans-serif; 
}
.business-left-menu ul li img{
  width: 20px !important;
}
.order-services-table-view{
  overflow-x: scroll !important;
}
.order-view-status{
  float: right !important;
  width: 70% !important;
  margin-bottom: 10px !important;
}
.order-verify-otp-btn{
  background-color: #ECB390 !important;
  border-color: #ECB390 !important;
  float: left !important;
  margin-bottom: 10px !important;
}
.comments-container-order{
  height: 300px;
}
.order-update-btn{
  background-color: #FFF2EB !important;
  border-color: #C78963 !important;
  float: left !important;
  margin-bottom: 10px !important;
  border-radius: 8px !important;
  color: #602F10 !important;
  padding: 5px 30px !important;
}
.order-view-box{
  float: left !important;
  width: 100% !important;
  margin-bottom: 10px !important;
}
.order-view-btn{
  width: 100px !important;
  margin-left: 10px !important;
  border-radius: 12px !important;
  float: right !important;
  margin-bottom: 10px !important;
}
.order-list-tabel td p{
  font-size: 16px !important;
  margin-bottom: 0 !important;
}
.calendar-icon{
  font-size: 16px !important;
  margin-bottom: 7px !important;
}
.order-calendar{
  float: right;
  /* display: flex !important; */
}
.order-search-bar{
  width: 100% !important;
  border-radius: 12px !important;
  font-size: 18px !important;
}
.service-header-view .back-text{
  cursor: pointer;
  text-align: end !important;
  color: #C78963 !important;
  font-size: 16px !important;
}
.service-header-view p{
  color: #333333;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 20px;
  font-weight: 500 !important;
  line-height: 10px !important;
}
.service-header-view h6{
  font-size: 12px !important;
  color: #7F7F7F !important;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
}

.service-register-form label{
  font-family: 'Work Sans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #333333 !important;
}
.inventory-tabel td p{
  font-size: 16px !important;
  /* text-align: center !important; */
  margin-left: 10px !important;
  margin-bottom: 0px !important;
}
.business-welcome{
  color : #602F10;
}
.proposal-view-left{
  width: 80% !important;
  margin-top: 20px;
}

.proposal-view-left h6{
  font-size: 16px !important;
}
.proposal-view-left span {
  font-size: 16px !important;
}
.proposal-view-right {
  width: 100% !important;
  margin-top: 20px;
}
.proposal-view-right h6{
  font-size: 16px !important;
}
.proposal-view-right span {
  font-size: 16px !important;
}

.admin-dashboard-card{
  width: 210px !important;
  background-color: #F8F9FA !important;
  border-radius: 12px !important;
  height: 120px !important;
}
.tabel-data-content td{
  font-size: 16px !important;
}
.tabel-data-content th{
  color: #6C757D !important;
}
a {
  font-family: 'Work Sans', sans-serif !important;
}

h6{
  font-weight: 600 !important;
}

 .explore-home-icon{
  margin: 5px !important;
  color: black !important;
  
 }
 .discount-font{
  font-family: 'DM Serif Display' !important;
  font-weight: 400 !important;
  
 }
.clearCart-btn{
  color: #602F10 !important;
  background-color: #ECB390 !important;
  border: none !important;
  padding: 10px 50px !important;
  border-radius: 8px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.clearCart-btn:hover{
  font-family: monospace !important;
  
}

 
.payment-success-service-details{
  /* background-color: #F8F9FA !important; */
  border-color: #F8F9FA !important;
  margin-top: 0%;
  
}
.payment-success-customer-details{
  background-color: #F8F9FA !important;
  border-color: #F8F9FA !important;
  margin-top: 0%;
}

.payment-success-help-center{
  float: right !important;
  margin-top: 5%;
}

.replace-cancel-btn{
  color: #602F10 !important;
  background-color: white !important;
  border-color: #602F10 !important;
  width: 70% !important;
  padding: 10px !important;
  margin-left: 50px;
  font-weight: 600 !important;
}
.order-cancel-btn{
  color: #C78963 !important;
  background-color: white !important;
  border-color: #C78963 !important;
  width: 100% !important;
  padding: 10px !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
}
.order-save-btn{
  color: #602F10 !important;
  background-color:#ECB390 !important;
  border-color: #602F10 !important;
  width: 100% !important;
  padding: 10px !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
}
.replace-btn{
  color: #602F10 !important;
  background-color: #ECB390 !important;
  border-color: #ECB390 !important;
  width: 70% !important;
  padding: 10px !important;
  margin-right: 50px;
  font-weight: 600 !important;
}

.comment-decline{
  color: #602F10 !important;
  background-color: #ECB390 !important;
  border-color: #ECB390 !important;
  float: right !important;
  width: 100%;
  text-align: center !important;
  height: 48px;
  border-radius: 8px;
  font-weight: 600 !important;
  
}

.modal-width{
  padding: 0px 50px !important;
}
/* 
.decline-comment {
  width: 400px !important;
} */
.drop-message{
  font-size: 20px !important;
  font-weight: 400 !important;
  margin-top: 30px;
}
.sss{
  cursor: pointer;
}
.order-id-details{
   word-break: break-word;
}
ol,
ul {
  padding-left: 0px !important;
}
.add-service-button-save{
  width: 100%;
  padding: 5px !important;
  margin-bottom: 20px !important;
  font-size: 16px !important;
  color: #602F10 !important;
  background-color: #C78963 !important;
  font-family: 'Work Sans', sans-serif !important;
  height: 40px;
}
.add-service-button-cancel{
  width: 100%;
  padding: 5px !important;
  margin-bottom: 20px !important;
  font-size: 16px !important;
  color: #C78963!important;
  background-color: white !important;
  font-family: 'Work Sans', sans-serif !important;
  border-color: #C78963 !important;
  height: 40px;
}

/* .navbar {
  height: 80px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.1);
} */
.homeSearch {
  margin-top: 5%;
}
.support-img-align{
  width: 430px;
  height: 243px;
 margin-top: 10%;
 margin-left: 10%;
}
.support-form{
  width: 80%;
}

.support-button{
  width: 90%;
  margin: 0px 0px 0px 20px;
  background-color: #ECB390 !important;
  color: #602F10 !important;
  border-color:#ECB390 !important ;
  border-radius: 8px !important;
  Padding : 9px, 17px, 9px, 17px;
}

.support-mail-align{
  margin-left: 20%;
  margin-top: 10%;
}

.accordion-button.collapsed::before{
  position: absolute;
  content:'';
  right:15px;
  top:22px;
  background: url(../images/down-arrow.png) no-repeat;
  width: 16px;
  height: 16px;
  transform: rotate(0deg);
}
.accordion-button::before{
  position: absolute;
  content:'';
  right:15px;
  top:22px;
  background: url(../images/down-arrow.png) no-repeat;
  transform: rotate(180deg);
  width: 16px;
  height: 16px;
}
.img-fluid.view-cart-button{
  float: right;
  position:fixed;
  font-size: 16px;
  padding: 0px;
	width:80px;
	height:80px;
	bottom:15px;
	right: 0px;
	background: #ecb390 !important;
  font-weight: 700;
	color: #602f10 !important;
	border-radius:50px;
	text-align:center;
  border-color: transparent;
}
.img-fluid.view-cart-button:hover{
  background-color: black!important;
}

.icon-cart{
  height:30px;
   width:50px;
}
.home-search-text {
  min-height: 288px;
  line-height: 120px;
  letter-spacing: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 80px;
  line-height: 120%;
  text-align: start;
}
.sec-main-text {
  color: #d49168 !important;
}
.icon-logo {
  height: 510.88px;
}
.home-search-bar {
  margin-top: 50px;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.mainServices {
  margin-top: 5%;
}
.service-img {
  width: 224px;
  height: 224px;
}
.trendingServices {
  margin-top: 6.5%;
}
.trending-service-heading {
  text-align: start;
  margin-bottom: 30px;
  margin-left: 10%;
}
.section-header {
  text-align: start;
  margin-bottom: 20px;
}

.howItWorks {
  margin-top: 6.5%;
}
.customer-reviews {
  margin-top: 6.5%;
}
.footer {
  background-color: #ffebdf;
  width: 100%;
  height: 300px;
  margin-top: 30px;
}
.footer-company {
  margin-top: 5%;
  margin-left: 20%;
}
.footer-business {
  margin-top: 5%;
  margin-left: 15%;
}
.footer-app-info {
  margin-top: 5%;
  margin-left: 10%;
}
.review-card {
  margin-top: 50px;
  margin-left: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 856px;
  background: #ffffff;
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.175);
  border-radius: 12px;
  border: none;
}
.review-ratings {
  margin-left: 40%;
}
.register-modal {
  width: 600px;
  border-radius: 16px;  
}
.register-form {
  padding: 20px 50px;
}
.modal-header {
  border-bottom: none !important;
}
.business-header-group {
  background: linear-gradient(
    360deg,
    rgba(255, 235, 223, 0) 0%,
    #ffebdf 48.21%,
    #ffebdf 100%
  );
}
.business-home-header {
  padding-top: 5%;
}
.business-main_header > h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 64px;
  line-height: 120%;
}
.business-sub-header > h6 {
  margin-top: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 120%;
}
.selection-buttion-section {
  margin-top: 6%;
}
.selection-button {
  background-color: #d49168 !important;
  border-color: #d49168 !important;
}
.statistical-card .card-1 {
  margin-top: 7%;
  margin-left: 7%;
  margin-right: 7%;
  width: 352px;
  height: 144px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  border-radius: 12px;
}
.partner-content {
  margin-top: 10%;
}
.how-it-works-content {
  margin-top: 15%;
}
.image-uploader {
  border: 2px dashed #BFBFBF;
  border-radius: 12px;
  color: #7F7F7F;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 385px;
}
.image-uploader > img {
  height: 140px;
}
.add-time-slot{
  display: flex !important;
  cursor: pointer;
}
.add-time-slot span{
  font-size: 22px !important;
  color: #C78963 !important;
  font-weight: 900 !important;
}
.add-time-slot p{
  cursor: pointer;
  color: #C78963 !important;
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.slayText{
  cursor: pointer;
  color: #C78963!important;
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.slaytextBlack {
  cursor: pointer;
  color: #333333!important;
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.required:after {
  content: " *";
  color: red;
}
.dashboard-header {
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.dashboard-body {
  width: 100%;
}

/* .sidebar-content {
  width: 305px !important;
} */

.menuItem {
  display: flex;
  align-items: center !important;
  gap: 2rem;
  height: 2.5rem;
  margin-left: 5rem;
  transition: all 300ms ease;
  font-size: 20px;
  margin-bottom: 3rem;
}
.menuItem:first-child {
  margin-top: 15%;
}
.email-footer-icon{
  margin-top: 5px !important;
  margin-right: 6px !important;
}

.support-email-icon{
  margin-top: 0px !important;
  margin-right: 6px !important;
}


/* .main-content {
  height: 100%;
  width: 90%;
  margin-left: 5% !important;
 
} */
.user-view-footer{
  margin-top: 10%;
}
.verification-status-card {
  background: #ffffff;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.1);
}
.dashboard-home-card {
  width: 100%;
  border-radius: 8px;
  text-align: start;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.home-card-value {
  margin-top: 0px !important;
  margin-left: 10px;
  font-family: "Space Grotesk";
  font-weight: 500 !important;
  font-size: 30px !important;
  /* line-height: 120%; */
  color: black;
  display: flex !important;
}
.rupee-icon{
  margin-top: 15px !important;
}
.icon-header {
  font-family: "Space Grotesk";
  /* font-style: normal; */
  font-weight: 500;
  font-size: 18px;
  /* line-height: 120%; */
  margin-top: 10px;
  margin-left: 20px;
}
.slaylewks-table .table-head {
  font-size: 24px;
  color: #6c757d;
  font-weight: 500;
}
.slaylewks-table td {
  font-size: 18px;
  color: #000;
  font-weight: 400;
}
.slaylewks-table .discount {
  color: #198754;
}
.slaylewks-table .active-status {
  color: #0d6efd;
}
.slaylewks-table .disable-status {
  color: #6c757d;
}
.form-check-input {
  border: 1px solid #e5e5e5 !important;
}
.upload-section {
  display: flex;
  margin-bottom: 10px;
}
.upload-section > div {
  width: 50%;
}
.drop-zone {
  margin-bottom: 10px;
  padding: 40px 10px;
  height: inherit;
  border: 2px dashed #e9ebeb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.drop-zone:focus {
  outline: none;
}
.image-preview {
  height: inherit;
}
.preview-image {
  width: 100%;
  height: inherit;
  display: block;
  margin-bottom: 10px;
}
.preview-message {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5%;
}
.slaylewks-outline-btn {
  color: #602F10 !important;
  background-color: white !important;  
  border-color: #d49168 !important;

}
.slaylewks-inventory-btn{
  color: #602F10 !important;
  background-color: white !important;
  border-color: #d49168 !important;
  width: 80px !important;
  float: left !important;
  margin-left: 10px !important;
  margin-bottom: 5px !important;
}
.slaylewks-inventory-btn:hover {
  color: #ffffff !important;
  background-color: #d49168 !important;
  border-color: #d49168 !important;
}
.search-inventory{
  width: 70% !important;
  float: right;
}
.search-orderlist{
  width: 70% !important;
}
.fiters-section-inventory{
  width: 100% !important;
  margin-left: 0;
  display: flex !important;
}
.fiters-section-inventory p{
  color: #1890FF !important;
  font-size: 14px !important;
  font-family: 'Work Sans', sans-serif !important;
  cursor:pointer !important;
}
.fiters-section-inventory.form-select{
  margin-left: 5% !important;
}
.slaylewks-outline-btn:hover {
  color: #ffffff !important;
  background-color: #d49168 !important;
}
.slay-outline-btn {
  color: #ffffff !important;
  background-color: #d49168 !important;
  border-color: #d49168 !important;
}
.slay-outline-btn:hover {
  color: #ffffff !important;
  background-color: #d49168 !important;
}
.business-left-menu {
  float: left;
  width: 100%;
  height: auto;
  border-right: 1px solid #e9ecef;
}

.coupon-modal-size.modal.show .modal-dialog {
  transform: none;
  width: 370px;
}

.errorMessage {
  color: red;
}

.code-modal-body .form-select {
    font-size: 16px !important;
    font-family: 'Space Grotesk', sans-serif !important;
    font-weight: 400 !important;
    color: #504f4f !important;
}



.location-initial .modal-dialog{
  /* margin-left: 28px;
  margin-top: 70px;
  margin-right: 28px;
  margin-bottom: 70px; */
}

.business-left-menu ul li {
  float: left;
  width: 100%;
  padding: 15px;
}
.business-left-menu ul li:hover,
.business-left-menu ul li.active {
  background: #fae9df;
}
.business-left-menu ul li img {
  float: left;
  margin: 3px 0;
}
.business-left-menu ul li a {
  float: left;
  width: auto;
  font-weight: 500;
  font-size: 20px;
  color: #212529;
  margin-left: 20px;
}
#sub-heading{
  float: none !important;
}

.business-label-form{
  margin-bottom: 18px !important;
}

.support-logout {
  float: left;
}
.support-logout li img {
  margin: 6px 0 !important;
}
.support-logout li:hover {
  background: transparent !important;
}
/* .support-logout li a {
  color: #6c757d !important;
} */
.support-logout li a.logout {
  color: #dc3545 !important;
}
h2 {
  font-size: 32px;
  color: #000;
  font-weight: 500;
  padding-top: 20px;
}
h3 {
  font-weight: 500;
  font-size: 28px;
  color: #212529;
}
.setting-suptitle {
  float: left;
  width: 100%;
  font-size: 16px;
  color: #6c757d;
  font-weight: 400;
  margin: 10px 0 30px 0;
}
.info-icon {
  margin: 0 0 0 10px;
}
.common-box-border {
  width: 23%;
  margin: 0 0 0 32px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  border-radius: 12px;
  cursor: pointer;
}
.common-box-border:first-child {
  margin-left: 0;
}
.modal-80w {
  min-width: 80%;
}
.order-view-body {
  padding: 20px;
}
.appointment-view-body{
  padding: 20px;
  font-family: 'Work Sans', sans-serif !important;
}
.appointment-view-body h4{
  font-size: 20px;
  font-family: 'Work Sans', sans-serif !important;
}
.appointment-customer-details h6{
  font-size: 14px !important;
  font-family: 'Work Sans', sans-serif !important;
}
.image-uploader-body h3{
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
}
.image-uploader-body{
  padding: 10px 30px !important;
}
.image-uploader-body label{
  font-size: 12px !important;
}
.provider-name-dashboard{
  font-family: 'Work Sans', sans-serif !important;
  color:#C78963 !important
}
.order-view-action-btns button {
  margin-right: 20px;
  padding: 5px 25px;
}
.order-view-otp {
  margin-top: 10px;
  margin-bottom: 40px;
}
.common-busniess-box {
  width: 100%;
}
.common-busniess-box ul li {
  float: left;
  width: 50%;
  margin-bottom: 15px;
}
.common-busniess-box ul li span {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}
.business-service ul li {
  float: left;
  width: 25%;
}
.view-text {
  font-size: 16px;
  font-weight: 400;
  color: #0d6efd;
  text-align: center;
}
.naturalimg img {
  padding: 10px;
  float: left;
}
.decline-accept {
  float: left;
  width: 100%;
  text-align: center;
}
.decline-btn {
  border: 1px solid #dc3545;
  border-radius: 5px;
  color: #dc3545;
  font-weight: 500;
  font-size: 20px;
  width: 300px;
  padding: 5px;
  display: inline-block;
  margin: 0 30px 0 0;
}
.decline-btn:hover {
  color: #dc3545;
}
.accept-btn {
  display: inline-block;
  width: 300px;
  background: #0d6efd;
  border-radius: 5px;
  color: #ffff;
  font-weight: 500;
  font-size: 20px;
  width: 300px;
  padding: 5px;
}
.accept-btn:hover {
  color: #fff;
}
.decline-accept a img {
  margin: -4px 0 0 0;
}
.order-customer-details h5 {
  margin-top: 10px;
  margin-bottom: 40px;
}
.common-busniess-box h3{
  margin-bottom:15px;
}
.common-busniess-box h6 {
  font-size: 18px !important;
  margin-bottom: 20px;
}
.common-busniess-box h6 span {
  width: 60%;
  float: right;
  Font-size: 16px;
}
.dropdown-toggle {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}
.dropdown-toggle::after {
  display: none !important;
}
footer {
  background: #fae9df;
  padding: 35px 0;
}
.footer-common h3 {
  font-weight: 500;
  font-size: 24px;
  color: #212529;
  margin-bottom: 15px;
  margin-left: 0 !important;
}
.email-weight{
  font-weight: 400!important;
}
.footer-common ul li {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.footer-common ul .email {
  cursor: pointer;
}
.footer-common ul li a {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
.footer-common ul li a:hover {
  color: #0d6efd;
}
.social-media {
  background: #ecb390;
  padding: 20px 0;
}
.social-media p {
  font-weight: 400;
  font-size: 16px;
  color: #602f10;
  margin: 0;
}
.social-media span {
  float: left;
  font-weight: 400;
  font-size: 16px;
  color: #602f10;
  margin: 0 10px 0 0;
}
.social-media ul li {
  float: left;
  width: auto;
}
.social-media ul li a {
  margin: 0 0 0 10px;
}
.social-media-inner ul li  a{
  text-decoration: none;
  cursor: pointer;
  color: #000000;
}
.active-coupons{
  color: #eaa377 !important;
  font-weight: 500 !important;
}
.expired-coupons{
  color: #F5222D !important;
  font-weight: 500 !important;

}
.rmsc {
  --rmsc-main: #d49168 !important;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px;
  --rmsc-radius: 4px;
  --rmsc-h: 40px;
}
.dropdown-heading-value span{
  font-weight: 200 !important;
}
.e1gn6jc30 span{
  font-weight: 200 !important;
}
.e11wid6y0::placeholder{
  font-size:medium;
}

.social-media-inner {
  float: right;
  width: 50%;
}
.modal-40w {
  min-width: 40%;
}
.date-right{
  text-align: right;
}
.marketing-body-content {
  width: 100%;
}

.code-modal-header h4{
  margin-left: 60px !important;
  font-size: 28px !important;
  font-weight: 600 !important;
  color: #333333 !important;
  font-family: 'Space Grotesk', sans-serif !important;
}


.upload-icon-btn {
  color: #333333  !important ;
  background: #FFFFFF !important;
  border-color: #EBEBEB  !important;
  border-radius: 8px !important;
}
.download-icon-btn {
  color: #333333 !important ;
  background: #FFFFFF !important;
  border-color: #EBEBEB  !important;
  border-radius: 8px !important;
}
.upload-body-content {
  padding-left: 15px;
  padding-right: 20px;
}
.cart-empty {
  float: left;
  width: 100%;
  text-align: center;
}
.empty-cart-inner {
  float: left;
  width: 100%;
  margin: 50px 0;
}
.explore-btn {
  background: #ecb390;
  border-radius: 8px;
  color: #602f10;
  display: inline-block;
  width: 480px;
  text-align: center;
  padding: 12px;
}
.explore-btn:hover {
  color: #602f10;
}
.cart-count {
  font-size: 12px;
  margin-left: -5px;
}
#lblCartCount {
  font-size: 26px;
  color: red;
  position: relative !important;
  bottom: 18px !important;
  right: 5px;
}
#lblCartCountNumber {
  font-size: 14px;
  color: white !important;
  position: relative !important;
  bottom: 28px !important;
  right: 22px !important;
  font-family: 'Work Sans', sans-serif !important;
}
#cart-icon-mobile{
  font-size: 30px !important;
  position: relative;
  left: 16px;
}

.badge {
  padding-left: 10px;
  padding-right: 10px;
}
.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
#eye {
  position: absolute;
  right: 30px;
  transform: translate(0, -50%);
  top: 18px;
  cursor: pointer;
}
#conform-eye {
  position: absolute;
  right: 22%;
  transform: translate(0, -50%);
  top: 62%;
  cursor: pointer;
}
/*15-06-2022*/
.dashboard-page{
  overflow: hidden;
}
.inventory-search{
  float:right;
  width:300px !important;
}
.filter-service{
  float: left;
  width: 100% !important;
  height: 40px !important;
  margin-left: 20px !important;
  font-size: 16px !important;
  color: #7F7F7F !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  font-family: 'Work Sans', sans-serif !important;
}
.asc-desc{
  float: left;
  width: 100px !important;
  margin: 0 0 10px 15px;
}
.Show-Cart{
  margin-top: 45px !important;
  margin-left: 76px;
  text-align: end;
  cursor: pointer;
  font-weight: 900;
}
.Edit-icon{
  cursor: pointer;
}
.navfont-size h6{
  margin-top: 14px !important;
}

 
/*Active Class*/
.active-menu{
  border-radius: 5px;
  background: #fae9df;
}
.active-Navbar{
  color: #d49168 !important;
}
.eye-icon{
  position: absolute;
  right: 30px;
  top: 8px;
}
.order-payment-status{
  float:left;
  width:100%;
}

.fc .fc-daygrid-day-frame {
  position: relative;
  height: 20px !important;
  background-color: white!important;
}

.fc-scrollgrid-sync-inner:visited,.fc-scrollgrid-sync-inner:active,.fc-scrollgrid-sync-inner:hover ,.fc-scrollgrid-sync-inner:focus {
  background-color: #FFF8F5 !important;
}

.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row-reverse;
  font-size: 18px;
  font-weight: 500;
}
.inventory-reset {
  cursor: pointer !important;
  color: #0d6efd !important;
  margin-top: 8px !important;
  float: left !important;
  margin-left: 8px !important;
}
.ActiveBtn{
  background-color: #000 !important;
}
.download-note{
  font-size: 14px !important;
  color: #1890FF !important;
  font-style: italic !important;
  font-weight: 500 !important;
}
.delete-btn-admin{
  color: #F5222D !important;
  border-color:#F5222D !important;
  background-color: #FFF1F0 !important;
  width: 132px;
  font-family: 'Work Sans', sans-serif !important;
  height: 40px;

}
.ionic-icon-btn{
  position: relative;
  top: 3px;
  font-size: 18px;
}
.generate-btn-admin{
  color: #602F10 !important;
  border-color:#C78963  !important;
  background-color: #C78963 !important;
  width: 132px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500!important;
  height: 40px;
}
.status-active{
  color: #C78963 !important;
}
.marketing-header{
  font-size: 20px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500!important;
  margin-top: 10px;
}
.marketing-sub-header{
  font-size: 20px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500!important;
  margin-top: 10px;
  color:#C78963;
}
.admin-coupon-list-head{
  background-color: #F5F5F5;
  height: 50px;
  color: #7F7F7F;
  font-size: 16px;
  margin-top: 20px;
}
.admin-tabel-heading{
  align-items: center !important;
  vertical-align: middle !important;
}

.coupon-headers .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {   
  color: #C78963!important;
  border-bottom: 2px solid #C78963 !important;
  font-size: 16px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  border-top: white !important;
  border-left: white !important;
  border-right: white !important;
}
.coupon-headers .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {   
  color: #7F7F7F!important;
  font-size: 16px;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  border-top: white !important;
  border-left: white !important;
  border-right: white !important; 
}
.saloon-type-container label{
  font-size: 14px;
}
.saloon-type-container{
  width: 80%;
  margin: 0 auto !important;
}
.heading-btn-coupon{
  float: right;
}
.sortbyType{
  font-size: 14px !important;
  color: #7F7F7F !important;
  font-family: 'Work Sans', sans-serif !important;
  cursor: pointer;
}

/* Radio button css */


.bridal-check{
  float: left !important;
  margin: 0 !important;
  padding: 0 !important;
}
.success-logo{
  width: 320px;
  height: 320px;
}
.step-four-title h4{
  font-size: 28px;
  color: #212529;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
}
.step-four-title p{
  color: #6C757D;
  font-size: 16px;
  font-weight: 500 !important;
  font-family: 'Roboto', sans-serif !important;
}
.getLocation-icons{
  color: #C78963;
  display: flex;
  margin-top: 10px !important;
}
.getLocation-icons span {
 position: relative;
 cursor: pointer;
}
.getLocation-icons h6{
  margin-left:5px;
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  cursor: pointer;
  font-weight: 500 !important;
}
.get-location-container h5{
  font-size: 12px !important;
  color: #BFBFBF !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  margin-top: 20px;
}
.edit-icon-checkout{
  font-size: 16px;
  color: #C78963;
  float: right;
  cursor: pointer;
}
.cart-icon-system{
  font-size: 24px !important;
}
.system-cart-outer{
  color: red;
  font-size: 20px;
  position: relative;
  bottom: 50px;
  left: 8px;
}
.system-cart-inner{
  color: white;
  font-size: 12px;
  position: relative;
  bottom: 58px;
  right: 4px;
  font-family: 'Work Sans', sans-serif !important;
}
.navbar-outer{
  padding: 0px 50px !important;
  margin: 0 auto!important;
  height: 70px;
}

.order-save-btn-container{
  margin-top: 0% !important;
  width: 80%;
}
.noshow-container label{
  color: #333333;
  font-size: 14px;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.noshow-container select{
  font-size: 16px !important;
  color: #7F7F7F;
  height: 48px;
}
.noshow-container textarea{
  font-size: 16px;
  color: #7F7F7F !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.coupon-list-inner-card{
  height: 500px !important;
  border: none !important;
  overflow-y: auto;
}
.apply-coupon-headers{
  display: flex !important;
  text-align: center !important;
  color: #000000 !important;
  width: 100% !important;
}
.apply-coupon-headers span{
  position: relative;
  bottom: 2px;
  cursor: pointer;
}
.apply-coupon-headers p{
  color: #000000 !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 14px !important;
  margin-left: 30% !important;
  text-align: center !important;
}
.coupon-card-outer{
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 2px solid #BFBFBF !important;
  border-radius: 12px !important;
}
.coupon-card-outer h6{
  font-size: 20px !important;
  font-family: 'Space Grotesk', sans-serif !important; 
  font-weight: 600 !important;
  color: #212529 !important;
}
.coupon-card-outer h5{
  font-size: 10px !important;
  font-family: 'Space Grotesk', sans-serif !important; 
  font-weight: 600 !important;
  color: #d49168 !important;
}
.coupon-card-outer h1{
  font-size: 12px !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #4CB518 !important;
  font-weight: 400 !important;
}
.coupon-card-outer p{
  font-size: 12px !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #7F7F7F !important;
  font-weight: 400 !important;
}
.coupon-card-outer h2{
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #C78963 !important;
  font-weight: 500 !important;
  float: right;
  cursor: pointer;
  /* line-height: 0 !important; */
  margin: 0 !important;
  padding: 2px 0px !important;
}
/* .drop-down-items.dropdown-menu[data-bs-popper] {
  left: 240px !important;
  margin-top: 0 !important;
  padding: 0 !important;
  margin-bottom: 20px !important;
} */
#tab-view{
  display: none !important;
}
.no-salons-available{
  text-align: center;
  padding: 20px 20px 80px 20px;
}
.no-salons-available h1{
  font-size: 20px;
  color: #333333;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.no-salons-available p{
  font-size: 14px !important;
  color: #808080;
}
.no-salons-available h6{
  color: #C78963;
  margin-top: 50px !important;
  cursor: pointer;
}
.my-order-tabs{
  width: 80%;
  margin: 0 auto;
}
.order-filters{
  font-size: 14px !important;
  margin: 0px;
  background-color: #FCFCFC !important;
}
.calendar-outer-layer{
  width: 98% !important;
  margin: 20px auto !important;
}

.order-info-tabel{
  margin-top: 20px !important;
}
.order-info-tabel tbody tr p{
  margin-top: 0px !important;
  margin-bottom: 0 !important;
}
.order-info-tabel tbody tr h6{
  margin-top: 0px !important;
  margin-bottom: 0 !important;
}
.click-to-pay-active{
  font-size: 14px !important;
  color: #1890FF !important;
  font-weight: 600;
  font-family: 'Work Sans', sans-serif !important;
  cursor: pointer;
}
.click-to-pay-in-active{
  font-size: 14px !important;
  color: #BAE7FF !important;
  font-weight: 600;
  font-family: 'Work Sans', sans-serif !important;
}
.payment-completed-order{
  color: #4CB518 !important;
  font-size: 14px !important;
}
.menu-btn-dashboard{
  background-color:white !important;
  margin: 10px;
  color: #d49168 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  border: 1px solid #ECB390 !important;
  width: 95% !important;
}
.optionListContainer {
  background: white !important;
  font-size: 12px !important;
  border-radius: 4px;
  margin-top: 1px;
  position: absolute;
  width: 100%;
  z-index: 2;
  font-weight: 200 !important;
  color: black !important;
  font-family: 'Work Sans', sans-serif !important;
}
.optionListContainer li {
  font-weight: 400 !important;
}  

.highlightOption {
  background: white !important;
  color: black !important;

}
.chip {
  align-items: center;
  background: #ecb390 !important;
  border-radius: 4px !important;
  color: black !important;
  display: inline-flex;
  font-size: 10px !important;
  line-height: 19px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 0px 5px !important;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.multiSelectContainer input {
  background: transparent;
  border: none;
  margin-top: 3px;
  font-size: 14px !important;

}
.multiSelectContainer .optionListContainer .optionContainer input[type=checkbox] {
  border: 2px solid #C78963 !important;
  border-radius: 0 !important;
  background-color: red !important;

}
.update-commition-container .form-select{
  padding: 10px 20px;
}
.percentage-commission{
  padding: 0 30px;
}
.update-commition-container{
  margin: 20px;
}



/*After login mobile responsive*/

@media screen and (max-width: 767px) and (min-width: 320px) {
  .saloon-type-container{
    width: 100%;
  }
  .saloon-type-container label{
    font-size: 12px;
  }
  .commission-btn-group {
    padding: 20px 0px;
}
.percentage-commission{
  padding: 0;
}
  #fav-icon-hover {
    background-color: #fff!important;
    border-radius: 20px!important;
    bottom: 440px!important;
    color: red!important;
    display: none;
    left: 25%!important;
    margin: 0 0 15px 10px!important;
    width: 190px!important;
    z-index: 1!important;
}
  .generate-coupon-code-cancel{
    width: 100% !important;
    font-size: 12px !important;
                                            }
  .code-action-btn {
    width: 90% !important;
    margin: 30px auto !important;
   }
  .image-uploader-body{
    padding: 10px !important;
  }

  .navbar-inner{
    background: white !important;
  }
  .profile-dropdown-mobile.dropdown-menu[data-bs-popper] {
    left: 165px !important;
    margin-top: 0 !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
}
  .review-count-explore {
   margin: 0px 5px !important;
}
  .business-left-menu {
    border-right: none !important;
  }
  .continue-btn-login {
    font-size: 12px;
  }
  .contact-details .form-control, .contact-details .form-select {
    font-size: 12px;
  }
  .single-page-review-count {
    font-size: 8px !important;
    position: relative;
    top: 2px;
}
  .click-to-pay-in-active{
    font-size: 14px !important;
    color: #BAE7FF !important;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif !important;
  }
  .my-order-tabs {
    width: 100%;
    margin: 0 auto;
}
.exploremain-search-box {
  font-size: 10px !important;
}
  .order-info-tabel tbody tr p{
    margin-top: 0px !important;
    margin-bottom: 0 !important;
    font-size: 12px !important;
  }
  .nav-tabs .nav-link {
    font-size: 10px !important;
}
.exploremain-search-box {
  font-size: 10px !important;
}
.order-filters {
  font-size: 10px !important;
}
.payment-orderInfo-details h6 {
  font-size: 12px !important;
}
.myOrder-payment-info h6 {
  margin-top: 3px;
  font-size: 10px !important;
  margin-bottom: 0px !important;
}
  .request-container{
    width: 100%;
    margin: 0 auto !important;
  }
  .coupon-card-outer h2{
    float: right !important;
    cursor: pointer;
  }
  .coupon-modal-size.modal.show .modal-dialog {
    transform: none;
    width: 96%;
  }
  .comments-container-order{
    height: 200px;
  }
  .order-save-btn-container{
    margin-top: 20% !important;
    width: 90%;
  }
  .search-orderlist {
    width: 100% !important;
}
  .single-view-request h1 {
    font-size: 16px;
    color: #333333;
    font-family: 'Work Sans', sans-serif;
}
  .request-decision-btn {
    width: 100%;
    margin: 20px auto !important;
}
  .single-view-request thead {
    background-color: #F5F5F5;
    font-size: 10px;
    color: #7F7F7F;
}
  .single-view-request td {
    font-size: 10px;
    color: #333333;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400 !important;
    text-align: start !important;
}
.request-view-email {
  font-size: 10px;
  color: #333333;
  font-weight: 400 !important;
}
  .request-headers-reset{
    float: none !important;
  }

  .drop-down-items.dropdown-menu[data-bs-popper] {
    left: -103px;
    margin-top: 2px !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
}
  /* .navbar {
    height: 85px !important;
    padding: 0px 0px !important;
    zoom: 95%;
  } */
  .navfont-size h6{
    margin-top: 0px !important;
    color: grey;
  }
  
  .active-Navbar {
    color: #d49168 !important;
    border-bottom: 2px solid black;
}
  .navbar-outer{
    padding: 0 !important;
    margin: 0 !important;
  }
  #lblCartCount {
    font-size: 26px;
    color: red;
    position: relative !important;
    bottom: 25px !important;
    right: 0px !important;
    left: 10px !important;
}

  #lblCartCountNumber {
    font-size: 14px;
    color: white !important;
    position: relative !important;
    bottom: 35px !important;
    right: 0px !important;
    left: -5px !important;
    font-family: 'Work Sans', sans-serif !important;
  }
  #cart-icon-mobile {
    font-size: 22px !important;
    position: relative;
    left: 6px !important;
    top: 25px;
}
#cart-icon-empty-mobile {
  font-size: 22px !important;
  position: relative;
  left: 6px !important;
  top: 4px;
}

  .logo-header {
    width: 100% !important;
    height: 20px !important;
    /* margin-left: 0px; */
}
  .bg-white.navbar-collapse.collapse.show{
    height: 100vh !important;
    min-height: 100vh;
  }
  .suggestion-places{
    font-size: 12px;
  }
  .loading-location{
    text-align: center;
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .sugeestion-container{
    height: 278px;
  }
  .location-enter-icon {
    width: 0px !important;
    margin-top: 8px !important;
    margin-left: 0px !important;
    cursor: pointer !important;
}
  .locationAuto {
    width: 100% !important;
    border: none !important;
    margin-left: 0 !important;
    height: 38px !important;
}
.location-searchBar-outer {
  height: 40px !important;
  width: 100% !important;
}
.location-search-container {
  width: 100% !important;
}
  .payment-sub-cards h6 {
    font-size: 14px !important;
  }
  .payment-suceess-service-info h6 {
    font-size: 10px !important;
    margin-top: 5px;
  }
  .step-four-title h4{
    font-size: 18px;
  }
  .orderid-card {
    width: 100%;
  }
  .step-four-title p{
    font-size: 14px;
  }
  .success-logo{
    width: 100%;
  }
  .uploader-text h6{
    font-size: 12px !important;
    text-align: center;
  }
  .uploader-text p{
    font-size: 10px !important;
    text-align: center;
  }
  .step-one-title h4 {
    font-size: 12px !important;
  }
  .step-one-title p {
    font-size: 10px !important;
    line-height: 10px !important;
}
  .business-register-form .form-label {
    font-size: 10px !important;}
  .business-register-form{
    width: 100% !important;
  }
  .business-edit-form .form-label {
    font-size: 10px !important;}
  .business-edit-form{
    width: 100% !important;
  }
  .bridal-check{
    float: none !important;
    margin: 0 5px !important;
    padding: 0 5px !important;
  }
  .service-header-view p {
    font-size: 16px;
  }
  .business-left-menu ul li a {
    font-size: 12px !important;
}
  .fiters-section-inventory {
    margin-left: 5px;
    margin-top: 20px !important;
}
  .inventory-selection-list {
    width: 100% !important;
    margin-bottom: 10px;
}
  .search-inventory {
    width: 100% !important;
    float: none;
    margin-bottom: 10px;
}
  .inventory-btn-list {
    display: flex;
    float: none;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 10px !important;
  }
  .add-service-btn {
    width: 100px;
    font-size: 12px !important;
    margin: 0  !important;

  }
  .staff-update-service-btn{
    width: 100%;
    font-size: 12px !important;
  }
  .staff-cancel-service-btn{
    width: 100%;
    font-size: 12px !important;
  }
  .staff-delete-service-btn{
      width: 100%;
    font-size: 12px !important;
  }
  /* .navbar-toggler-icon {
    width: 0.5em !important;
    height: 0.5em !important;
  } */
  .location-in-navbar{
    display: none !important;
  }
 
  .payment-info-image {
    width: 100% !important;
    height: 70px !important;
    /* height: 150px !important; */
}
  .myOrder-more-info {
    font-size: 10px !important;
  }
  .myOrder-verification-otp {
    font-size: 12px !important;
  }
  .myOrder-id-info {
    font-size: 12px !important;
  }
  .myOrder-payment-info p {
    font-size: 12px !important;
    line-height: 16px !important;
}
 
  .review-content {
    width: 100% !important;
    margin: 10px 0px 0px 0px !important;
  }
 
  .searchbar-copon{
    width: 90% !important;
    margin: 10px auto !important;
  }
  .sidebar-content {
    width: 100% !important;
}
.code-modal-header h4 {
  font-size: 14px !important;
  text-align: center;
  margin-left: 0 !important;
}
.custom-code-container .form-select {
  font-size: 12px !important;
}
.generateCode-type-active p {
  margin-top: 10px !important;
  font-size: 12px !important;
}
.default-generateCode-type p {
  margin-top: 10px !important;
  font-size: 12px !important;
}
.generate-coupon-code {
  width: 100% !important;
  font-size: 12px !important;
}

  .admin-tabel-data td{
    font-size: 10px;
    word-wrap: break-word;
  }
  .admin-tabel-heading {
    font-size: 10px;
}
  #desktop-view{
    display: none;
  }
  .coupon-headers p{
    margin-top: 10px;
    float: none;
  }
  .coupon-headers.reset-filters-coupons{
    display: flex !important;
  }
  .generate-btn-admin {
    width: 150px;
    font-size: 12px !important;
}
  .delete-btn-admin {
    width: 100px;
    font-size: 12px !important;

}

  .heading-btn-coupon{
    align-items: center !important;
    padding: 0 !important;
  }
  
  .coupon-headers-inner{
    width: 100% !important;
   position: relative;
   left: 0%;
   top: 0px!important;
  }
  .order-id-info h6 {
    font-size: 12px;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}
.payment-info-inner {
  border-right: none !important;
}
  .cancel-form-outer{
    width: 100% !important;
  }
  .favorite-icon-favscreen{
    margin: 2px 2px 5px 2px !important;    
  }
  .profile-fav-card {
    width: 24px !important;
    height: 24px !important;
}
.apply-service{
  background-color: #d49168 !important;
  border-color: #C78963 !important;
  width: 100%;
  margin-top: 20px;
}
.line-search {
  margin-top: 0 !important;
  padding-top: 0 !important;
  margin-left: 0px !important;
}
.result-not-found-section{
  overflow-x: hidden;
  width: 100% !important;
  padding: 0;
  margin: 0;
}
  .marketing-body-content{
    width: 100% !important;
    margin: 0 auto !important;
  }
  .code-modal-body{
    width: 100% !important;
  }
  .custom-code-container{
  width: 100% !important;
}
  .serivice-item-total-end p {
    float: right!important;
    left: 0%!important;
    position: relative !important;
}
  .filter-service-dashboard{
    margin-top: 20px !important;
    width: 30% !important;
  }
  .fav-icon-unFill {
    position: relative;
    bottom: 420px;
    left: 85.7%;
    background-color: white !important;
    border-radius: 8px !important;
    padding: 5px !important;
}
.fav-icon-fill {
  position: relative;
  bottom: 420px;
  left: 85.7%;
  background-color: white !important;
  border-radius: 8px !important;
  padding: 5px !important;
}
  .title-view {
    margin-top: 100px;
  }
  .container-xxl {
    zoom: 100% !important; 
    max-width: 2560px !important;
  }
  .container{
    zoom: 100% !important;
  }
 
  .our-features-images img{
    margin-left: 0px !important;
  }
  .our-features-images p{
    margin-left: 0px !important;
    font-size: 8px !important;
  }
  .how-it-works-card{
    margin-bottom: 30px !important;
  }

  /* .navbar-view{
    margin: 0 !important;
    padding: 0 !important;
  } */
  .nav-search-hide{
    display: none !important;
  }
  .reset-eye-icon {
    position: relative;
    left: 84% !important;
    top: 29%;
    cursor: pointer;
}
.reset-eye-icon-conform {
  position: relative;
     left: 84% !important;
  top: 56%;
}
  .eye-icon {
    right: 30px;
    top: 8px;
}
  
  .register-form {
    padding: 5px 5px;
  }
  #mobile-view{
    display: contents !important;
  }
  #system-view{
    display: none !important;
  }
  .filter-service{
    font-size: 12px !important;
    margin-top: 0 !important;
  }
  .note-text{
    color:#F5222D !important;
    font-weight: 100;
    font-size: 16px;
    line-height: 20px !important;
  }
  .important-note-modal{
    padding: 10px !important;
  }
  .gallery-add-btn{
    float: none !important;
  }
  
  .gallery-search-filter{
    width: 100% !important;
    margin: 0 auto !important;
  }
  .add-select{
    width: 100% !important;
    margin-top: 20px !important ;
  }
  .filter-service{
    float: none;
    margin-left: 0px !important;
  }

  .image-delete-cancel{
    color: #602F10 !important;
    background-color: white !important;
    border-color: #602F10 !important;
    width: 100% !important;
    padding: 10px !important;
    margin-left: 0;
    font-weight: 0 !important;
  }
  
  
  .image-delete-delete{
    color: #602F10 !important;
    background-color: #ECB390 !important;
    border-color: #ECB390 !important;
    width: 100% !important;
    padding: 10px !important;
    margin-right: 0px;
    font-weight: 600 !important;
  }
  
  .about-one-inner {
    margin-top: 0px !important;
}
  .calendar-view{
    width: 100% !important;
    margin: 0 auto !important;
  }
  .gallery-image-user{
    width: 100% !important;
  }
  
  .refund-card{
    width: 100% !important;
  }
  .card-order-view{
    width: 100% !important;
  }

  .refund-tabel h6{
    margin-left: 180px !important;
  }
  .refund-tabel-total h6 {
    margin-left: 200px !important;
  }
  .customer-details-order{
   font-size: 12px !important;
  }
  .date-month{
    font-size: 16px !important;
  }
  .order-view-service-details h4{
    font-size: 16px !important;

  }
  .order-id-view{
    display: flex;
  }
  .order-id-view h5{
    font-size: 16px !important;
    word-break: break-all !important;
  }
  .order-id-view h4 {
    font-size: 16px !important;
    margin-top: 5px !important;
  }
  
  .order-view-right-ctnt h5{
    font-size: 16px !important;
  }
  .order-view-right-ctnt h6{
    font-size: 16px !important;
  }
  .order-otp-verfication h6{
    margin-left: 50px !important;
  }
  .total-amount-inorder h6{
    margin-left :150px !important; 
  }
  .about-one-img{
    width: 100% !important;
  }
  .about-two-img{
    width: 100% !important;
  }
  .about-three-img{
    width: 100% !important;
  }
  .rupee-icon{
    margin-top: 16px !important;
  }
  .home-card-value h2{
     font-size: 30px !important;
  }
  .icon-header{
    font-size: 12px !important;
    margin: 5px !important;
  }
  .home-card-value{
    margin: 0 !important;
  }
  .business-left-menu ul li a {
    font-size: 16px !important;
    font-family: 'Space Grotesk', sans-serif; 
  }
  .business-left-menu ul li img{
    width: 18px !important;
  }
  .common-box-border {
   margin: 0 !important;
  }
  .order-view-btn{
   float: left !important;
  }
  .order-verify-otp-btn{
    width: 100% !important;
  }
  .order-update-btn{
    width: 100% !important;

  }
  .order-view-status{
    float: left !important;
    width: 100% !important;
  }
  .inventory-reset .text-primary .cur-pointer{
    float: left !important;
    margin-top: 20px !important;
  }

  .active-Navbar{
    border-bottom: 2px solid black;
  }
  .admin-dashboard-card{
    width: 100% !important;
  }

  .navfont-size {
    width: 95% !important;
    margin: 25px 0px 0px 0px !important;    
  }

  .support-img-align{
    width: 350px;
    height: 243px;
    margin: 0px;
  }
  .support-mail-align{
    margin-top: 20px;
    margin-left: 0px;
    text-align: center;
  }
  .support-form{
    width: 100%;
  }
  
  .support-button{
    width: 90%;
    margin: 0px 0px 0px 20px;
    background-color: #ECB390 !important;
    color: #602F10 !important;
    border-color:#ECB390 !important ;
    border-radius: 8px !important;
    Padding : 9px, 17px, 9px, 17px;
  }
  .decline-btn {
    border: 2px solid #dc3545;
    font-size: 15px;
    width: 250px;
    padding: 8px;
    margin: 0 30px 22px 28px;
}
.accept-btn {
   
    width: 250px;
    background: #0d6efd;
    border: 2px solid #0d6efd;
    font-size: 15px;
    width: 250px;
    padding: 8px;
    margin: 0 30px 22px 28px;
}
  .staff-counts{
    font-size: 12px!important;
    font-family: 'Work Sans', sans-serif !important;    
  }
  .order-tabel-view tr td{
    font-size: 16px;
  }
  .common-busniess-box h6 {
    font-size: 12px !important;
    margin-bottom: 20px;
  }

  .order-customer-details h5 {
    font-size: 16px !important;
}
  .order-verify-btn{
    width: 100%;
  }
  .order-view-action-btns button {
   margin-left: 10px;
  }
  .img-fluid.view-cart-button{
    float: right;
    position:fixed;
    font-size: 12px;
    padding: 0px;
    width:50px;
    height:50px;
    bottom:50px;
    right:10px;
    background: #ecb390 !important;
    font-weight: 700;
    color: #602f10 !important;
    border-radius:50px;
    text-align:center;
    border-color: transparent;
  }
  .icon-cart{
    height:10px;
     width:10px;
  }

.support-logout{
  margin-top:0;
}
.slaylewks-table .table-head {
  font-size: 16px;
  color: #6c757d;
  font-weight: 500;
}
.business-left-menu{
  height:auto;
}
.main-content{
  flex:none;
  margin:0;
}
.float-end.date-calen{
  float:left!important;
  margin-top:15px;
}
.dashboard-home-card{
  margin-bottom:15px!important;
}
.dashboard-home-card:last-child{
  margin-bottom: 0;
}
.float-end.download-icon-btn,.float-end.upload-icon-btn,.float-end.add-service-btn{
  float:left!important;
}
.inventory-search{
  float:left;
  width:64%!important;
  margin-top: 20px;;
}
.filter-service,.asc-desc{
  float:left;
  margin:5px 5px 0 0;
  font-size: 8px !important;
  height: 24px !important;
}
.common-text-review {
  font-size: 10px !important;
  font-weight: 400;
  color: #6c757d;
  line-height: 4px;
  word-break: normal;
}

.service-operation-section.mt-4{
  margin-top: 15px!important;
}
.main-content h4{
  font-size: 20px;
}
.demo-app-sidebar{
  width:100% !important;
  margin-bottom: 20px;
}
.demo-app-main{
  margin-left:0 !important;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child){
  margin-left:0!important;
}

.date-right{
  text-align: left;
}
.business-search.w-50{
  width:100%!important;
}
.freelance-business-btn{
  width:47%;
  margin:0 8px 0 0;
}
.freelance-business-btn:last-child{
  margin-right:0;
}
.free-business-search.w-50{
  width:100%!important;
  margin-top: 20px;
}
.explore-single-tab {
  font-size: 12px !important;
}
}


@media only screen and(min-width:767px){
  
  .title-view {
    margin-top: 100px;
  }
  .fav-icon-fill {
    position: absolute;
    left: 91%;
    top: 3%;
    color: red !important;
    background-color: white !important;
    border-radius: 8px !important;
    padding: 5px !important;
}
/* .fav-icon-unFill {
  position: absolute;
  left: 78%;
  top: 4%;
  background-color: white !important;
  border-radius: 8px !important;
  padding: 5px !important;
} */

  #eye {
    position: absolute;
    right: 30px;
    transform: translate(0, -50%);
    top: 18px;
    cursor: pointer;
  }
  .Show-Cart{
    margin-left: 60px;
    font-weight: 900;
    font-size: 12px !important;
  }
  #conform-eye {
    position: absolute;
    right: 10%;
    transform: translate(0, -50%);
    top: 62%;
    cursor: pointer;
  }
  .footer-common h3 {
    font-weight: 500;
    font-size: 22ox;
    color: #212529;
    margin-bottom: 15px;
  }
}

.slaylews-header-nav {
  z-index: 100 !important;
}
@media screen and (min-width: 425px) {
  .profile-dropdown-mobile.dropdown-menu[data-bs-popper] {
    left: 165px !important;
    margin-top: 0 !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
}
 
  .refund-tabel h6{
    margin-left: 220px !important;
  }
  .refund-tabel-total h6 {
    margin-left: 250px !important;
  }
}
@media screen and (max-width: 600px) {
  .single-view-request td {
    font-size: 10px;
    color: #333333;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400 !important;
    text-align: start !important;
}
 
  .icon-logo {
    display: none;
  }
  .mainServices {
    margin-top: 15%;
  }
  .trendingServices {
    display: none;
  }
  .customer-reviews {
    display: none;
  }
  .footer-content {
    background-color: #ffebdf;
  }
  .footer-body-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cart-checkout-continue-update {
    width: 100px !important;   
    margin: 20px !important;
  }
  .footer-bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .social-media-inner {
    margin-top: 15px;
    width: 100%;
  }
}
/* New Styles on 09-06 */
.logo {
  width: 250px;
  height: 20%;
}
.home-main-container {
  margin: 0 auto !important;
  width: 50% !important;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #mobile-view{
    display: none !important;
  }
  #system-view{
    display: contents !important;
  }
  .business-left-menu {
    height: 50vh !important;
}
  .social-media-inner {
    float: left;
    width: 100%;
    margin-left: 14px;
}
.order-summary{
  width: 80%;
  margin: 0 auto !important;
}
.serivice-item-amounts p {
    left: 15% !important;
}
.serivice-item-discount p {
  left: 15% !important;
}
.serivice-item-coupon p {
  left: 15% !important;
}
  .cart-summary-page .container .row .col-md-7 {
    width: 100% !important;
  }
  .cart-summary-page .container .row .col-md-5 {
    width: 100% !important;
    margin: 0 auto !important;
}
  .order-image{
    width: 50% !important;
    float: left !important;
  }
  .service-item-list{
    width: 50% !important;
    float: left !important;
  }
  .logo {
    width: 200px;
    height: 20%;
  }
  .col-md-9{
    width: 100% !important;
  }
  .col-md-3{
    width: 100% !important;
  }
  .explore-search-icon {
    position: relative;
    left: 5% !important;
    top: 5px;
}
/* .fav-icon-unFill {
  position: relative;
  left: 84.7%;
  top: 2%;
  background-color: white !important;
  border-radius: 8px !important;
  padding: 5px !important;
} */
.fav-icon-fill {
  position: relative;
  left: 84.7%;
  bottom: 233px !important;
  color: red !important;
  background-color: white !important;
  border-radius: 8px !important;
  padding: 5px !important;
}
.explore-list img {
  width: 30% !important;
  margin: 0 auto !important;
}
.col-md-8 {
  flex: 0 0 auto;
  width: 100% !important;
}
.card-list {
  width: 230px !important;
  margin-left: 10px !important;
}

.reset-eye-icon {
  position: relative;
  left: 88% !important;
}
.reset-eye-icon-conform {
  position: relative;
  left: 88% !important;
}
}


@media only screen and (max-width: 390px) {
  .profile-dropdown-mobile.dropdown-menu[data-bs-popper] {
    left: 165px !important;
    margin-top: 0 !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
}
  .single-view-request td {
    font-size: 10px;
    color: #333333;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400 !important;
    text-align: start !important;
}
  .natural-list ul {
    float: left;
    width: 100%;
    padding-left: 15px;
    display: flex;
}
  .load-btn {
    border: 1px solid #ced4da;
    color: #ced4da;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px !important;
    width: 200px;
    background: #fff;
    padding: 5px !important;
    margin-bottom: 25px;
  
}
.service-tabs div a {
  font-size: 20px !important;
  color: #000;
  font-weight: 500;
 
}
.inventory-reset{
  margin-top: 25px !important;
}
.order-search-bar {
  width: 66% !important;
}
.request-search-bar {
  width: 100% !important;
  margin-bottom: 10px;
}

.order-cal-date{
  font-size: 10px !important;
}

.gallery-image-user {
  width: 100%;
}
}
@media only screen and (max-width: 425px){
  /* .order-calendar{ 
    float: left !important;
    margin-top: 20px !important;
  }   */
}
@media only screen and (max-width: 320px) {
  .navbar-outer{
    padding: 0 !important;
    margin: 0 !important;
  }
  .discount-booking-text span{
    margin-top: 0px !important;
  }
  .discount-booking-text h2 {
    font-size: 28px;
    margin: 60px 0 0 0 !important;
  }
  .natural-list ul {
    float: left;
    width: 100%!important;
    display: flex;
    margin-top: 3px;
}

  .business-timelimit-section .common-text {
    margin-top: 4px;
    margin-left: 10px;
  }
  .service-tabs div a {
    font-size: 20px !important;
    color: #000;
    font-weight: 500;
    text-align: left !important;
  }

  .verification-status-card{
    margin: 0 !important;
    width: 100%;
  }
  .progress-step .step-label {
     margin-left: 20px;
    font-size: 10px;
  }
  .decline-btn{
    margin: 0 30px 22px 0px !important;
  }
  .float-end {
    float: none !important;
  }
  .rdrMonthAndYearWrapper {
    align-items: center;
    height: 48px;
    padding-top: -3px;
    width: 250px;
  }
  
  .rdrMonth {
    width: 20.667em;
}
  .rdrDateDisplayWrapper {
    background-color: rgb(239, 242, 247);
    width: 250px;
  }
  .rdrCalendarWrapper {
    color: #000000;
    font-size: 12px;
    width: 250px;
  }
  .rdrDays {
    display: flex;
    flex-wrap: wrap;
    
  }
}
@media only screen and (min-width:480px)  {
  .locationAuto {
    width: 100% !important;
  }
}
@media only screen and (min-width: 390px) and (max-width: 425px){
  .navbar-outer{
    padding: 0 !important;
    margin: 0 !important;
  }
  .navbar-outer{
    padding: 0 !important;
    margin: 0 !important;
  }
  .icon-header {
    font-size: 18px !important;
    margin-top: 5px;
  }
  .rounded-pill {
    border-radius: 8px !important;
  }
  .single-page-review-count {
    font-size: 14px !important;
  }
  .ordersummary-inner h3 {
    font-size: 18px !important;
  }
  .ordersummary-inner span {
    font-size: 18px !important;
  }
  .servicelist-inner ul li p {
    font-size: 18px !important;
    margin-left: 15px !important;
  }
  .servicelist-inner ul li span {
    font-size: 18px !important;
  }
  .total-amount-inner p {
    font-size: 20px !important;
  }
  .total-amount-inner span {
    font-size: 20px !important;
  }
  .cart-service-list ul li p.service-heading {
    font-size: 16px !important;
    margin-left: 15px !important;
  }
  .cart-service-list ul li {
    float: left;
    width: 97% !important;
    border-bottom: 1px solid #e9ecef;
    padding: 15px 0;
    position: relative;
  }
  .time-order {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #343a40 !important;
    position: absolute;
    top: 70px;
    right: 30px !important;
    width: auto !important;
  }
  .cart-service-list ul li {
    padding: 0px 0px 20px 0px;
  }
  .about-one-inner {
    margin-top: 0px !important;
}

  .rdrMonthAndYearWrapper {
    align-items: center;
    height: 48px;
    padding-top: -3px;
    width: 100%px;
  }
  
  .rdrMonth {
    padding: 0;
    width: 100%;
  }
  .rdrDateDisplayWrapper {
    background-color: rgb(239, 242, 247);
    width: 100%;
  }
  .rdrCalendarWrapper {
    color: #000000;
    font-size: 12px;
    width: 100%;
  }
  .rdrDays {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px;
  }

}

/* Resonsive for Tablets */

@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1) {
  .profile-dropdown-mobile.dropdown-menu[data-bs-popper] {
    left: -100px !important;
    margin-top: 20px !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
}
  .coupon-card-outer h2{
    float: right !important;
    cursor: pointer;
  }
 
  #mobile-view{
    display: none !important;
  }
  #system-view{
    display: contents !important;
  }
  .locationAuto {
    width: 100% !important;
    border: none !important;
    margin-left: 0 !important;
    height: 38px !important;
}
  .inventory-selection-list {
    width: 361px !important;
    font-size: 16px !important;
    color: #7F7F7F !important;
    font-family: 'Work Sans', sans-serif !important;
    margin-bottom: 20px;
}
.search-inventory {
  width: 90% !important;
  float: left;
  margin-bottom: 0px;
}
.fiters-section-inventory {
  width: 50% !important;
  display: flex !important;
  position: relative;
  left: 48%;
  bottom: 35px;
}
.business-left-menu ul li a {
  font-size: 12px !important;
}
.how-works-outer{
  height: auto !important;
}
}


 /* IpadMini Responsive */

 @media only screen 
 and (min-device-width: 768px) 
 and (max-device-width: 1024px) 
 and (-webkit-min-device-pixel-ratio: 1) {
  .drop-down-items.dropdown-menu[data-bs-popper] {
    left: -103px;
    margin-top: 2px !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
}
  .searchbar-copon {
    height: 40px !important;
    width: 100% !important;
}
.coupon-headers-inner {
  width: 100% !important;
  position: none !important;
  left: 0% !important;
  top: 35px!important;
  display: flex !important;
  margin-bottom: 50px;
}
.coupon-headers-reset {
  margin-top: 10px;
  float: none !important;
}
  .coupon-card-outer h2{
    float: right !important;
    cursor: pointer;
  }
  
  #mobile-view{
    display: none !important;
  }
  #system-view{
    display: contents !important;
  }

  .navfont-size {
    width: 100% !important;
    margin: 0px 0px !important;    
  }

  #system-view{
    display: none !important;
  }

  #mobile-view{
    display: none !important;
  }

  #cart-icon-mobile {
    font-size: 21px !important;
    position: relative;
    left: 100px;
    top: 24px;
}
#cart-icon-empty-mobile {
  font-size: 21px !important;
  position: relative;
  left: 100px;
  top: 4px;
}
#lblCartCount {
  font-size: 26px;
  color: red;
  position: relative !important;
  bottom: 30px !important;
  right: 0px !important;
  left: 100px !important;
}
#lblCartCountNumber {
  font-size: 16px;
  color: white !important;
  position: relative !important;
  bottom: 40px !important;
  font-family: 'Work Sans', sans-serif !important;
  left: 83px !important;
}
.active-Navbar{
  color: #d49168 !important;
  /* border-bottom: 2px solid black; */
}



  #mobile-view{
    display: none !important;
  }
#system-view{
  display: contents !important;
}
.cart-icon-system {
  font-size: 24px !important;
  position: relative;
  top: 11px;
}
.system-cart-outer {
  color: red;
  font-size: 20px;
  position: relative;
  bottom: 42px;
  left: 9px;
}
.system-cart-inner {
  color: white;
  font-size: 12px;
  position: relative;
  bottom: 50px;
  font-family: 'Work Sans', sans-serif !important;
}
.profile-dropdown.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 83% !important;
    margin-top: 0 !important;
}
.navfont-size {
  width: 100% !important;
  margin: 0px 0px 0px 30px !important;    
}
.navfont-size .nav-link{
  margin-top: 0 !important;
}
   .how-works-outer{
     height: auto !important;
   }
  }
  @media screen and ( device-width: 375px ) {
   
    .single-view-request thead {
      background-color: #F5F5F5;
      font-size: 10px;
      color: #7F7F7F;
  }
    .single-view-request td {
      font-size: 10px;
      color: #333333;
      font-family: 'Work Sans', sans-serif;
      font-weight: 400 !important;
      text-align: start;
  }
    /* #cart-icon-mobile {
      font-size: 22px !important;
      position: relative;
      left: 42px;
    } */
    .drop-down-items.dropdown-menu[data-bs-popper] {
      left: -108px;
      margin-top: 10px !important;
      padding: 0 !important;
      margin-bottom: 20px !important;
  }
.how-it-works-card p {
  font-size: 12px!important;
  color: #7F7F7F !important;
  font-weight: 400!important;
  font-family: 'Work Sans', sans-serif;
}
.how-it-works-card h5 {
  font-size: 14px!important;
  font-family: 'Work Sans', sans-serif;
}
.profile-dropdown-mobile.dropdown-menu[data-bs-popper] {
  left: 210px !important;
  margin-top: 0 !important;
  padding: 0 !important;
  margin-bottom: 20px !important;
}
.navbar-outer{
  padding: 0 !important;
  margin: 0 !important;
}


}
@media only screen and (min-width: 390px) and (max-width: 425px){
  .single-view-request thead {
    background-color: #F5F5F5;
    font-size: 10px;
    color: #7F7F7F;
}
  .single-view-request td {
    font-size: 10px;
    color: #333333;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400 !important;
    text-align: start !important;
}
  
  /* #cart-icon-mobile {
    font-size: 22px !important;
    position: relative;
    left: 53px;
} */
.navbar-outer{
  padding: 0 !important;
  margin: 0 !important;
}
}
  

@media screen and ( device-width: 425px ) {
  .single-view-request thead {
    background-color: #F5F5F5;
    font-size: 10px;
    color: #7F7F7F;
}
  .single-view-request td {
    font-size: 10px;
    color: #333333;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400 !important;
    text-align: start !important;
}
  .profile-dropdown-mobile.dropdown-menu[data-bs-popper] {
    left: 260px !important;
    margin-top: 0 !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
}
    /* #cart-icon-mobile {
      font-size: 22px !important;
      position: relative;
      left: 53px;
    } */
  .drop-down-items.dropdown-menu[data-bs-popper] {
    left: -103px !important;
    margin-top: 2px !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
}
.how-it-works-card p {
  font-size: 12px!important;
  color: #7F7F7F !important;
  font-weight: 400!important;
  font-family: 'Work Sans', sans-serif;
}
.how-it-works-card h5 {
  font-size: 14px!important;
  font-family: 'Work Sans', sans-serif;
}
.navbar-outer{
  padding: 0 !important;
  margin: 0 !important;
}


  }


  

  @media screen and ( device-width: 540px ){
    .single-view-request thead {
      background-color: #F5F5F5;
      font-size: 10px;
      color: #7F7F7F;
  }
    .single-view-request td {
      font-size: 10px;
      color: #333333;
      font-family: 'Work Sans', sans-serif;
      font-weight: 400 !important;
      text-align:  start !important;
  }
  /* #cart-icon-mobile {
    font-size: 22px !important;
    position: relative;
    left: 73px;
} */
.navbar-outer{
  padding: 0 !important;
  margin: 0 !important;
}
}


 

  @media screen and ( device-width: 1024px ) {
    #mobile-view{
      display: none !important;
    }
    #system-view{
      display: contents !important;
    }
    .navfont-size {
      width: 100% !important;
      margin: 0px 0px !important;    
    }
    .cart-icon-system {
      font-size: 24px !important;
      margin-top: 4px;
  }
  #basic-navbar{
    margin-left: 25% !important;
  }
  .system-cart-outer {
    color: red;
    font-size: 20px;
    position: relative;
    bottom: 0px;
    left: -15px;
}
.system-cart-inner {
  color: white;
  font-size: 12px;
  position: relative;
  bottom: 8px;
  right: 28px;
  font-family: 'Work Sans', sans-serif !important;
}
.navbar-outer{
  margin: 0 auto !important;
}
.fav-icon-fill {
  position: relative;
  left: 83.7%;
  bottom: 380px !important;
  color: red !important;
  background-color: white !important;
  border-radius: 8px !important;
  padding: 5px !important;
}
.fav-icon-unFill {
  position: relative;
  left: 83.7%;
  bottom: 380px !important;
  background-color: white !important;
  border-radius: 8px !important;
  padding: 5px !important;
  cursor: pointer !important;
}


  }

  @media screen and (min-device-width: 320px) and (max-device-width: 912px) { 
    .rdrCalendarWrapper {
      color: #000000;
      font-size: 8px !important;
  }
    .profile-dropdown-mobile.dropdown-menu[data-bs-popper] {
    left: -100px !important;
    margin-top: 20px !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
}

    #normal-nav{
      display: none !important;
    }
    #advance-nav{
      display: block !important;
    }
    .active-Navbar{
      border-bottom: 2px solid black;
      color: #c67605 !important;
    }
    .active-Navbar h6{
      /* border-bottom: 2px solid black; */
      color: #c67605 !important;
    }
   .navfont-size h6{
    color: gray;
   }
  }
