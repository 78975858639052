.my-order-otp-btn{
  background-color: #ECB390 !important;
  border-color: #ECB390 !important;
  width: 30% !important;
  margin-left: 45px !important;
  border-radius: 8px !important;
}
.order-title {
  font-size: 40px;
  color: #212529;
  font-weight: 500;
}

.select-place {
  float: right;
  width: auto;
  font-weight: 500;
  font-size: 20px;
  color: #6c757d;
  border-radius: 8px;
}
.order-placed {
  font-size: 24px;
  font-weight: 500;
  color: #6c757d;
  float: right;
  width: 100%;
  text-align: right;
  margin: 20px 0 40px 0;
}
.myorder-status-list {
  float: left;
  width: 100%;
}
.myorder-status-list ul li {
  float: left;
  width: 100%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: 40px;
}
.order-id-overflow{
  word-break: break-word;
}
.order-image-size{
  width: 200px !important;
  border-radius: 8px;
}
.myorder-top {
  float: left;
  width: 100%;
  background: #f8f9fa;
  border-bottom: 1px solid #d1d1d1;
  border-radius: 8px 8px 0 0;
  padding: 0px 20px 0px 20px;
}
.myorder-top ul li {
  width: auto;
  box-shadow: none !important;
  margin: 20px 30px 10px 0;
}
.myorder-top ul li p {
  font-size: 16px;
  color: #6c757d;
  font-weight: 500;
}
.myorder-top ul li span {
  font-size: 16px !important;
  color: #6c757d;
  font-weight: 400;
}
.common-color {
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #0d6efd !important;
}
.order-number {
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #6c757d !important;
  word-break: break-word;
}
.pending-status {
  background: #ffc107;
  color: #212529;
  font-weight: 400;
  font-size: 16px;
  border-radius: 100px;
  padding: 5px 10px;
}
.otp-btn {
  font-size: 20px;
  font-weight: 500;
  color: #602f10 ;
  background: #ecb390 !important;
  border-radius: 8px;
  padding: 0px 13px;
  border: none;
  width: 25%;
  margin-right: 140px !important;
 
}
.myorder-bottom {
  float: left;
  width: 100%;
  position: relative;
  padding: 20px;
}
.myorder-bottom ul li {
  width: auto;
  box-shadow: none !important;
  margin: 0 45px 0 0;
}
.myorder-bottom ul li:last-child {
  margin: 0 0 0 150px;
}
.myorder-bottom ul li p {
  font-size: 20px;
  font-weight: 500;
  color: #6c757d;
}
.myorder-bottom ul li span {
  font-size: 20px;
  font-weight: 500;
  color: #212529;
  margin-bottom: 17px;
  width: 100%;
  float: left;
}
.otp-inner {
  float: left;
  width: 100%;
  text-align: center;
}
.myorder-bottom ul li p.reorder {
  font-weight: 300;
  color: #0d6efd !important;
}
.cancel-btn {
  float: right;
  border: 1px solid #602f10;
  border-radius: 8px;
  color: #602f10;
  font-weight: 500;
  font-size: 16px;
  padding: 5px 15px;
  background: #fff;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.completed-status {
  background: #198754;
  color: #fff;
}
.canceled-status {
  background: #dc3545;
  color: #fff;
}
.loadmore-btn {
  float: left;
  width: 100%;
  text-align: center;
}
.load-btn {
  border: 1px solid #ced4da;
  color: #ced4da;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  width: 160px;
  background: #F5F5F5 !important;
  padding: 5px;
  margin-bottom: 30px;
}
.otp-content {
  border-radius: 8px;
  border: 1px solid #ced4da;
}
.otp-outer {
  border-bottom: none;
}
.otpcode-popup {
  float: left;
  width: 100%;
  text-align: center;
  padding: 20px;
}
.otpcode-popup h3 {
  font-weight: 500;
  font-size: 28px;
  color: #212529;
}
.otpcode-popup p {
  font-weight: 300;
  font-size: 16px;
  color: #6c757d;
}
.otpcode-popup span {
  float: left;
  width: 100%;
  font-weight: 400;
  font-size: 20px;
  color: #6c757d;
  margin: 20px 0;
}
.otpcode-popup span.otp-code {
  font-weight: 500;
  font-size: 40px;
  color: #6c757d;
  margin: 0;
}
/*Mobile Responsive Start*/
@media screen and (max-width: 767px) and (min-width: 320px) {
  .my-order-otp-btn{
    margin-left: 0 !important;
    margin-bottom: 10px !important;
  }
  .order-number{
    margin: 0 auto !important;
    font-size: 13px !important;
    word-break: break-word;
  }
 .order-title {
  font-size: 24px;
}
.order-placed{
  font-size: 20px;
  margin:5px 0 20px 0;
  text-align: left;
}
.order-image-size{
  width: 300px!important;
  
}
.myorder-top ul li p{
  font-size: 18px;
}
.myorder-top ul li span{
  font-size: 16px;
}
.myorder-bottom h5{
  font-size: 18px;
}
}