
.policy-header {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  color: #000;
  margin-bottom: 40px;
}
h2 {
  font-weight: 500 !important;
}
.terms-paragraph {
  font-size: 18px;
  color: #000;
  font-weight: 400;
  margin-bottom: 20px;
}
section {
  padding: 20px 0 20px 0;
}
.cookies-use {
  float: left;
  border: 2px solid #000;
  margin-bottom: 40px;
}
.cookies-use ul {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
}
.cookies-use ul li {
  float: left;
  width: 100%;
  list-style: none;
  border-bottom: 2px solid #000;
  margin: 0;
}
.cookies-use ul li:last-child {
  border-bottom: none;
}
.cookies-use ul li span {
  float: left;
  width: 30%;
  font-weight: 400;
  font-size: 20px;
  color: #000;
  padding: 30px;
}
.cookies-use ul li p {
  float: left;
  width: 70%;
  font-weight: 400;
  font-size: 20px;
  color: #000;
  border-left: 2px solid #000;
  margin: 0;
  padding: 30px;
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .policy-header {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    color: #000;
    margin-bottom: 40px;
  }
  .terms-paragraph{
    font-size: 16px;
  }
  .cookies-use ul li span{
    font-size: 14px;
    padding: 30px 10px 24px 10px;
    word-break: break-word;
  }
  .cookies-use ul li p {
    font-size: 14px;
    padding: 30px 10px 24px 10px;
    word-break: break-word;
  }


}