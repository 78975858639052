@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Space+Grotesk:wght@300;400;500;600;700&family=Work+Sans:wght@300;400;500;600;700;800&display=swap');

body p{
  font-family: 'Work Sans', sans-serif;
}
body h2{
  font-family: 'Space Grotesk', sans-serif; 
}
body h3{
  font-family: 'Space Grotesk', sans-serif; 
}
body h4{
  font-family: 'Space Grotesk', sans-serif; 
}
body td{
  font-family: 'Work Sans', sans-serif;
  font-weight: 400!important;
}
body th{
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 500!important;
}
body li{
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 900 !important;
}
.container-xxl {
  zoom: 80% !important; 
  max-width: 2560px !important;
}
body span {
  font-family: 'Space Grotesk', sans-serif !important;
  font-weight: 900 !important;
}
.howitworks-card p{
  color: #7F7F7F !important;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 16px !important;
}
.howitworks-card h5{
  color: #333333 !important;
  font-size: 20px !important;
  font-family: 'Work Sans', sans-serif !important;
}
.home-search-outer{
  background-color: white !important;
  width: 668px !important;
  margin: 0 auto !important;
  height: 90px !important;
  border-radius: 24px !important;
  display: flex !important;
}

.home-search-input-box{
  /* width: 550px !important; */
  height: 60px !important;
  border-radius: 8px !important;
  color: #6c757d !important;
  font-weight: 300 !important;
  font-size: 20px !important; 
  margin: 0 auto !important;
  border-color: white !important;
}
.vertical-line {
  width: 1px;
  height: 70%;
  background-color: #000;
  margin: 0 10px;
}
.home-search-icon{
  width: 30px !important;
  margin-top: 20px !important;
  margin-left: 20px !important;
}
.home-enter-icon{
  width: 30px !important;
  margin-top: 26px !important;
  margin-left: 0px !important;
  cursor: pointer !important;
}
.home-search-container{
  width: 85% !important;
  /* margin-top: 15px !important; */
  display: flex;
  align-items: center;
}
.location-flex-box{
  flex-grow: 1;
  flex-basis: 30%;
  width: 30% !important;
}
.service-search-flex-box{
  flex-grow: 1;
  flex-basis: 70%;
  width: 70% !important;

}
.business-work-list .card{
  border: none !important;
}
.business-right{
 width: 50%;
 float: right;
 cursor: pointer;
}
.freelancer-left{
  width: 50%;
  cursor: pointer;
}

.business-right span{
  margin-top: 50px !important;
}
.user-img {
  position: relative;
}
.business-home-text {
  float: left;
  width: 100%;
  text-align: center;
}
.business-home-text p {
  font-weight: 300;
  font-size: 24px;
  color: #000;
  margin: 0;
}
.business-home-text p.banner-text {
  font-family: 'DM Serif Display', serif;
  font-size: 60px;
  font-weight: 400;
  color: #000;
  margin: 0;
}
.business-home-text span.beauty-text {
  float: left;
  width: 100%;
  font-size: 60px;
  font-weight: 400;
  color: #c78963;
  font-family: 'DM Serif Display', serif !important;
}
.business-home-text ul {
  width: 90%;
  margin: 0 auto;
}
.business-home-text ul li {
  float: left;
  width: 40%;
  margin: 50px 150px 0 0;
}
.business-home-text ul li:last-child {
  margin-right: 0;
}
.business-home-text ul li .business-home-freelancer {
  float: left;
  width: 100%;
  text-align: center;
}
.business-home-text ul li .business-home-freelancer img {
  border-radius: 100px;
  width: 100%;
  cursor: pointer;
  margin: 0 !important;
}
.business-home-text ul li .business-home-freelancer span {
  float: left;
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
p.create-account {
  float: left;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  color: #6c757d;
  margin-top: 30px;
}
.business-home-banner {
  background: #fff;
  float: left;
  width: 100%;
  margin-bottom: 50px;
}
.user-common-title.business-client {
  font-size: 28px;
  font-weight: 500;
  color: #6c757d;
}
.user-common-title {
  float: left;
  width: auto;
  font-size: 48px;
  font-weight: 400;
  color: #000;
  font-family: 'DM Serif Display', serif;
}
.user-common-title span {
  font-weight: 400;
  font-size: 48px;
  color: #ecb390;
}
.view-all {
  float: right;
  font-size: 20px;
  font-weight: 500;
  color: #0d6efd;
 
}
.explore-service-list {
  float: left;
  width: 100%;
  margin: 30px 0;
}
.explore-service-list ul li {
  float: left;
  width: 15%;
  margin-right:18px;
}
.explore-service-list ul{
  float:left;
  width:100%;
}
.explore-service-list ul li:last-child {
  margin-right: 0;
}
.explore-list {
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
.explore-list-our-features{
  width: 100% !important;
}
.explore-list-our-features img{
  width: 70% !important;
}
.explore-list img{
  width: 70% !important;
  margin: 0 auto !important;
}
.explore-list span {
  font-weight: 400 !important;
  font-size: 20px !important;
  color: #000;
  float: left;
  width: 100%;
  margin-top: 20px;
  font-family: 'Work Sans', sans-serif !important;
}
.explore-list-our-features span {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #7F7F7F !important;
  float: left;
  width: 100%;
  margin-top: 20px;
  font-family: 'Work Sans', sans-serif !important;
  text-align: center !important;
}
.business-partner-section {
  width: 100%;
  background: #faf4f0;
  padding: 0px;
}
.business-partner-inner {
  float: left;
  width: 100%;
  text-align: center;
  padding: 50px;
}
.business-partner-inner p {
  font-weight: 500;
  font-size: 36px;
  color: #000;
  font-family: 'Space Grotesk', sans-serif !important; 
}
.business-partner-inner span {
  font-weight: 300 !important;
  font-size: 16px;
  color: #6c757d;
  font-family: 'Work Sans', sans-serif !important;
}
.our-features-header{
  height: 100px !important;
}
.our-features-header h3{
  margin-top: 50px!important;
  margin-bottom: 23px !important;
}
.our-features-outer {
  float: left;
  width: 100%;
  background: #F8F9FA!important;
  padding-top:50px;
  /* margin-top:0!important; */
  padding-bottom: 0;;
  margin: 0 auto !important;
}
.client-main {
  float: right;
  margin-top: 15px;
}
.client-main ul li {
  float: left;
  width: auto;
  margin: 0 0 0 20px;
}
.client-main ul li a {
  font-weight: 500;
  font-size: 20px;
  color: #6c757d;
}
.client-main ul li a:hover {
  color: #0d6efd;
  border-bottom: 1px solid #0d6efd;
}
.our-features-list {
  float: left;
  width: 75%;
  margin-top: 50px;
  margin-left: 14% !important;
}
.our-features-list ul li {
  float: left;
  width: 33%;
  margin-bottom: 60px;
}
.our-features-list ul li .explore-list span {
  font-size: 18px;
}
.how-works-outer {
  float: left;
  width: 100%;
  margin: 0px 0px 40px 0px;
  height: auto !important;
  padding: 0 !important;
  background-color: white !important;
}
.how-works-list.business-work-list {
  float: left;
  width: 100%;
  margin: 40px 0 80px 0;
}
.how-works-list.business-work-list ul li {
  float: left;
  width: 31%;
  margin-right: 25px;
}
.how-works-list.business-work-list ul li:last-child {
  margin-right: 25px;
}
.how-works-list.business-work-list ul li .card {
  border: none;
}
.how-works-list.business-work-list ul li .card-body {
  padding: 0;
}
.how-works-list.business-work-list ul li .card-body h5{
  font-family: 'Space Grotesk', sans-serif !important; 
  font-size: 20px !important;
}
.how-works-list.business-work-list ul li .card-body p{
  font-size: 18px !important;
}
.how-works-list.business-work-list ul li .card-title {
  font-size: 24px;
  font-weight: 500;
  color: #212529;
  margin: 20px 0 15px 0;
}
.how-works-list.business-work-list ul li .card-text {
  font-size: 20px;
  font-weight: 300;
  color: #6c757d;
}
.works-img {
  width: 40px;
  height: 40px !important;
}

.customer-review {
  float: left;
  width: 100%;
  margin-bottom: 50px !important;
}
.customer-review ul li {
  float: left;
  width: 48%;
  margin-right: 25px;
}
.customer-review ul li:last-child {
  margin-right: 0;
}
.customer-review ul li .card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
  border-radius: 12px;
  border: none;
  padding: 10px 30px;
  margin: 36px 5px 0px 20px;
}
.customer-review ul li .card-text {
  font-size: 18px;
  font-weight: 400;
  color: #6c757d;
}
.customer-review ul li .card-body {
  padding: 0;
}
.customer-review ul li span {
  float: left;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: #212529;
}
.customer-review ul li span.location-date {
  float: left;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #6c757d;
  margin-top: 5px;
}
.customer-review-img {
  float: left;
  width: 100%;
  text-align: center;
}
.customer-review .us-icon {
  width: 65px;
  margin-bottom: 10px;
}
.customer-review-slide {
  float: left;
  width: 100%;
  margin: 50px 0;
}
.customer-review-slide .carousel-indicators [data-bs-target] {
  width: 18px;
  height: auto;
  background: #ecb390;
  border-radius: 100%;
}
.customer-review-slide .carousel-indicators {
  bottom: 0px;
}
.customer-review-slide .carousel-control-next,
.customer-review-slide .carousel-control-prev {
  display: none !important;
}
/*Mobile Responsive Start*/

@media screen and (max-width: 767px) and (min-width: 320px) {
  .our-features-images img {
    width: 100%!important;
    /* height: 200px!important; */
    height: auto !important;
}
  .mobile-img {
     width: 100%;
}
  .how-works-outer {
    height: auto !important;
    margin-top: 0 !important;
  }
  .howitworks-card h5 {
    color: #333333 !important;
    font-size: 8px !important;
    font-family: 'Work Sans', sans-serif !important;
    text-align: center;
}
.howitworks-card p {
  color: #7F7F7F !important;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 8px !important;
  text-align: center;
}
 
  .card-list{
    width: 100% !important;
    margin: 10px auto !important;
  }
  .user-home-banner {
    width: 100%;
    background-image: url(http://localhost:3000/static/media/user-bannerimg.43b63b5….webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px !important;
    padding: 0;
}
  .user-home-text p.banner-text {
    font-size: 19px;
    font-weight: 400;
    color: #000;
    margin: 0px auto;
    font-family: 'DM Serif Display' !important;
}
.user-home-text span.beauty-text {
  float: left;
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  color: #c78963;
  margin: 0px auto;
  font-family: 'DM Serif Display' !important;
}
  .book-appointment-outer img {
    height: 220px !important;
  }
  .book-appointment-outer {
    float: left;
    width: 100%;
    background: #000;
    border-radius: 24px;
    position: relative;
    margin-bottom: 10px;
    height: 220px !important;
    margin-top: 30px;
}
  .navbar-view{
    margin: 0 !important;
    padding: 0 !important;
  }
  .home-search-outer{
    background-color: white !important;
    width: 100% !important;
    margin: 0 auto !important;
    height: 60px !important;
    border-radius: 24px !important;
    display: flex !important;
  }
  .home-search-input-box{
    width: 100% !important;
    height: 40px !important;
    border-radius: 8px !important;
    color: #6c757d !important;
    font-weight: 300 !important;
    font-size: 16px !important; 
    margin: 0 auto !important;
    border-color: white !important;
  }
  .home-search-icon{
    width: 20px !important;
    margin-top: 15px !important;
    margin-left: 20px !important;
  }
  .home-enter-icon{
    width: 60px !important;
    margin-top: 18px !important;
   cursor: pointer !important; 
  }
  .home-search-container{
    width: 100% !important;
    margin-top: 10px !important;
  }
  .business-right h4{
    font-size: 12px;
  }
  .freelancer-left h4{
    font-size: 12px;
  }
  .customer-review ul li .card {
    margin: 36px 5px 0px 5px;
  }
  .explore-service-list ul li {
    width: 100%;
    text-align: center;
    margin: 0 0 30px 0;
  }
  .business-home-text ul li {
    width: 100%;
    margin-right: 0;
  }
  .our-features-list ul li {
    width: 100%;
  }
  .how-works-list.business-work-list ul li {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .how-works-list.business-work-list {
    margin-bottom: 0;
  }
  .customer-review ul li {
    width: 100%;
    margin: 0;
  }

  .business-home-text {
    top: 5px;
    left: 0;
  }
  .city-zip-outer {
    margin-top: 5px;
    padding: 0 10px;
  }
  .city-zip-outer,
  .zip-inner {
    width: 100%;
  }
  .zip-inner,
  .city-zip-outer .city-zipcode,
  .city-zip-outer .service-stylist {
    height: 50px;
  }
  .zip-location,
  .service-location {
    padding: 0 0px 0 10px;
    width: 30px;
    height: 50px;
  }
  .button-location {
    width: 35px;
    margin:7px 0 !important;
  }
  .city-zip-outer .city-zipcode,
  .city-zip-outer .service-stylist {
    font-size: 14px;
    width: 40%;
  }
  .business-home-text p {
    font-size: 18px;
  }
  .service-gender-user ul li p {
    font-size: 18px;

  }
  .service-gender-user ul li span {
    font-size: 10px !important;
  }
  .service-gender-user {
    width: 100%;
    margin: 20px 0 0 0;
  }
  .service-gender-user ul li:before {
    width: 5px;
    height: 30px;
    top: 10px;
  }
  
  .user-common-title,
  .user-common-title span {
    font-size: 26px;
  }
  .explore-list span {
    font-size: 28px;
  }
  .client-main {
    margin-top: 0;
  }
  .discount-booking-text {
    top: 0px;
    left: 0px;
    padding: 5px;
  }
  .explore-btn {
    top: inherit;
    bottom: 10px;
    right: 10px;
    font-size: 16px;
    width: 50%;
    
    
  }
  .discount-booking-text h2 {
    font-size: 28px;
    margin: 75px 0 0 0;
  }
  .discount-booking-text p {
    
    font-size: 20px !important;
    margin-top: 10px;
    text-align: center;
  }
  .discount-booking-text span {
    font-size: 8px;
    text-align: center;
    margin-top: 20px !important;
  }
  .book-appointment-section {
    margin-top: 0px;
  }
  .business-home-text p.banner-text,
  .business-home-text span.beauty-text {
    font-size: 20px;
  }
  .explore-service-list ul li {
    width: 100%;
    text-align: center;
    margin: 0 0 30px 0;
  }
  .our-features-list ul li {
    width: 100%;
  }
  .how-works-list ul li {
    width: 100%;
    margin: 0 0 30px 0;
  }
  .how-works-outer {
    margin-bottom: 20px;
    margin-top: 20px;

  }
  .how-works-list {
    margin-bottom: 0;
  }
  .discount-booking-text {
    width: 100%;
  }
  .customer-review ul li {
    width: 100%;
    margin: 0;
  }
  .explore-service-list {
    margin: 25px 0 0 0;
  }
  .our-features-outer {
    margin-top: 20px;
    padding-top: 0 !important;
    margin-bottom: 0 !important;

  }
  .footer-common h3 {
    font-size: 16px;
}
.footer-common ul li a {
  font-size: 12px;
}
.email-footer-icon {
  margin-top: 2px !important;
}
.social-media p {
  font-size: 10px;
}
.social-media span {
  font-size: 12px;
}
 
}

/*Mobile Responsive End*/
@media only screen and (max-width: 320px) {
  
  .client-main {
    float: right;
    margin-top: 10px;
  }
  .client-main ul li {
    float: left;
    width: auto;
    margin:  auto;
  }
  .client-main ul li a {
    font-weight: 500;
    font-size: 18px;
    color: #6c757d;
  }
  .client-main ul li a:hover {
    color: #0d6efd;
    border-bottom: 1px solid #0d6efd;
  }
  .business-partner-inner p{
    font-size: 16px;
  }
  .business-partner-inner span{
    font-size: 12px;
  }
  .user-common-title {
    float: left;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #000;
    margin-top: 35px;
  }
  .user-common-title span {
    font-weight: 400;
    font-size: 20px;
    color: #ecb390;
  }
  .view-all {
    display: none !important;
}
.explore-list span {
  font-size: 14px !important;
  margin-top: 18px;
}
.our-features-images p {
  font-size: 8px !important;
  text-align: center;
}
.how-it-works-card h5 {
  font-size: 10px!important;
  text-align: center;
}
.works-img {
  width: 100%;
  height: 18px !important;
}
.how-it-works-card p {
  font-size: 10px!important;
  text-align: center;
}
.how-it-works-card{
  margin-bottom: 30px !important;
}
.business-partner-inner {
  padding: 0px !important;
  margin-bottom: 20px;
}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .explore-list img {
    width: 100% !important;
  }
}

