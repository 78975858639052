.accordion-button:focus,
.btn-check:focus + .btn,
.btn:focus,
.form-select:focus,
.form-control:focus {
  box-shadow: none;
}


.subnav-list{
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #7F7F7F !important;
  font-family: 'Work Sans', sans-serif !important;
  margin-top: 5px !important;
}
.subnav-list a {
  color: #7F7F7F !important;
}
.naturals-outer {
  border-bottom: 1px solid #ddd;
}
.natural-promote {
  width: 290px !important;
  height: 240px !important;
  border-radius: 8px !important;

}
.cart-explore{
  color: #C78963 !important;
  font-size: 20px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.empty-cart-title{
  font-size: 20px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 600 !important;
}
.empty-cart-sub-title{
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #7F7F7F !important;
}
.empty-cart-image{
  width: 350px !important;
  height: 258px !important;
}
.progress {
  --bs-progress-bar-bg: #FFC107 !important;
  width: 80% !important;
  margin: 5px 50px 0px 50px !important;
}
.rating-bg {
  float: left;
  background: #198754;
  border-radius: 8px;
  padding: 3px 12px;
}
.rating-icon {
  margin: -5px 0 0 0;
}
.rating-bg span {
  color: #fff;
  font-size: 20px;
  padding: 0 0 0 5px;
}
.mobile-padding {
  padding-right: 50px !important;
}
.natural-list {
  float: left;
  width: 50%;
  height: 18px !important;
  
}
.natural-list .common-text{
  font-weight: 400 !important;
  font-size: 16px !important;
}
.natural-list ul {
  float: left;
  width: 100%;
  padding-left: 15px;
}
.natural-list ul li {
  float: left;
  width: auto;
  margin: 8px 10px 0 0;
  display: flex !important;
}
.common-text {
  float: left;
  font-size: 16px;
  font-weight: 400;
  color: #6c757d;
  margin-bottom: 10px;
  position: relative;
}
.service-title {
  /* float: left; */
  /* width: 100%; */
  font-size: 38px;
  font-family: Space Grotesk ;
  color: #212529;
  font-weight: 500;
  margin-top: 10px !important;
}
.location-icon {
  background: url(../images/location1.png) 0px 4px no-repeat;
  width: 100%;
  padding-left: 25px;
}
.phone-icon {
  background: url(../images/phone.png) 0px 3px no-repeat;
  width: 100%;
  padding-left: 25px;
}
.email-icon {
  background: url(../images/email.png) 0px 6px no-repeat;
  width: 100%;
  padding-left: 25px;
}
.time-icon {
  background: url(../images/time.png) 0px 7px no-repeat;
  width: auto;
  padding-left: 25px;
  padding-top: 3px;
}
.direction {
  font-size: 16px;
  color: #C78963 !important;
  font-weight: 400;
  text-decoration: none;
  background: url(../images/direction.png) right no-repeat;
  top: 0;
  text-transform: capitalize !important;
  padding-right: 20px;
  margin: 0 0 0 10px;
}
.service-accordion ul {
  padding: 0;
}
.service-accordion ul li {
  margin-bottom: 10px;
}
.service-accordion ul li:last-child {
  margin-bottom: 0;
}
.service-main-outer .accordion-button:not(.collapsed) {
  background: #fae9df;
  color: #000;
  font-size: 24px;
  font-weight: 500;
}
.service-accordion ul li a {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  text-decoration: none;
}
/* .busiess-details-accordion .accordion-header {
  background: #fae9df !important;
}
.busiess-details-accordion .accordion-button {
  background: #fae9df !important;
  color: #000 !important;
  font-size: 24px !important;
  font-weight: 500 !important;
} */

.accordion-button:link,.accordion-button:focus ,.accordion-button:active  {
     background-color: #FFF8F5 !important;
     color:#000 !important;
     text-decoration: none !important;

  }

.accordion-button:focus{
  box-shadow: none !important;
}

.cur-pointer-list{
  cursor: pointer;
  margin-bottom: 10px !important;
}

.cur-pointer-list a:visited, .cur-pointer-list a:focus, .cur-pointer-list a:hover, .cur-pointer-list a:active {
  color:#C78963 !important;
}
.service-tabs {
  border-bottom: 1px solid #ddd;
}
.service-tabs ul li {
  float: left;
  width: 33%;
  text-align: center;
}
.service-tabs ul li a {
  font-size: 32px;
  color: #000;
  font-weight: 500;
  position: relative;
}
.service-tabs div a {
  font-size: 32px;
  color: #000;
  font-weight: 500;
  position: relative;
  text-align: center;
}
.service-tabs ul li a:hover:before {
  border-bottom: 4px solid #602f10;
  position: absolute;
  bottom: 0px;
  content: "";
  width: 80px;
}
.service-tabs div a:hover:before {
  border-bottom: 4px solid #602f10;
  position: absolute;
  bottom: -2px;
  content: "";
  width: 125px;
}
/* .service-tabs ul li a.cutomer-review:hover:before {
  border-bottom: 4px solid #602f10;
  position: absolute;
  bottom: -2px;
  content: "";
  width: 100px;
} */
.service-tabs div a.cutomer-review:hover:before {
  border-bottom: 4px solid #602f10;
  position: absolute;
  bottom: -2px;
  content: "";
  width: 180px;
}
.service-tabs div a.services:hover:before {
  border-bottom: 4px solid #602f10;
  position: absolute;
  bottom: -2px;
  content: "";
  width: 80px;
}

/* .service-tabs ul li a.gallery-list:hover:before {
  border-bottom: 4px solid #602f10;
  position: absolute;
  bottom: -2px;
  content: "";
  width: 80px;
} */
.service-tabs div a.gallery-list:hover:before {
  border-bottom: 4px solid #602f10;
  position: absolute;
  bottom: -2px;
  content: "";
  width: 80px;
}
.service-tabs ul li a:actve:before {
  border-bottom: 4px solid #602f10;
  position: absolute;
  bottom: -2px;
  content: "";
  width: 80px;
}
.service-tabs div a:active:before {
  border-bottom: 4px solid #602f10;
  position: absolute;
  bottom: -2px;
  content: "";
  width: 80px;
}
.service-tabs ul li a.active {
  color: #602f10;
  
}
.service-tabs div a.active {
  color: #602f10;
}
.search-sortby-outer {
  float: left;
  width: 100%;
  /* border-bottom: 1px solid #ddd; */
  padding: 20px 0;
}
.search-sortby-left {
  float: left;
  width: 50%;
  line-height: 36px;
}
.search-sortby-left p {
  float: left;
  width: auto;
  font-weight: 500;
  font-size: 24px;
  color: #212529;
  margin: 0 10px 0 0;
}
.search-sortby-left .form-select {
  border: 1px solid #ced4da;
  border-radius: 8px;
  width: 162px;
}
.search-sortby-left .dropdown .btn.dropdown-toggle.show {
  color: #0d6efd;
}
.search-sortby-right {
  float: right;
  width: 50%;
}
.search-sortby-right .form-select {
  border: 1px solid #ced4da;
  border-radius: 8px;
  width: auto;
}
.search-sortby-right .search-box {
  margin: 0 30px 0 0;
}
.service-list ul {
  width: 100%;
  padding-left: 0;
  max-height: 800px;
  padding-right: 50px;
}
.service-list {
  float: left;
  width: 100%;
  padding: 30px 0 30px 0px;
  /* max-height: 500px;
  overflow: auto;
  overflow-x:hidden; */
}
.text-muted{
  word-break: break-all;
  color: #7F7F7F !important;
  font-size: 14px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
}
.service-list ul li {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 60px;
}
.service-list h3 {
  font-size: 20px;
  color: #212529;
  font-weight: 500;
  margin-bottom: 15px;
}
.price-time {
  font-size: 16px;
  font-weight: 500;
  color: #6c757d;
  margin-top: 0 !important;
}
.price-one {
  color: #333333 !important;
  margin: 2px 0 0 8px;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.price-two {
  color: #BFBFBF !important;
  margin: 2px 0 0 8px;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.item-time{
  color: #333333 !important;
  margin: 2px 0 0 8px;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.discount-off {
  color: #52C41A !important;
  margin: 5px 0 0px 10px !important;
  font-size: 14px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
}
.estimate-time {
  display: inline-block;
  margin: 0;
}
span.offer-right {
  position: absolute;
  bottom: 48px;
  right: 22px;
  font-size: 24px;
  font-weight: 500;
  color: #198754;
}
.book-now {
  background-color: #FFF8F5!important;
  font-weight: 500 !important;
  border: 1px solid #F3CFB8 !important;
  border-radius: 8px !important;
  position: absolute !important;
  right: 0;
  bottom: 0;
  width: 100px !important;
  font-size: 15px !important;
  color: #602f10 !important;
  margin-top: 20px;
}
.added-btn {
  background: #ecb390 !important;
  font-weight: 500 !important;
  border: 1px solid #F3CFB8 !important;
  border-radius: 8px !important;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100px !important;
  font-size: 15px !important;
  color: #602f10 !important;
  margin-top: 20px;
}
.load-more-outer {
  float: left;
  width: 100%;
}
.load-more {
  width: 200px;
  border: 1px solid #ced4da;
  font-size: 20px;
  font-weight: 500;
  color: #6c757d;
  border-radius: 8px;
}
.filter-sort {
  float: right;
  width: auto;
  margin: 0 20px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  color: #6c757d;
}
.filter-sortby {
  float: right;
  width: auto;
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  color: #6c757d;
}
.add-review {
  font-family: 'Work Sans', sans-serif !important;
  width: 100% !important;
  height: 40px !important;
  background: #ecb390 !important;
  margin-top: 4px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #602f10 !important;
  border-radius: 8px !important;
}
.customer-review-list ul {
  float: left;
  width: 100%;
  padding: 0;
  padding-right: 25px;
}
.customer-review-list ul li {
  float: left;
  width: 100%;
  margin-bottom: 25px;
}
.review-img {
  float: left;
  width: 50px;
}
.review-list {
  float: left;
  width: calc(100% - 50px);
}
.review-date {
  font-size: 14px;
  font-weight: 400;
  color: #6c757d;
  margin: 0 0 0 10px;
}
.content-align {
  text-align: justify;
}
.customer-rating-box {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  border: none;
}
.customer-rating {
  font-size: 20px;
  font-weight: 500 !important;
  color: #7F7F7F;
  margin: 10px 0 0 10px;
  font-family: 'Work Sans', sans-serif !important;
}
.review-progressbar {
  float: left;
  width: 100%;
}
.review-progressbar ul {
  padding: 0;
}
.review-progressbar ul li {
  float: left;
  width: 100%;
}
.review-popup .form-label {
  float: left;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  margin-bottom: 15px;
}
.add-comment {
  width: auto;
}
.category-select {
  font-size: 20px;
  color: #6c757d;
  font-weight: 500;
  width: auto;
  border-radius: 8px;
}
.popup-textarea {
  border-radius: 8px;
}
.modal-dialog-outer {
  max-width: 1000px;
}
.modal-dialog-outer .modal-content {
  border-radius: 12px;
}
.add-review-title {
  font-size: 40px;
  color: #212529;
  font-weight: 500;
}
.galleryUpload-list-inner ul {
  padding: 0;
  margin: 0;
}
.galleryUpload-list-inner ul li {
  float: left;
  width: 25.8%;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
  left: 10%;
}
.galleryUpload-list-inner ul li:nth-child(4n + 0) {
  margin-right: 0;
}
.gallery-list-inner ul {
  padding: 0;
  margin: 0;
}
.gallery-list-inner ul li {
  float: left;
  width: 25.8%;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
}
.gallery-list-inner ul li:nth-child(4n + 0) {
  margin-right: 0;
}
.like-dislike {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ced4da;
  width: 38px;
  height: 38px;
  text-align: center;
}
.like-dislike img {
  margin: 10px 0;
}
.promote-btn {
  background: #ffc107;
  border-radius: 8px;
  position: absolute;
  width: auto;
  height: 26px;
  top: 65px;
  left: 285px;
}
.promote-btn span {
  font-size: 16px;
  font-weight: 500;
  color: #404041;
  padding: 10px 15px;
}

.cart-outer {
  position: absolute;
  top: 0;
  right: 0;
}
.cart-image {
  width: 80px;
}
.cart-outer span {
  font-size: 24px;
  color: #0d6efd;
  font-weight: 500;
  float: left;
  width: 100%;
}
.business-time-section{
  margin: 0 auto !important;
  width: 100% !important;

}
.business-timelimit-section .common-text{
  margin-top: 4px;
}

/*Mobile Responsive Start*/
@media screen and (max-width: 767px) and (min-width: 320px) {
  .natural-list .common-text {
    font-size: 12px !important;
  }
  .book-now {
    bottom: 5px !important;
    left: 77% !important;
    width: 85px !important;
    font-size: 10px !important;
}


.added-btn {
  bottom: 5px !important;
    left: 77% !important;
    width: 85px !important;
    font-size: 10px !important;

}
  .item-time {
    font-size: 12px !important;
  }
  .discount-off {
    margin: 2px 0 0px 10px !important;
    font-size: 12px !important;
  }
  .price-one {
    font-size: 12px !important;
  }
  .price-two {
    font-size: 12px !important;
  }
  .empty-cart-image{
    width: 200px !important;
    height: 150px !important;
  }
  .add-review{
    font-size: 12px !important;
  }
  /* .added-btn {
    width: 100px !important;
    background: #ecb390 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #602f10 !important;
    border-radius: 8px !important;
    position: absolute;
    right: 0;
    bottom: 0;
} */
.service-title {
  font-size: 16px !important;
  /* margin-top: 30px !important;
  margin-left: 10px !important;
  overflow-x: hidden !important; */
}

  .text-muted{
    word-break:keep-all !important;
  }
  .search-sortby-left {
    width: 100%;
    padding: 0;
  }
  .search-sortby-right,
  .search-sortby-left .form-select {
    float: left;
    width: 100%;
    margin-top: 15px;
  }
  .search-sortby-right .search-box {
    width: 100%;
    margin: 0;
  }
  .search-sortby-right .form-select {
    float: left;
    width: 100%;
    margin-top: 15px;
  }
  .service-list {
    border-left: 0;
    padding: 0px 20px;
   
  }
  .service-list ul {
    padding-right: 20px;
    margin-top: 10%;
    
  }
  .service-tabs ul li {
    width: 100%;
  }
  .filter-sortby {
    width: 100%;
    margin: 15px 0;
  }
  .filter-sort {
    width: 100%;
    margin: 0;
  }
  .gallery-list-inner ul li {
    width: 100%;
  }
  .gallery-list-inner .gallery-lists {
    width: 100%;
  }
  .natural-promote {
    width: 100% !important;
    height: 240px !important;
  }
  .promote-btn {
    left: 30px;
    top: 18px;
  }
  .mobile-promote-btn {
    position: relative;
  }
  .search-sortby-outer {
    padding: 15px;
  }
  .mobile-padding {
     padding: 0px !important;
     width: 100% !important;
  }
  .customer-rating-box {
    margin-bottom: 25px;
  }
  .cart-outer {
    z-index: 9;
  }
  .direction {
    margin: 0;
  }
  .service-main-outer.mt-5 {
    margin-top: 0 !important;
  }
  .load-more-outer {
    padding-bottom: 0 !important;
  }
  .add-review {
    height: 24px !important;
    font-size: 8px !important;
}
  .buisness-day-section{
    width: 100% !important;
  }
  .business-dot-section{
    width: 10% !important;
  }
  .business-timelimit-section{
    width : 100% !important;
  
  }
  .customer-rating {
    font-size: 9px;
    margin: 18px 0 0 10px;
}
}
@media only screen and (min-width: 375px){

  .natural-list ul {
    float: left;
    width: 100%;
    padding-left: 15px;
    display: flex !important;
  }
  }
@media only screen and (min-width: 390px){
  .service-tabs div a {
    font-size: 20px !important;
    color: #000;
    font-weight: 500;
    
  }

  /* .service-title {
     float: left;
     width: 100%; 
    font-size: 30px;
    font-family: Space Grotesk;
    color: #212529;
    font-weight: 500;
    margin-top: 20px !important;
  } */
}