h1 .cart-header {
  font-weight: 500;
  font-size: 40px;
  color: #212529;
  margin-top: 30px;
  margin-bottom: 30px;
}
.info-icon {
  margin: -4px 0 0 5px;
}
.cart-text {
  float: right;
  font-size: 20px;
  font-weight: 500;
  color: #0d6efd;
  margin-top: 15px;
}
.cart-service-list {
  width: 100%;
  max-height: 550px;
}
.cart-service-list ul li {
  float: left;
  width: 100%;
  border-bottom: 1px solid #e9ecef;
  padding: 15px 0;
  position: relative;
}
.cart-service-list ul li:last-child {
  border-bottom: none;
}
.cart-service-list ul li p {
  float: left;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #0d6efd;
  margin: 0 0 5px 0;
}
.cart-service-list ul li span {
  float: left;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #6c757d;
  margin: 0;
}
.cart-service-list ul li p.service-heading {
  font-size: 20px;
  font-weight: 500;
  color: #212529;
}
.service-item p {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #6c757d !important;
}
.common-img {
  margin: 0 8px 0 0;
}
.amount-text {
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #212529 !important;
  position: absolute;
  top: 55px;
  right: 10px;
  width: auto !important;
}
.close-icon {
  margin: 0 0 0 60px;
  cursor: pointer;
}
.modal-title {
  float: left;
  width: 100%;
  font-size: 28px;
  font-weight: 500;
  color: #212529;
}
.modal-body p {
  font-size: 16px;
  font-weight: 300;
  color: #6c757d;
  width: 100%;
  margin-bottom: 20px;
}
.cancel-btn {
  width: 165px;
  border-radius: 8px;
  border: 1px solid #602f10;
  padding: 12px 20px;
  text-align: center;
  color: #602f10;
  background: #fff;
  margin: 0 20px 0 0;
}
.cancel-btn:hover {
  background: #fff;
  color: #602f10;
}
.remove-btn {
  width: 165px;
  background: #ecb390;
  border-radius: 8px;
  color: #602f10;
  text-align: center;
  padding: 12px 20px;
  border: none;
}
.remove-btn:hover {
  background: #ecb390;
  color: #602f10;
}
.modal-content {
  border: 1px solid #ced4da;
  border-radius: 12px;
}
.modal-header {
  border-bottom: none;
}
.btn-close:focus,
.apply-btn:focus {
  outline: none;
  box-shadow: none;
}
.ordersummary-inner {
  float: left;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
}
.ordersummary-inner h3 {
  font-weight: 500;
  font-size: 28px;
  color: #212529;
  display: inline-block;
  padding: 20px;
}
.ordersummary-inner span {
  font-weight: 500;
  font-size: 28px;
  color: #212529;
  margin: 0;
  padding: 20px;
}
.servicelist-inner {
  float: left;
  width: 100%;
  padding: 20px;
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
}
.discount-coupon {
  font-size: 16px;
  font-weight: 400;
  color: #6c757d;
  padding: 12px 60px 12px 12px;
  border-radius: 8px;
  position: relative;
}
.discount-coupon:focus {
  box-shadow: none;
}
.apply-btn {
  font-size: 16px;
  font-weight: 400;
  color: #602f10;
  position: absolute !important;
  right: 0;
  top: 0;
  padding: 12px;
  border: none;
}
.apply-btn:hover {
  background-color: transparent;
  color: #602f10;
}
.servicelist-inner ul li {
  float: left;
  width: 50%;
  margin-bottom: 10px;
}
.servicelist-inner ul li p {
  font-size: 20px;
  font-weight: 400;
  color: #6c757d;
  display: inline-block;
  margin: 0;
}
.servicelist-inner ul li span {
  font-size: 20px;
  font-weight: 400;
  color: #212529;
  padding: 0;
}
.servicelist-inner ul li span.green {
  color: #198754;
}
.small-info {
  margin: 0 0 0 5px;
}
.total-amount-inner {
  float: left;
  width: 100%;
  padding: 20px;
}
.total-amount-inner p {
  font-weight: 500;
  font-size: 28px;
  color: #212529;
  margin: 0;
  display: inline-block;
}
.total-amount-inner span {
  font-weight: 500;
  font-size: 28px;
  color: #212529;
  margin: 0;
  padding: 0;
}
.total-amount-inner p.order-save {
  float: left;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #198754;
  margin-top: 10px;
}
.continue-btn {
  width: 100%;
  background: #ecb390;
  text-align: center;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 20px;
  color: #602f10;
  margin-top: 30px;
}
.proceed-cancel{
  width: 100%;
  background: #ecb390;
  text-align: center;
  padding: 0px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  color: #602f10;
  height: 40px !important;
}
.cart-checkout-continue{
  width: 100%;
  background: #ecb390;
  text-align: center;
  padding: 12px;
  border-color: #C78963 !important;
  border-radius: 8px;
  font-size: 20px;
  color: #602f10;
  margin-bottom: 20px !important;
}
.cart-checkout-continue-bridal{
  width: 100%;
  background: #ecb390;
  text-align: center;
  /* padding: 12px; */
  border-color: #C78963 !important;
  border-radius: 8px;
  font-size: 16px;
  color: #602f10;
  margin-bottom: 20px !important;
  height: 40px;
}
.cart-checkout-continue-update{
  width: 100%;
  background: #ecb390 !important;
  text-align: center;
  /* padding: 12px; */
  border-color: #C78963 !important;
  border-radius: 8px;
  font-size: 16px;
  color: #602f10;
  margin-bottom: 20px !important;
  height: 40px;
}


.cart-footer{
  background-color:#FAFAFA !important ;
}

.cart-footer-links{
  cursor: pointer;
}
.cart-footer-content p span .support-cart-center{
  color: #0D6EFD !important;
  font-weight: 500 !important;
  cursor: pointer;
}
.secure {
  margin: -4px 10px 0 0;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.slot-time-btn{
  font-size: 12px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  width: 100% !important;
  text-align: center !important;
  border-color: #7F7F7F !important ;
  color: #000000 !important;
}
.slot-time-btn-danger{
  font-size: 12px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  width: 100% !important;
  text-align: center !important;
  border-color: #7F7F7F !important ;
  color: #000000 !important;
}

.slot-time-btn-none{
  font-size: 12px !important;
  font-family: 'Work Sans', sans-serif !important;
  width: 100% !important;
  text-align: center !important;
  border: 1px solid #F7F7F7  !important ;
  background-color: #EBEBEB !important;
  color: #BFBFBF !important;
}
.slot-time-btn-none p{
  font-size: 12px !important;
  padding: 0 !important;
  margin: 0 !important;
  color: #BFBFBF !important;
 font-weight: 500 !important;
}
.slot-time-btn:hover{
  background-color: #FAE9DF !important;
  border-color:#C78963 !important ;
}
.slot-booking-time {
  font-size: 12px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  width: 100% !important;
  text-align: center !important;
  background-color: #FAE9DF !important;
  border-color:#C78963 !important ;
  color: #000000 !important;
}
.slot-available-text{
  margin-top: 5px !important;
  font-size: 10px !important;
  color: #7F7F7F !important;
  font-family: 'Work Sans', sans-serif !important;
}
.react-datepicker__header {
  text-align: center;
  background-color: white !important;
  border-bottom: 0px solid #aeaeae !important;
  border-top-left-radius: 0.3rem;
  padding: 10px 0px 0px 0px !important;
  position: relative;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  margin-top: 0;
  color: #121212 !important;
  font-weight: bold;
  font-size: 14px !important;
  
}
.react-datepicker__day-names {
  margin-bottom: 0px !important;
  border-bottom: none !important;

}
.react-datepicker__day, .react-datepicker__time-name {
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
  font-size: 12px !important;
  color: #602F10 !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.react-datepicker__day-name{
  color: #7F7F7F !important;
  font-size: 12px !important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #ECB390 !important;
  color: #fff;
}
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default;
  color: #BFBFBF !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  font-family: 'Work Sans', sans-serif !important;

}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #ECB390 !important;
  color: black !important
}
.service-type-active{
  background-color: #FAE9DF !important;
  text-align: center !important;
  height: 40px !important;
  width: 100% !important;
  cursor: pointer;
}
.generateCode-type-active{
  background-color: #FAE9DF !important;
  text-align: center !important;
  height: 40px !important;
  width: 100% !important;
  cursor: pointer;
  border-color: #C78963 !important;
}
.default-generateCode-type{
  background-color: white !important;
  text-align: center !important;
  height: 40px !important;
  width: 100% !important;
  cursor: pointer;
}
.default-generateCode-type p {
  margin-top: 7px !important;
  color: #333333 !important;
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}

.generateCode-type-active p{
  margin-top: 7px !important;
  color: #333333 !important;
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}

.service-type-active p{
  margin-top: 7px !important;
  color: #333333 !important;
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.default-service-type p{
  margin-top: 7px !important;
  color: #333333 !important;
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.default-service-type{
  text-align: center !important;
  height: 40px !important;
  width: 100% !important;
  cursor: pointer;
}
.continue-btn-login span{
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;

}
.register-form h6{
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #333333 !important;
}



@media only screen and (min-width: 390px) {
  .register-form h6 {
    font-size: 12px !important;
    font-weight: 500 !important;
    font-family: 'Work Sans', sans-serif !important;
    color: #333333 !important;
    margin-top: 20px;
}
  
.cart-service-list ul li span{
  margin-left: 20px !important;
}
}
@media only screen and (max-width: 600px){
.default-generateCode-type p {
  margin-top: 12px !important;
  color: #333333 !important;
  font-size: 10px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.generateCode-type-active p{
  font-size: 10px !important;
  margin-top: 12px !important;
}
}