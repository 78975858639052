.edit-icon {
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: #0d6efd;
  margin-top: 20px;
}

.fav-list-card {
  height: 96px !important;
  border-top: none !important;
  border-radius: 0 !important;
  /* border-right: none !important; */
  border-left: none !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.04) !important;
  margin: 20px 0px 0px 0px !important;
}
.fav-list-inner{
  display: flex !important;
}
.fav-list-inner div{
  float: left !important;
}
.fav-list-inner .img-container{
  width: 15% !important;
}
.fav-list-inner .info-container{
  width: 75% !important;
}
.fav-list-inner .info-container p:hover{
  color: #0D6EFD !important;
  cursor: pointer;
}
.fav-list-inner .info-container p {
  font-size: 14px !important;
  margin-bottom: 10px !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #333333;
  font-weight: 500 !important;
  margin-top: 10px !important;
}
.fav-list-inner .info-container h6 {
  font-size: 14px !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #7F7F7F;
  font-weight: 400 !important;
  margin-top: 10px !important;

}
.fav-list-inner .info-container h6 span {
  font-size: 14px !important;
  margin-bottom: 10px !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #7F7F7F;
  font-weight: 400 !important;
}
.fav-list-inner .fav-container{
  width: 10% !important;
}
.fav-list-inner .fav-container p{
  margin: 0 auto !important;
}
.profile-fav-card{
  width: 24px !important;
  height: 24px !important;
  border-radius: 5px !important;
  border: 1px solid #E0E0E0 !important;
}
.fav-section h1{
 font-size: 24px !important;
 font-weight: 600 !important;
 font-family: 'Space Grotesk', sans-serif !important;
 color: #333333 !important;
}

.no-Fav p{
  font-size: 20px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #404040 !important;
}
.cur-pointer{
  cursor: pointer;
}

.contactUs-section h2{
  font-size: 20px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  color: #333333 !important;
}
.contactUs-section h1{
  font-size: 24px !important;
  font-weight: 600 !important;
  font-family: 'Space Grotesk', sans-serif !important;
  color: #333333 !important;
}
.contactUs-section p{
  font-size: 14px !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #333333 !important;
  font-weight: 400 !important;
}
.support-profile-page h1{
  font-size: 24px !important;
  font-family: 'Space Grotesk', sans-serif !important;
  font-weight: 600 !important;
  color: #333333 !important;
  margin-bottom: 20px !important;
}
.support-profile-page h2{
  font-size: 20px !important;
  color: #333333 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
}
.support-profile-page p{
  font-size: 14px !important;
  color: #333333 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
}

.edit-icon img {
  margin: -2px 8px 0 0;
}
.profile-list {
  width: 50%;
  margin: 0 auto;
}
.profile-list ul {
  padding: 0;
  float: left;
  width: 100%;
  margin-top: 60px;
}
.profile-list ul li {
  float: left;
  width: 100%;
  margin: 0 0 10px 0;
}
.profile-list ul li p {
  float: left;
  width: 50%;
  font-size: 20px;
  font-weight: 500;
  color: #6c757d;
  text-align: left;
}
.profile-list ul li span {
  float: left;
  width: 50%;
  font-size: 20px;
  font-weight: 400;
  color: #000;
  text-align: left;
}
.profile-list ul li a {
  float: left;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  color: #0d6efd;
  text-align: left;
}
.info-icon {
  margin: 0 0 0 10px;
}
.changepwd-signout{
  position: absolute;
  left: 0;
  top: 170px;
}
.user-profile-details {
  margin-top: 8% !important;
  margin-left: 0 !important;
}
.user-profile-actions-inner{
  position:relative;
  margin: 0 auto;
  width: 40% !important;
  display: inline-flex;
}
.user-profile-actions {
  margin-left: 8% !important;
}
.cur-pointer {
  cursor: pointer;
}
.Verify-Border {
  border-left: none !important;
  background-color: white !important;
  color: rgb(75, 75, 244) !important;
  border-color: grey !important;
  text-align: center;
  padding: 0 20px;
  border-top-right-radius: 5% !important;
  border-bottom-right-radius: 5% !important;
}
.input-border {
  
  border: 1px solid rgb(233, 229, 229) !important;
}

.profile-verify-otp {
  width: 45%;
  margin: 0 auto !important;
}
.profile-verify-btn {
  position: relative;
  right: 12%;
  margin-top: 10px;
}
.user-profile-actions .profile-left {
  font-size: 18px;
  font-weight: 500;
  color: #6c757d;
  text-align: left;
  font-family: 'Space Grotesk', sans-serif !important;
}
.user-profile-details .profile-left {
  font-size: 18px;
  font-weight: 500;
  color: #6c757d;
  text-align: left;
  width:60%;
  font-family: 'Space Grotesk', sans-serif !important;
}
.head-content {
  position: relative;
}
.upload-icon {
  background-color: rgb(239, 234, 234);
  height: 45px;
  width: 45px;
  padding: 10px;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: 55%;
  color: #212529;
  font-weight: lighter;
}
.profile-pic {
  display: block;
}
.user-profile-details .profile-right {
  font-size: 18px;
  font-weight: 400;
  color: #6c757d;
  text-align: left;
  width: 50%;
  font-family: 'Space Grotesk', sans-serif !important;
}
.buttonSizing {
  width: 240px;
  margin-top: 30px;
  border-color: rosybrown;
  color: #602f10;
  background-color: #ecb390;
}
.buttonSizing :hover {
  background-color: white !important;
  border-color: rosybrown !important ;
}
.profileEdit {
  border-radius: 8%;
  height: 200px;
  width: 200px;
}
.upload {
  cursor: pointer;
}
#file {
  display: none;
}
.uploadIcon {
  position: relative;
}
.cart-service-header h6{
  font-size: 24px !important;
  font-weight: 600 !important;
  font-family: 'Space Grotesk', sans-serif !important;
}
.cart-service-header p{
  color: #C78963!important;
  float: right !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  cursor: pointer !important;
}



.cart-serviceprovider-details h6{
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  color: #ECB390 !important;
}
.cart-serviceprovider-details p{
  color: #7F7F7F !important;
  font-weight: 300 !important;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 14px !important;
}
.cart-checkout-item-list span{
   font-size: 12px !important;
   color: #7F7F7F !important;
   font-family: 'Work Sans', sans-serif !important;
   font-weight: 400 !important;
}
.cart-checkout-item-list p{
  color: #333333 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  margin: 0 !important;
  margin-top: 2px !important;
}
.cart-checkout-item-list h6{
  font-size: 16px !important;
  color: #7F7F7F !important;
  margin-top: 10px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
}
.cart-check-out-amount{
 float: right !important;
}
.cart-check-out-amount span{
  margin-left: 50% !important;
  color: #BFBFBF !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  cursor: pointer;
}
.cart-check-out-amount p{
 font-size: 16px !important;
 color: #333333 !important;
 font-family: 'Work Sans', sans-serif !important;
 font-weight: 500 !important;
}
.total-summary-card{
  width: 440px !important;
  margin-left: 10%!important;
}
.serivice-item-header h6{
  font-size: 20px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.serivice-item-header{
  display: flex !important;
}
.serivice-item-header p{
  position: relative;
  left: 25% !important;
  margin-top: 10px !important;
  font-size: 20px !important;
  color: #333333 !important;
  font-weight: 500 !important ;
  font-family: 'Work Sans', sans-serif !important;
  float: right !important;


}
.serivice-item-amounts{
  display: flex !important;
}
.serivice-item-amounts p{
  position: relative;
  left: 0% !important;
  font-size: 16px !important;
  color: #333333 !important;
  font-weight: 500 !important ;
  font-family: 'Work Sans', sans-serif !important;
  /* float: right !important; */
  width: 100% !important;
  text-align: end !important;
}
.serivice-item-amounts h6{
  font-size: 16px !important;
  color: #6C757D !important;
  font-weight: 400 !important ;
  font-family: 'Work Sans', sans-serif !important;
}
.serivice-item-discount{
  display: flex;
}
.serivice-item-discount h6{
  font-size: 16px !important;
  color: #6C757D !important;
  font-weight: 400 !important ;
  font-family: 'Work Sans', sans-serif !important;
}
.serivice-item-discount p{
  position: relative;
  left: 0% !important;
  font-size: 16px !important;
  color: #52C41A !important;
  font-weight: 500 !important ;
  font-family: 'Work Sans', sans-serif !important;
  width: 100% !important;
  text-align: end !important;

}
.serivice-item-coupon{
  display: flex;
}
.serivice-item-coupon h6{
  font-size: 16px !important;
  color: #6C757D !important;
  font-weight: 400 !important ;
  font-family: 'Work Sans', sans-serif !important;
}
.serivice-item-coupon p{
  position: relative;
  left: 0% !important;
  font-size: 16px !important;
  color: #52C41A !important;
  font-weight: 500 !important ;
  font-family: 'Work Sans', sans-serif !important;
  width: 100% !important;
  text-align: end !important;
}
.discount-text{
  font-size: 16px !important;
  color: #52C41A !important;
  font-weight: 500 !important ;
  font-family: 'Work Sans', sans-serif !important;
}
.serivice-item-total-end{
  display: flex;
}
.serivice-item-total-end p{
  position: relative;
  left: 0% !important;
  font-size: 14px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  width: 100% !important;
  text-align: end !important;

}
.serivice-item-total-end h6{
  font-size: 20px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 500 !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.safe-secure {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #6c757d !important;
  margin-top: 0px !important;
  font-family: 'Work Sans', sans-serif !important;

}
.safe-secure span{
  color: #7F7F7F !important;
}
.rupee-symbol{
  position: relative !important;
  top: 3px !important;
}
.coupon-code-component{
  background-color: white;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  width: 100% !important;
  margin: 0 auto !important;
  height: 40px;
  display: flex !important;
}
.coupon-code-component h5{
  color: #C78963 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  cursor: pointer;
}
.coupon-code-component h6{
  color: #F5222D !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  cursor: pointer;
}
.coupon-input-box{
  border: none !important;
  width: 80% !important;
}
.coupon-input-box-card{
  border: none !important;
  width: 85% !important;
}
.available-coupons p{
  color: #0D6EFD !important;
  font-size: 16px !important;
  font-family: 'Work Sans', sans-serif !important;
  font-weight: 400 !important;
  cursor: pointer;
}

.cart-footer-help{
  float: right !important;
}

.cart-footer-content p span{
  font-size: 16px !important;
  color: #7F7F7F !important;
  font-weight: 400 !important;
  font-family: 'Work Sans', sans-serif !important;
}
.profile-card-pic{
  border-radius: 50% !important;
  height: 160px !important;
  width: 160px !important;
}
.profile-card-pic-right{
  border-radius: 50% !important;
  height: 120px !important;
  width: 120px !important;
}
.user-details-profile{
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  width: 350px !important;
  border-radius: 0 !important;
  border-color: rgba(0, 0, 0, 0.04) !important;
  border-top: 0 !important

}
.card-profile-img-card{
  border-bottom: none !important;
}
.card-user-details h6{
  font-size: 16px !important;
  color: #7F7F7F !important;
  margin: 10px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
}


.card-user-details{
  margin-top: 35px !important;
}
.card-user-details p{
  margin-bottom: 8px !important;
  margin-left: 0 !important;
  font-size: 16px !important;
  color: #7F7F7F !important;
}
.card-user-details-edit{
  margin-top: 20px !important;

}
.card-user-details-edit p{
  color: #C78963 !important;
  font-size: 16px !important;
  cursor: pointer;
}
.card-user-details-edit h6{
  font-size: 16px !important;
  color: #333333 !important;
}
.user-details-profile-tab{
  border: none !important;
}
.card-user-details-edit h3{
  font-size: 14px !important;
  color: #7F7F7F !important;
  font-weight: 400 !important;
}
.list-group-flush h5{
  margin: 7px 0px 0px 0px !important;
  position: relative;
  left: 20% !important;
  color: #7F7F7F !important;
  cursor: pointer;
}
.list-group-flush p{
  font-size: 16px !important;
  color: #333333 !important;
  margin: 4px !important;
  font-weight: 500 !important;
  font-family: 'Work Sans', sans-serif !important;
  cursor: pointer;

}
.profileEdit-card{
   height: 120px !important;
   width: 120px !important;
   border-radius: 50% !important;
}
.email-component{
  display: flex !important;
  border: 1px solid rgb(220, 220, 220) !important;
  border-radius: 8px !important;
}
.email-component input{
  border: none !important;
  background-color: white !important;
  width: 85% !important;
}
.email-component p{
 position: relative;
 top: 8px !important;
 right: 5px !important;

}
.form-select-Row{
  margin: 10px 6px !important;
}
.email-component p span{
  color: #52C41A !important;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
}
.text-brand-color{
  color: #C78963;
  font-family: 'Work Sans', sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.active-profile-tab{
  background-color: black !important;
}
.active-profile-tab p{
  color: #C78963 !important;
}
.ion-icon:hover{
  content: none !important;

}
.mobile-view-profile{
  display: none !important;
}
/*  
react-date-picker */

.react-date-picker__inputGroup__divider {
  display: none !important;
}
.to-datePicker{
  width: 100% !important;
  margin-left: 10px !important;
  height: 40px !important;
  border-radius: 5px !important;
  border: 1px solid #EBEBEB !important;
  border-top: none !important;
  padding: 15px !important;
  font-size: 14px !important;
  color: #7F7F7F !important;
  font-weight: 500 !important;
}
.date-cal-coupon{
  position: relative !important;
  left: 92% !important;
  bottom: 46% !important;
  color: #C78963 !important;
  cursor: pointer !important;
}
.react-date-picker__button svg {
  display: none !important;
}
.calander-section-coupon{
  margin-bottom: 0 !important;
  padding: 0 !important;
}
.life-time-empty{
  margin-left: 13px !important;
  width: 100% !important;
  padding: 0 !important;
}

.add-discount-amt-icon{
  margin-top: 10px !important;
  font-size: 18px !important;
  color: #C78963 !important;
}
.close-discount-amt{
  margin-top: 3px !important;
  font-size: 18px !important;
  color: #F5222D !important;
}
.react-date-picker, .react-date-picker *, .react-date-picker *:before, .react-date-picker *:after {
  box-sizing: border-box;
  border-radius: 5px;
  height: 35px;
  
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid rgb(183, 180, 180) !important;
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  
  .email-component input{
    border: none !important;
    background-color: white !important;
    width: 70% !important;
  }
  .buttonSizing {
    width: 120px !important;
  }
  .serivice-item-header h6 {
    font-size: 14px !important;
  }
  .serivice-item-header p {
    font-size: 14px !important;
  }
  .serivice-item-total-end h6 {
    font-size: 16px !important;
  }
  .serivice-item-amounts h6 {
    font-size: 14px !important;
  }
  .serivice-item-amounts p {
    font-size: 14px !important;
  }
  .serivice-item-discount h6 {
    font-size: 14px !important;
  }
  .serivice-item-discount p {
    font-size: 14px !important;
  }
  .serivice-item-coupon h6 {
    font-size: 14px !important;
  }
  .serivice-item-coupon p {
    font-size: 14px !important;
  }
  .available-coupons p {
    font-size: 12px !important;
  }
  .cart-checkout-continue {
    font-size: 14px;
  }
  .coupon-code-component p {
    font-size: 14px !important;
  }
  .fav-section{
    margin-top: 80px !important;
  }
  .fav-section h1 {
    font-size: 18px !important;
}

  .fav-business-images {
    margin-top: 5px;
}
.fav-list-inner .fav-container p {
  margin-top: 30% !important;
}
  .fav-list-inner .info-container p {
    font-size: 12px !important;
  }
  .fav-list-inner .fav-container{
    width: 100% !important;
  }
  .fav-list-inner .img-container{
    width: 100% !important;
  }
  .fav-list-inner .info-container{
    width: 100% !important;
  }


 
.cart-checkout-item-list p{
font-size: 12px !important;
}
.cart-checkout-item-list h6 {
  font-size: 12px !important;
}
 
  .mobile-view-profile{
    display:contents !important;
  }
  .system-view-profile{
    display: none !important;
  }
  .user-details-profile{
    width: 100% !important;
    margin: 0 auto !important;
  }
  .cart-footer-help{
    float: none !important;
  }
  .cart-footer-content p span {
    font-size: 10px !important;
  }
.total-summary-card{
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }


.cart-service-header h6 {
          font-size: 16px !important; 
        }
.cart-service-header p {
            float: none !important;
          }
.cart-checkout-item-list{
            width: 50% !important;
            float: left !important  ;
        }
.cart-checkout-amount-list{
          width: 50% !important;
          float: left !important;
          margin-top: 10px !important;
      }
.item-component{
          width: 100% !important;
          margin: 0 !important;
          padding: 0 !important;
        }
        .cart-checkout-page-mobile{
          width: 100% !important;
        }
        .cart-summary-page{
          width: 100% !important;
        }
.add-new-services {
  position: relative;
  bottom: 14%;
}

.user-profile-details {
  margin-top: 15% !important;
  margin-left: 0 !important;
  font-size: 10px !important;
}
.user-profile-actions-inner{
  position:relative;
  margin: 0 auto;
  width: 70% !important;
  display: inline-flex;
  font-size: 10px !important;
}
.user-profile-actions {
  margin-left: 0% !important;
}
.user-profile-details .profile-right {
  font-size: 14px;
  font-weight: 400;
  color: #6c757d;
  text-align: left;
  width: 50%; 
  font-family: 'Space Grotesk', sans-serif !important;
}
.user-profile-details .profile-left {
  font-size: 14px;
  font-weight: 500;
  color: #6c757d;
  text-align: left;
  width:60%;
  margin-bottom: 85px !important;
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .cart-checkout-page .col-md-4{
    width: 100% !important;
  }
 
  .cart-footer-content p span {
      font-size: 11px !important;
    }
    .tabelt-view .col-md-4{
      width: 100% !important;
    }
    .user-details-profile{
      padding-bottom: 0px !important;
      width:100% !important;
      border-radius: 0 !important;
      border-color: rgba(0, 0, 0, 0.04) !important;
      border-top: 0 !important;
      font-size: 12px !important;
      margin: 0 auto !important;
    }
    .system-view-profile{
      display: none !important;
    }
    .mobile-view-profile{
      display: contents !important;
    }
  }